import React, { Component, Fragment } from "react";
import {
  Row,
  Form,
  Spin,
  Col,
  Card,
  Icon,
  Button,
  message,
  Tabs
} from "antd";
import IntlMessages from "util/IntlMessages";
import queryString from "query-string";
import {
  getPolicy,
  getReferenceListPublicWeb,
  publicViewPolicy
} from "../../../restAPI/main";
import { MagicReference, policyTabKeys } from "../../../restAPI/request";
import Objective from "./objective";
import Article from "./article";
import AnnexOne from "./annex1";
import AnnexTwo from "./annex2";
import AnnexThree from "./annex3";
import AnnexFour from "./annex4";
import AnnexFive from "./annex5";
import AnnexSix from "./annex6";
import AnnexSeven from "./annex7";
import AnnexEight from "./annex8";
import AnnexNine from "./annex9";

const { TabPane } = Tabs;

class GeneralInformation extends Component {
  state = {
    policy: {
      directions: [],
      additional_annex_names: [],
      policy_financial_sources: []
    },
    loading: true,
    policyStatus: [],
    financials: [],
    policyTypes: [],
    mainAnnex: [],
    objectives: {},
    activeKey: policyTabKeys.general
  };

  componentWillMount() {
    const { match, policy_id, location, annex } = this.props;
    if (location) {
      if (location.search) {
        const value = queryString.parse(location.search);
        let annexkey = "5";
        switch (value.annex) {
          case "1":
            annexkey = "5";
            break;
          case "2":
            annexkey = "8";
            break;
          case "3":
            annexkey = "14";
            break;
          case "4":
            annexkey = "10";
            break;
          case "5":
            annexkey = "9";
            break;
          case "6":
            annexkey = "7";
            break;
          case "7":
            annexkey = "11";
            break;
          case "8":
            annexkey = "13";
            break;
          case "9":
            annexkey = "6";
            break;
          case "10":
            annexkey = policyTabKeys.general;
            break;
          case "11":
            annexkey = policyTabKeys.article;
            break;
          case "12":
            annexkey = policyTabKeys.objective;
            break;
          default:
            annexkey = "1";
            break;
        }
        this.onChangeTabKey(annexkey);
      }
    }

    if (match) {
      getPolicy(match.params.id, "", this.onSuccess, this.onFailed, "POST");
    }

    if (policy_id) {
      publicViewPolicy(policy_id, "", this.onSuccess, this.onFailed, "POST");
      let annexkey = "5";
      switch (annex) {
        case "1":
          annexkey = "5";
          break;
        case "2":
          annexkey = "8";
          break;
        case "3":
          annexkey = "14";
          break;
        case "4":
          annexkey = "10";
          break;
        case "5":
          annexkey = "9";
          break;
        case "6":
          annexkey = "7";
          break;
        case "7":
          annexkey = "11";
          break;
        case "8":
          annexkey = "13";
          break;
        case "9":
          annexkey = "6";
          break;
        case "10":
          annexkey = policyTabKeys.general;
          break;
        case "11":
          annexkey = policyTabKeys.article;
          break;
        case "12":
          annexkey = policyTabKeys.objective;
          break;
        default:
          annexkey = "1";
          break;
      }

      this.onChangeTabKey(annexkey);
    }

    getReferenceListPublicWeb(
      MagicReference.FINANCIAL_SOURCE,
      "",
      this.onFinancialSourceSuccess,
      this.onFailed,
      "POST"
    );

    getReferenceListPublicWeb(
      MagicReference.POLICY_TYPE,
      "",
      this.onPolicyTypeSuccess,
      this.onFailed,
      "POST"
    );

    getReferenceListPublicWeb(
      MagicReference.POLICY_STATUS,
      "",
      this.onPolicyStatusSuccess,
      this.onFailed,
      "POST"
    );

    getReferenceListPublicWeb(
      MagicReference.ANNEX,
      "",
      this.onAnnexSuccess,
      this.onFailed,
      "POST"
    );
  }

  onSuccessObj = response => {
    this.setState({
      objectives: response
    });
  };

  onFailed = err => {
    message.error(err);
    const failPolicy = {
      directions: [],
      policy_annexes: [],
      additional_annex_names: [],
      policy_financial_sources: [],
      members: []
    };
    this.setState({
      loading: false,
      policy: failPolicy
    });
  };

  onAnnexSuccess = response => {
    this.setState({
      mainAnnex: response
    });
  };

  onFinancialSourceSuccess = response => {
    this.setState({
      financials: response
    });
  };

  onPolicyTypeSuccess = response => {
    this.setState({
      policyTypes: response
    });
  };

  onPolicyStatusSuccess = response => {
    this.setState({
      policyStatus: response
    });
  };

  onSuccess = response => {
    if (response.id) {
      this.setState({
        policy: response
      });
    } else {
      let TMPpolicy = {
        directions: [],
        policy_annexes: [],
        additional_annex_names: [],
        policy_financial_sources: []
      };

      this.setState({
        policy: TMPpolicy
      });
      message.warning("Бодлого олдсонгүй");
    }

    this.setState({
      loading: false
    });
  };

  getTypeName = type_id => {
    const { policyTypes } = this.state;
    let retVal = "";
    policyTypes.map(i => {
      if (i.id === type_id) {
        retVal = i.name;
      }
      return retVal;
    });

    return retVal;
  };

  getStatusName = status_id => {
    const { policyStatus } = this.state;
    let retVal = "";
    policyStatus.map(i => {
      if (i.id === status_id) {
        retVal = i.name;
      }
      return retVal;
    });

    return retVal;
  };

  getAnnexName = annex_id => {
    const { mainAnnex } = this.state;
    let retVal = "";
    mainAnnex.map(i => {
      if (i.id === annex_id) {
        retVal = i.name;
      }
      return retVal;
    });

    return retVal;
  };

  getFinancialName = financial_id => {
    const { financials } = this.state;
    let retVal = "";
    financials.map(i => {
      if (i.id === financial_id) {
        retVal = i.name;
      }
      return retVal;
    });

    return retVal;
  };

  getUserNameOrOrgName = userOrOrg => {
    let retVal =
      userOrOrg.firstname +
      " " +
      userOrOrg.lastname +
      " (" +
      userOrOrg.member_position_name +
      ") ";

    return retVal;
  };

  onChangeTabKey = newKey => {
    this.setState({
      activeKey: newKey
    });
  };

  render() {
    const { location, match, policy_id } = this.props;
    const realID = match ? match.params.id : policy_id;
    const { policy, loading, activeKey } = this.state;

    let showAll = true
    let showOnlyAnnexThree = undefined

    if(location){
      showAll = queryString.parse(location.search).tp === "true" ? false : true;
      showOnlyAnnexThree = queryString.parse(location.search).only;
    }

    const main = (
      <Fragment>
        {loading ? (
          <Spin />
        ) : (
          <Tabs
            type="card"
            onTabClick={this.onChangeTabKey}
            className="policy-detail"
            activeKey={activeKey}
          >
            <TabPane tab="Ерөнхий мэдээлэл" key={policyTabKeys.general}>
              <Fragment>
                <Row>
                  <Col span={24} className="text-center mb-5 mt-5">
                    <span style={{ fontSize: 30 }}>{policy.policy_name}</span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Row>
                      {/* Type */}
                      <Col
                        span={4}
                        style={{ fontWeight: "bold" }}
                        className="mt-4"
                      >
                        <IntlMessages id="policy.type" />
                      </Col>
                      <Col span={20} className="mt-4">
                        {this.getTypeName(policy.policy_type_id)}
                      </Col>

                      {/* Direction */}
                      <Col
                        style={{ fontWeight: "bold" }}
                        span={4}
                        className="mt-4"
                      >
                        <IntlMessages id="policy.status" />
                      </Col>
                      <Col span={20} className="mt-4">
                        {this.getStatusName(policy.policy_status_id)}
                      </Col>

                      {/* Goal */}
                      <Col
                        style={{ fontWeight: "bold" }}
                        span={4}
                        className="mt-4"
                      >
                        <IntlMessages id="goal" />
                      </Col>
                      <Col span={20} className="mt-4">
                        {policy.goal}
                      </Col>

                      {/* Direction */}
                      <Col
                        style={{ fontWeight: "bold" }}
                        span={4}
                        className="mt-4"
                      >
                        <IntlMessages id="policy.direction" />
                      </Col>
                      <Col span={20} className="mt-4">
                        {policy.directions.map(eachDir => {
                          return <p key={eachDir.id}>{eachDir.direction}</p>;
                        })}
                      </Col>

                      {/* Annex */}
                      <Col
                        style={{ fontWeight: "bold" }}
                        span={4}
                        className="mt-4"
                      >
                        <IntlMessages id="policy.annex" />
                      </Col>
                      <Col span={20} className="mt-4">
                        <ul>
                          {policy.policy_annexes.map(eachAnnex => {
                            return (
                              <li key={eachAnnex.id}>
                                {eachAnnex.annex_name ||
                                  this.getAnnexName(eachAnnex.annex_id)}
                              </li>
                            );
                          })}
                        </ul>
                      </Col>

                      {/* Files */}
                      <Col
                        style={{ fontWeight: "bold" }}
                        span={4}
                        className="mt-4"
                      >
                        <IntlMessages id="policy.additional_annex" />
                      </Col>
                      <Col span={20} className="mt-4">
                        {policy.additional_annex_names.length === 0
                          ? "-"
                          : policy.additional_annex_names.map(eachAnnexFile => {
                              return (
                                <p key={eachAnnexFile.id}>
                                  <a href={eachAnnexFile.filepath}>
                                    {eachAnnexFile.filename}
                                  </a>
                                </p>
                              );
                            })}
                      </Col>

                      {/* Financial Source */}
                      <Col
                        style={{ fontWeight: "bold" }}
                        span={4}
                        className="mt-4"
                      >
                        <IntlMessages id="policy.financial_source" />
                      </Col>
                      <Col span={20} className="mt-4">
                        <ul>
                          {policy.policy_financial_sources.map(eachFina => {
                            return (
                              <li key={eachFina.id}>
                                {this.getFinancialName(
                                  eachFina.financial_source_id
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24}>
                    {/* total_funding_tugrug Source */}
                    <Row className="mt-4">
                      <Col style={{ fontWeight: "bold" }} span={4}>
                        <IntlMessages id="policy.total_funding_tugrug" />
                      </Col>
                      <Col span={20}>{policy.total_funding_by_policy}</Col>
                    </Row>
                  </Col>
                </Row>
                {policy_id ? null : (
                  <Row>
                    <Col span={24} className="text-right">
                      <Button
                        type="dashed"
                        onClick={() => this.props.history.goBack()}
                      >
                        <Icon type="arrow-left" />
                        <IntlMessages id="back" />
                      </Button>{" "}
                    </Col>
                  </Row>
                )}
              </Fragment>
            </TabPane>

            {showAll ? (
              <TabPane tab="Зорилт" key={policyTabKeys.objective}>
                <Objective policy={policy} policy_id={realID} />
              </TabPane>
            ) : null}

            {showAll ? (
              <TabPane tab="Бүлэг" key={policyTabKeys.article}>
                <Article policy={policy} />
              </TabPane>
            ) : null}

            {showAll && policy.policy_annexes.find(x => x.annex_id === 61) ? (
              <TabPane
                tab={<IntlMessages id="annex1" />}
                key={policyTabKeys.annex1}
              >
                <AnnexOne
                  onChangeTabKey={this.onChangeTabKey}
                  history={this.props.history}
                  policy={policy}
                />
              </TabPane>
            ) : null}

            {showAll && policy.policy_annexes.find(x => x.annex_id === 97) ? (
              <TabPane
                tab={<IntlMessages id="annex9" />}
                key={policyTabKeys.annex9}
              >
                <AnnexNine
                  onChangeTabKey={this.onChangeTabKey}
                  history={this.props.history}
                  policy={policy}
                />
              </TabPane>
            ) : null}

            {showAll && policy.policy_annexes.find(x => x.annex_id === 100) ? (
              <TabPane
                tab={<IntlMessages id="annex6" />}
                key={policyTabKeys.annex6}
              >
                <AnnexSix
                  onChangeTabKey={this.onChangeTabKey}
                  history={this.props.history}
                  policy={policy}
                />
              </TabPane>
            ) : null}

            {policy.policy_type_id !== 79 &&
            policy.policy_type_id !== 70 &&
            showAll &&
            policy.policy_annexes.find(x => x.annex_id === 69) ? (
              <TabPane
                tab={<IntlMessages id="annex2" />}
                key={policyTabKeys.annex2}
              >
                <AnnexTwo
                  onChangeTabKey={this.onChangeTabKey}
                  history={this.props.history}
                  policy={policy}
                />
              </TabPane>
            ) : null}

            {showAll && policy.policy_annexes.find(x => x.annex_id === 82) ? (
              <TabPane
                tab={<IntlMessages id="annex5" />}
                key={policyTabKeys.annex5}
              >
                <AnnexFive
                  onChangeTabKey={this.onChangeTabKey}
                  history={this.props.history}
                  policy={policy}
                />
              </TabPane>
            ) : null}

            {showAll && policy.policy_annexes.find(x => x.annex_id === 91) ? (
              <TabPane
                tab={<IntlMessages id="annex4" />}
                key={policyTabKeys.annex4}
              >
                <AnnexFour
                  onChangeTabKey={this.onChangeTabKey}
                  history={this.props.history}
                  policy={policy}
                />
              </TabPane>
            ) : null}

            {showAll && policy.policy_annexes.find(x => x.annex_id === 99) ? (
              <TabPane tab={<IntlMessages id="annex7" />} key="11">
                <AnnexSeven history={this.props.history} policy={policy} />
              </TabPane>
            ) : null}

            {showAll && policy.policy_annexes.find(x => x.annex_id === 98) ? (
              <TabPane
                tab={<IntlMessages id="annex8" />}
                key={policyTabKeys.annex8}
              >
                <AnnexEight
                  onChangeTabKey={this.onChangeTabKey}
                  history={this.props.history}
                  policy={policy}
                />
              </TabPane>
            ) : null}

            {showOnlyAnnexThree !== null &&
            showAll &&
            policy.policy_annexes.find(x => x.annex_id === 81) ? (
              <TabPane
                tab={<IntlMessages id="annex3" />}
                key={policyTabKeys.annex3}
              >
                <AnnexThree
                  onChangeTabKey={this.onChangeTabKey}
                  history={this.props.history}
                  policy={policy}
                />
              </TabPane>
            ) : null}
          </Tabs>
        )}
      </Fragment>
    );

    return policy_id ? (
      main
    ) : (
      <Card
        className="gx-card"
        title={<div><IntlMessages id="sidebar.policyDetail" />
        (
          {policy.policy_name
            ? policy.policy_name.substring(0, 75)
            : null || policy.policy_name_en
            ? policy.policy_name.substring(0, 75)
            : null}
          {policy.policy_name
            ? policy.policy_name.length > 75
              ? " ..."
              : null
            : null}
          )
        </div>
        }
      >
        {main}
      </Card>
    );
  }
}

export default Form.create()(GeneralInformation);
