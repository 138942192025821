import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SIGNIN_USER, SIGNOUT_USER, SIGNUP_USER } from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess
} from "../../appRedux/actions/Auth";

import JWTdecode from "jwt-decode";

import Axios from "axios";
import * as config from "../../restAPI/config";
import { message } from "antd";

const createUserWithEmailPasswordRequest = async (rd, email, password) =>
  await Axios.post(config.config.BASE_URL + "Auth/register", {
    rd,
    email,
    password
  })
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await Axios.post(config.config.BASE_URL + "Auth/login", { email, password })
    .then(authUser => authUser)
    .catch(error => error);

function* createUserWithEmailPassword({ payload }) {
  const { email, password, rd } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      rd,
      email,
      password
    );
    if (signUpUser.data.status_code !== 0) {
      yield put(showAuthMessage(signUpUser.data.error_msg));
    } else {
      window.location.href("/signin");
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password
    );
    if (signInUser.data.status_code !== 0) {
      yield put(showAuthMessage(signInUser.data.error_msg));
      message.error(signInUser.data.error_msg);
    } else {
      const all_windowswithRoute = signInUser.data.body.all_windows.concat(
        signInUser.data.body.submenus
      );

      localStorage.setItem("apis", JSON.stringify(signInUser.data.body.apis));
      localStorage.setItem("windows", JSON.stringify(all_windowswithRoute));
      localStorage.setItem("menus", JSON.stringify(signInUser.data.body.menus));
      localStorage.setItem("user_token", signInUser.data.body.token);
      localStorage.setItem(
        "user_datas",
        JSON.stringify(JWTdecode(signInUser.data.body.token))
      );

      if (window.OneSignal) {
        window.OneSignal.setExternalUserId(
          JWTdecode(signInUser.data.body.token).id
        );
      }

      yield put(userSignInSuccess(signInUser.data.body));
      window.location.reload();
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    localStorage.clear();
    delete Axios.defaults.headers.common["Authorization"];
    yield put(userSignOutSuccess(true));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(createUserAccount), fork(signOutUser)]);
}
