import React, { Fragment } from "react";
import { Row, Col, Form } from "antd";
import styles from "./style.module.scss";

class BothFalse extends React.Component {
  render() {
    const { dataRequest } = this.props;

    return (
      <Fragment>
        <tbody>
          {dataRequest.policy.indicators.map((eachIndiator, mainIndex) => {
            return (
              <tr key={mainIndex}>
                {/* Indicator Name */}
                <td style={{ width: 150 }} className={styles.tgNrix}>
                  {eachIndiator.indicator_name}
                </td>
                {/* unit_id */}
                <td style={{ width: 50 }} className={styles.tgNrix}>
                  {eachIndiator.unit_name}
                </td>
                {/* Base year and lvl */}
                <td style={{ width: 50 }} className={styles.tgNrix}>
                  {eachIndiator.baseline_year}
                </td>
                <td style={{ width: 150 }} className={styles.tgNrix}>
                  {eachIndiator.baseline_level}
                </td>
                {/* target year and lvl */}
                <td style={{ width: 150 }}>
                  {eachIndiator.indicator_targets.map((target, index) => {
                    return target.target_year + "-" + target.target_level;
                  })}
                </td>
                {/* Source of data */}
                <td style={{ minWidth: "350px" }} className={styles.tgNrix}>
                  {eachIndiator.source_type_name}

                  {eachIndiator.source_type_id === 32 ? ( //research
                    <Fragment>{eachIndiator.source_research_name}</Fragment>
                  ) : eachIndiator.source_type_id === 31 ? ( //organization
                    <Fragment>
                      {eachIndiator.source_organization_name ? (
                        <ul className="text-justify">
                          <li>{eachIndiator.source_organization_name}</li>
                        </ul>
                      ) : null}
                    </Fragment>
                  ) : (
                    eachIndiator.source_text
                  )}
                </td>
                {/* Org or Group */}
                <td style={{ minWidth: "350px" }} className={styles.tgNrix}>
                  <Fragment>
                    <ul className="text-justify">
                      {eachIndiator.indicator_organizations.map(
                        (eachOrganizations, indexOrgan) => {
                          return (
                            <Row
                              style={{ width: 350 }}
                              key={indexOrgan}
                              gutter={0}
                            >
                              <Col span={24}>
                                <li>{eachOrganizations.name} </li>
                              </Col>
                            </Row>
                          );
                        }
                      )}
                    </ul>
                  </Fragment>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Fragment>
    );
  }
}

export default Form.create()(BothFalse);
