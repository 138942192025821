export const config = {
  // BASE_URL: "http://10.0.1.56:5000/api/v1/",
  BASE_URL: "https://nda.development.gov.mn/api/v1/",
  // CHAT_URL: "ws://103.17.108.234:8090",
  CHAT_URL: "wss://chat.development.gov.mn",
  // GIS_URL: "http://103.50.205.71:8085"
  GIS_NO_AUTH_URL: "https://gis.development.gov.mn/public", //ene nuhur login hiigeegui uyd haragdana aa (mobile, web ylgaagui)
  GIS_AUTH_URL: "https://gis.development.gov.mn/private", // ene nuhur login hiisen uyd web deer haragdana

  AUTH_NDA_VIDEO:
    "https://drive.google.com/open?id=1gu7VUwpWYFnE-3JrOJ1yc1bLsNZ_DAqr",
  AUTH_SUM_VIDEO:
    "https://drive.google.com/open?id=171t03IDoINuNDbKipctxS_XFoa6icqsD",
  AUTH_MINISTRY_VIDEO:
    "https://drive.google.com/open?id=17ZhS-2a3wtSRwHEueeEsxePhOBOpo-W1",
  AUTH_GUEST_VIDEO:
    "https://drive.google.com/open?id=1-BhZYmLimydMlGPmnAjkLqrcDjK-qPuW"
};
