import React from "react";
import { Icon, message } from "antd";
import { viewNotification } from "../../restAPI/main";
import IntlMessages from "util/IntlMessages";
import { Link } from "react-router-dom";

class NotificationItem extends React.Component {
  getType(type) {
    if (type === 1) {
      return (
        <Icon
          style={{ fontSize: 30 }}
          type="check-circle"
          theme="twoTone"
          twoToneColor="#52c41a"
        />
      );
    } else if (type === 2 || type === 9) {
      return (
        <Icon
          style={{ fontSize: 30 }}
          type="exclamation-circle"
          theme="twoTone"
          twoToneColor="#33ccff"
        />
      );
    } else if (type === 3) {
      return (
        <Icon
          style={{ fontSize: 30 }}
          type="close-circle"
          theme="twoTone"
          twoToneColor="#ff0000"
        />
      );
    } else if (type === 4) {
      return (
        <Icon
          style={{ fontSize: 30 }}
          type="warning"
          theme="twoTone"
          twoToneColor="#cc9900"
        />
      );
    }
  }

  setView = notification => {
    viewNotification([notification.id], this.onSuccess, this.onFailed);
  };

  onSuccess = () => {
    const { fetchNotifList } = this.props;
    fetchNotifList();
  };

  onFailed(err) {
    message.error(err);
  }

  render() {
    const { notification } = this.props;
    const {
      content,
      date,
      link,
      is_viewed,
      notification_type_id
    } = notification;
    return (
      <li
        onClick={() => this.setView(notification)}
        className="gx-media"
        style={{ padding: "7px 0", cursor: "pointer" }}
      >
        {this.getType(notification_type_id)}
        <div className="gx-media-body gx-align-self-center">
          <Link to={link} style={{ width: "100%", color: "black" }}>
            <p className="ml-3  gx-fs-sm gx-mb-0">
              {!is_viewed ? (
                <span className="badge badge-success mr-2">
                  <h5 style={{ padding: 0, margin: 0, color: "white" }}>
                    <IntlMessages id="new" />
                  </h5>
                </span>
              ) : null}
              {content}
            </p>
            <span className="ml-3  gx-meta-date">
              <small>{date}</small>
            </span>
          </Link>
        </div>
      </li>
    );
  }
}

export default NotificationItem;
