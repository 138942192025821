exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_inputFocus__2HEsz:focus {\n  height: 200px; }\n\n.style_title__33l9p {\n  text-align: center;\n  margin: 20px 0px;\n  font-size: 17px;\n  font-weight: 500; }\n\n.style_tg__2RX0v {\n  border-collapse: collapse;\n  border-spacing: 0;\n  width: 100%;\n  font-size: 14px;\n  -webkit-font-feature-settings: \"tnum\";\n          font-feature-settings: \"tnum\";\n  font-variant: tabular-nums;\n  line-height: 1.3;\n  list-style: none;\n  border: 1px solid #e8e8e8;\n  border-radius: 6px 6px 0 0;\n  border-collapse: separate;\n  display: block;\n  overflow-x: auto; }\n\n.style_tg__2RX0v td {\n  font-size: 14px;\n  padding: 10px 5px;\n  border-style: solid;\n  border-width: 1px;\n  overflow: hidden;\n  word-break: normal;\n  border: 1px solid #e8e8e8;\n  align-items: center; }\n\n.style_tg__2RX0v th {\n  font-size: 14px;\n  font-weight: normal;\n  padding: 10px 5px;\n  border-style: solid;\n  border-width: 1px;\n  overflow: hidden;\n  word-break: normal;\n  color: #535353;\n  text-align: left;\n  background: #fafafa;\n  border: 1px solid #e8e8e8; }\n\n.style_tg__2RX0v > tr > th {\n  color: #535353;\n  font-weight: bold;\n  text-align: left; }\n\n.style_tg__2RX0v .style_tg9wq8__25Uhq {\n  border-color: inherit;\n  text-align: center;\n  vertical-align: middle; }\n\n.style_tg__2RX0v .style_tgNrix__EYBmv {\n  text-align: center;\n  vertical-align: middle; }\n\n.style_semi_bold_grey_background__1AQBY {\n  font-weight: 500;\n  background-color: #f3f3f3; }\n\n.style_bold_grey_background__1bjdr {\n  font-weight: 700;\n  background-color: #f3f3f3; }\n", ""]);

// exports
exports.locals = {
	"inputFocus": "style_inputFocus__2HEsz",
	"title": "style_title__33l9p",
	"tg": "style_tg__2RX0v",
	"tg9wq8": "style_tg9wq8__25Uhq",
	"tgNrix": "style_tgNrix__EYBmv",
	"semi_bold_grey_background": "style_semi_bold_grey_background__1AQBY",
	"bold_grey_background": "style_bold_grey_background__1bjdr"
};