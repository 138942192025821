import { Col, Form, message, Row, Spin } from "antd";
import React, { Component, Fragment } from "react";
import IntlMessages from "util/IntlMessages";
import ShowMoreText from "react-show-more-text";
import { policyViewAnnexSixPublicWeb } from "../../../../restAPI/main";
import styles from "./style.module.scss";

class AnnexSix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dirIndex: 0,
      objIndex: 0,
      actIndex: 0,
      outIndex: 0,
      partType: 1,
      choose_org: false,
      is_choice_groups: false,
      source_organization_id: null,
      selectedOrgs: [],
      policy_id: 0,
      annex6_outcome: "",
      start_year: 2019,
      end_year: 2030,
      directions: [
        {
          direction_id: 0,
          direction_name: "",
          direction_name_en: "",
          objectives: [
            {
              objective_id: 0,
              objective_name: "",
              objective_name_en: "",
              activities: [
                {
                  activity_id: 0,
                  activity: "",
                  activity_en: "",
                  related_article: "",
                  related_article_en: "",
                  is_deleted: false,
                  activity_outputs: [
                    {
                      activity_output_id: 0,
                      output: "",
                      output_en: "",
                      indicator: "",
                      indicator_en: "",
                      start_year: null,
                      end_year: null,
                      financial_source_id: 0,
                      total_funding_by_output: 0,
                      is_deleted: false,
                      evaluation: 0,
                      activity_organizations_1: [
                        {
                          activity_organization_id: 0,
                          participatory_type: 1,
                          is_group: true,
                          org_or_group_id: 0
                        }
                      ],
                      activity_organizations_2: [
                        {
                          activity_organization_id: 0,
                          participatory_type: 2,
                          is_group: true,
                          org_or_group_id: 0
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],

      editAddress: false,
      currentAddress: {},
      loading: true
    };
  }

  componentWillMount() {
    this.fetchgetPolicyAnnex6();
  }

  fetchgetPolicyAnnex6 = () => {
    const { policy } = this.props;
    policyViewAnnexSixPublicWeb(
      policy.id,
      "",
      this.onSuccessGet,
      err => this.onFailed(err, ""),
      "post"
    );
  };

  onSuccessGet = response => {
    let tmp = response.directions;
    tmp.map(dir => {
      dir.objectives.map(obj => {
        obj.activities.map(act => {
          act.activity_outputs.map(out => {
            let tempOrg1 = [];
            let tempOrg2 = [];
            out.activity_organizations.map(org => {
              if (org.participatory_type === 1) {
                tempOrg1.push(org);
              } else if (org.participatory_type === 2) {
                tempOrg2.push(org);
              }
              return null;
            });
            if (tempOrg1.length === 0) {
              tempOrg1.push({
                activity_organization_id: 0,
                participatory_type: 1,
                is_group: true,
                org_or_group_id: 0
              });
            }
            if (tempOrg2.length === 0) {
              tempOrg2.push({
                activity_organization_id: 0,
                participatory_type: 2,
                is_group: true,
                org_or_group_id: 0
              });
            }
            out.activity_organizations_1 = tempOrg1;
            out.activity_organizations_2 = tempOrg2;
            return null;
          });
          return null;
        });
        return null;
      });
      return null;
    });
    this.setState({
      annex6_outcome: response.annex6_outcome,
      end_year: response.end_year,
      start_year: response.start_year,
      policy_id: response.policy_id,
      directions: tmp,
      loading: false
    });
    return null;
  };

  calcFirstAct = (act, outIndex) => {
    let first = true;
    for (let i = 0; i < outIndex; i++) {
      if (act.activity_outputs[i].is_deleted === false) {
        first = false;
      }
    }
    return first;
  };

  calcRowSpan = act => {
    let count = 0;
    act.activity_outputs.map(out => {
      if (out.is_deleted === false) {
        count += 1;
      }
      return "";
    });
    return count !== 0 ? count : 1;
  };

  onFailed = (err, stateNameLoading) => {
    this.setState({
      [stateNameLoading]: false
    });
    message.error(err);
  };

  render() {
    const { policy } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 20 },
        sm: { span: 20 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };

    const { annex6_outcome, directions, loading } = this.state;
    let rowCount = 1;

    return (
      <Fragment>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Row>
            <Col className="text-right" span={24}>
              <IntlMessages id="annexLeftTitle" values={{ annexNumber: `6` }} />
            </Col>
          </Row>
          <div className={styles.title}>
            <IntlMessages id="annex6.title" />
          </div>
          {loading ? (
            <Spin />
          ) : (
            <table className={styles.tg}>
              <tbody>
                <tr>
                  <td
                    style={{ minWidth: "300px" }}
                    className={(styles.tgNrix, styles.bold_grey_background)}
                    colSpan="2"
                  >
                    <IntlMessages id="annex6.goal" />
                  </td>
                  <td className={styles.tgNrix} colSpan="7">
                    {policy.goal || policy.goal_en}
                  </td>
                </tr>
                <tr>
                  <td
                    className={(styles.tgNrix, styles.bold_grey_background)}
                    colSpan="2"
                  >
                    <IntlMessages id="annex6.outcome" />
                  </td>
                  <td className={styles.tgNrix} colSpan="7">
                    {annex6_outcome}
                  </td>
                </tr>
                {directions.map((dir, dirIndex) => {
                  return (
                    <Fragment key={"dir_" + dirIndex}>
                      <tr>
                        <td
                          className={
                            (styles.tgNrix, styles.semi_bold_grey_background)
                          }
                          colSpan="2"
                        >
                          {dirIndex + 1}{" "}
                          <IntlMessages id="annex6.areas_of_activity" />
                        </td>
                        <td className={styles.tgNrix} colSpan="7">
                          <ShowMoreText
                            lines={3}
                            more="Дэлгэрэнгүй"
                            less="Хураах"
                            anchorClass=""
                            expanded={false}
                            className="text-left"
                          >
                            {dir.direction_name}
                          </ShowMoreText>
                        </td>
                      </tr>
                      <tr style={{ textAlign: "center" }}>
                        <td
                          className={
                            (styles.tgNrix, styles.semi_bold_grey_background)
                          }
                        >
                          #
                        </td>
                        <td
                          style={{ minWidth: "300px" }}
                          className={
                            (styles.tgNrix, styles.semi_bold_grey_background)
                          }
                        >
                          <IntlMessages id="annex6.activity" />
                        </td>
                        <td
                          style={{ minWidth: "300px" }}
                          className={
                            (styles.tgNrix, styles.semi_bold_grey_background)
                          }
                        >
                          <IntlMessages id="annex6.output" />
                        </td>
                        <td
                          style={{ minWidth: "300px" }}
                          className={
                            (styles.tgNrix, styles.semi_bold_grey_background)
                          }
                        >
                          <IntlMessages id="annex6.indicators" />
                        </td>
                        <td
                          style={{ minWidth: "200px" }}
                          className={
                            (styles.tgNrix, styles.semi_bold_grey_background)
                          }
                        >
                          Хэрэгжүүлэх байршил
                        </td>
                        <td
                          style={{ minWidth: "400px" }}
                          className={
                            (styles.tgNrix, styles.semi_bold_grey_background)
                          }
                        >
                          <IntlMessages id="annex6.institution_responsible" />
                        </td>
                        <td
                          style={{ minWidth: "400px" }}
                          className={
                            (styles.tgNrix, styles.semi_bold_grey_background)
                          }
                        >
                          <IntlMessages id="annex6.counterpart_institution" />
                        </td>
                        <td
                          className={
                            (styles.tgNrix, styles.semi_bold_grey_background)
                          }
                        >
                          <IntlMessages id="annex6.timeframe" />
                        </td>
                      </tr>
                      {dir.objectives.map((obj, objIndex) => {
                        return (
                          <Fragment key={"obj_" + objIndex}>
                            <tr>
                              <td
                                className={styles.tgNrix}
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between"
                                }}
                                colSpan="8"
                              >
                                <Row>
                                  <Col span={24}>
                                    <ShowMoreText
                                      lines={3}
                                      more="Дэлгэрэнгүй"
                                      less="Хураах"
                                      anchorClass=""
                                      expanded={false}
                                      className="text-left"
                                    >
                                      {obj.objective_name}
                                    </ShowMoreText>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                            {obj.activities.map((act, actIndex) => {
                              return !act.is_deleted
                                ? act.activity_outputs.map((out, outIndex) => {
                                    return !out.is_deleted ? (
                                      <tr key={"out_" + outIndex.toString()}>
                                        <td className={styles.tgNrix}>
                                          {rowCount++}
                                        </td>
                                        {this.calcFirstAct(act, outIndex) ? (
                                          <td
                                            className={styles.tgNrix}
                                            align="right"
                                            rowSpan={this.calcRowSpan(
                                              act
                                            ).toString()}
                                          >
                                            <ShowMoreText
                                              lines={3}
                                              more="Дэлгэрэнгүй"
                                              less="Хураах"
                                              anchorClass=""
                                              expanded={false}
                                              className="text-left"
                                            >
                                              {act.activity}
                                            </ShowMoreText>
                                          </td>
                                        ) : null}
                                        <td className={styles.tgNrix}>
                                          <ShowMoreText
                                            lines={3}
                                            more="Дэлгэрэнгүй"
                                            less="Хураах"
                                            anchorClass=""
                                            expanded={false}
                                            className="text-left"
                                          >
                                            {out.output}
                                          </ShowMoreText>
                                        </td>
                                        <td className={styles.tgNrix}>
                                          <ShowMoreText
                                            lines={3}
                                            more="Дэлгэрэнгүй"
                                            less="Хураах"
                                            anchorClass=""
                                            expanded={false}
                                            className="text-left"
                                          >
                                            {out.indicator}
                                          </ShowMoreText>
                                        </td>
                                        <td
                                          style={{ minWidth: "150px" }}
                                          className={styles.tgNrix}
                                        >
                                          <span className="text-left">
                                            {out.aimag_id
                                              ? out.aimag_name
                                              : null}
                                            {out.soum_id
                                              ? ` - ${out.soum_name}`
                                              : null}
                                            {out.bag_id
                                              ? ` - ${out.bag_name}`
                                              : null}
                                          </span>
                                          <br />
                                        </td>
                                        <td
                                          style={{ minWidth: "350px" }}
                                          className={styles.tgNrix}
                                        >
                                          <Fragment>
                                            <ul className="text-justify">
                                              {out.activity_organizations_1.map(
                                                (
                                                  eachOrganizations,
                                                  indexOrgan
                                                ) => {
                                                  return (
                                                    <Row
                                                      style={{ width: 350 }}
                                                      key={indexOrgan}
                                                      gutter={0}
                                                    >
                                                      <Col span={24}>
                                                        {eachOrganizations.name ? (
                                                          <li>
                                                            {
                                                              eachOrganizations.name
                                                            }{" "}
                                                          </li>
                                                        ) : null}
                                                      </Col>
                                                    </Row>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </Fragment>
                                        </td>
                                        <td
                                          style={{ minWidth: "350px" }}
                                          className={styles.tgNrix}
                                        >
                                          <Fragment>
                                            <ul className="text-justify">
                                              {out.activity_organizations_2.map(
                                                (
                                                  eachOrganizations,
                                                  indexOrgan
                                                ) => {
                                                  return (
                                                    <Row
                                                      style={{ width: 350 }}
                                                      key={indexOrgan}
                                                      gutter={0}
                                                    >
                                                      <Col span={20}>
                                                        {eachOrganizations.name ? (
                                                          <li>
                                                            {
                                                              eachOrganizations.name
                                                            }{" "}
                                                          </li>
                                                        ) : null}
                                                      </Col>
                                                    </Row>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </Fragment>
                                        </td>
                                        <td className={styles.tgNrix}>
                                          {out.start_year} -{out.end_year}
                                        </td>
                                      </tr>
                                    ) : null;
                                  })
                                : null;
                            })}
                          </Fragment>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          )}
        </Form>
      </Fragment>
    );
  }
}

export default Form.create()(AnnexSix);
