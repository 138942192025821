import { SET_PRIVILEGES } from "constants/ActionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  privileges: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PRIVILEGES: {
      return {
        ...state,
        error: "",
        privileges: action.payload,
        loading: false
      };
    }
    default:
      return state;
  }
};
