exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_inputFocus__1h0Lo:focus {\n  height: 200px; }\n\n.style_title__pZ2f0 {\n  text-align: center;\n  margin-bottom: 30px;\n  font-weight: 400;\n  font-size: 16px; }\n\n.style_title__pZ2f0 {\n  text-align: center;\n  margin: 20px 0px;\n  font-size: 17px;\n  font-weight: 500; }\n\n.style_tg__2jza4 {\n  border-collapse: collapse;\n  border-spacing: 0;\n  width: 100%;\n  font-size: 14px;\n  -webkit-font-feature-settings: \"tnum\";\n          font-feature-settings: \"tnum\";\n  font-variant: tabular-nums;\n  line-height: 1.3;\n  list-style: none;\n  border: 1px solid #e8e8e8;\n  border-radius: 6px 6px 0 0;\n  border-collapse: separate;\n  display: block;\n  overflow-x: auto; }\n\n.style_tg__2jza4 td {\n  font-size: 14px;\n  padding: 10px 5px;\n  border-style: solid;\n  border-width: 1px;\n  overflow: hidden;\n  word-break: normal;\n  border: 1px solid #e8e8e8;\n  align-items: center; }\n\n.style_tg__2jza4 th {\n  font-size: 14px;\n  font-weight: normal;\n  padding: 10px 5px;\n  border-style: solid;\n  border-width: 1px;\n  overflow: hidden;\n  word-break: normal;\n  color: #535353;\n  text-align: left;\n  background: #fafafa;\n  border: 1px solid #e8e8e8; }\n\n.style_tg__2jza4 > tr > th {\n  color: #535353;\n  font-weight: bold;\n  text-align: left; }\n\n.style_tg__2jza4 .style_tg9wq8__3B8t_ {\n  border-color: inherit;\n  text-align: center;\n  vertical-align: middle; }\n\n.style_tg__2jza4 .style_tgNrix__3UK1z {\n  text-align: center;\n  vertical-align: middle; }\n\n.style_togtool_list__33UEx {\n  width: 100%; }\n\n.style_togtool__1UPyk {\n  width: 100%;\n  margin-top: 10px;\n  text-decoration: underline;\n  color: #038fde;\n  cursor: pointer; }\n", ""]);

// exports
exports.locals = {
	"inputFocus": "style_inputFocus__1h0Lo",
	"title": "style_title__pZ2f0",
	"tg": "style_tg__2jza4",
	"tg9wq8": "style_tg9wq8__3B8t_",
	"tgNrix": "style_tgNrix__3UK1z",
	"togtool_list": "style_togtool_list__33UEx",
	"togtool": "style_togtool__1UPyk"
};