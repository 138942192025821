exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_textCenter__IIheF {\n  text-align: center !important; }\n\n.style_title__3sU2T {\n  text-align: center;\n  margin: 20px 0px;\n  font-size: 17px;\n  font-weight: 500; }\n", ""]);

// exports
exports.locals = {
	"textCenter": "style_textCenter__IIheF",
	"title": "style_title__3sU2T"
};