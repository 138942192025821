const languageData = [
  {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us"
  },
  {
    languageId: "mongolia",
    locale: "mn",
    name: "Монгол",
    icon: "mn"
  }
];
export default languageData;
