exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_inputFocus__2ZLKV:focus {\n  height: 200px; }\n\n.style_title__3CCnk {\n  text-align: center;\n  margin-bottom: 30px;\n  font-weight: 400;\n  font-size: 16px; }\n\n.style_title__3CCnk {\n  text-align: center;\n  margin: 20px 0px;\n  font-size: 17px;\n  font-weight: 500; }\n\n.style_tg__2n-fH {\n  border-collapse: collapse;\n  border-spacing: 0;\n  width: 100%;\n  font-size: 14px;\n  -webkit-font-feature-settings: \"tnum\";\n          font-feature-settings: \"tnum\";\n  font-variant: tabular-nums;\n  line-height: 1.3;\n  list-style: none;\n  border: 1px solid #e8e8e8;\n  border-radius: 6px 6px 0 0;\n  border-collapse: separate;\n  display: block;\n  overflow-x: auto; }\n\n.style_tg__2n-fH td {\n  font-size: 14px;\n  padding: 10px 5px;\n  border-style: solid;\n  border-width: 1px;\n  overflow: hidden;\n  word-break: normal;\n  border: 1px solid #e8e8e8;\n  align-items: center; }\n\n.style_tg__2n-fH th {\n  font-size: 14px;\n  font-weight: normal;\n  padding: 10px 5px;\n  border-style: solid;\n  border-width: 1px;\n  overflow: hidden;\n  word-break: normal;\n  color: #535353;\n  text-align: left;\n  background: #fafafa;\n  border: 1px solid #e8e8e8; }\n\n.style_tg__2n-fH > tr > th {\n  color: #535353;\n  font-weight: bold;\n  text-align: left; }\n\n.style_tg__2n-fH .style_tg9wq8__1vZvG {\n  border-color: inherit;\n  text-align: center;\n  vertical-align: middle; }\n\n.style_tg__2n-fH .style_tgNrix__ozBl7 {\n  text-align: center;\n  vertical-align: middle; }\n\n.style_grey_background__2t8jf {\n  background-color: #e8e8e8; }\n", ""]);

// exports
exports.locals = {
	"inputFocus": "style_inputFocus__2ZLKV",
	"title": "style_title__3CCnk",
	"tg": "style_tg__2n-fH",
	"tg9wq8": "style_tg9wq8__1vZvG",
	"tgNrix": "style_tgNrix__ozBl7",
	"grey_background": "style_grey_background__2t8jf"
};