import React from "react";

import asyncComponent from "util/asyncComponent";
import { Route, Switch, Redirect } from "react-router-dom";

const Configuration = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}policy-annex`} />
    <Route
      path={`${match.url}/reference`}
      component={asyncComponent(() => import("./Reference"))}
    />
    <Route
      path={`${match.url}/policy-status-shifting`}
      component={asyncComponent(() => import("./PolicyStatusShifting"))}
    />
    <Route
      path={`${match.url}/policy-annex`}
      component={asyncComponent(() => import("./PolicyAnnex"))}
    />
    <Route
      path={`${match.url}/region`}
      component={asyncComponent(() => import("./Region"))}
    />
    <Route
      path={`${match.url}/region_sum`}
      component={asyncComponent(() => import("./Region"))}
    />
    <Route
      path={`${match.url}/permission/groups`}
      component={asyncComponent(() => import("./PermissionGroup"))}
    />
    <Route
      path={`${match.url}/permissions/:id`}
      component={asyncComponent(() => import("./Permissions"))}
    />
    <Route
      path={`${match.url}/role-permission`}
      component={asyncComponent(() => import("./RolePermission"))}
    />
    <Route
      path={`${match.url}/permissions`}
      component={asyncComponent(() => import("./PermissionsEdit"))}
    />
    <Route
      path={`${match.url}/gis`}
      component={asyncComponent(() => import("./GIS"))}
    />
  </Switch>
);

export default Configuration;
