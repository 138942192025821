export const BaseRequest = {
  lang: "mn"
};

//#region [ Reference ]
export const MagicReference = {
  FINANCIAL_SOURCE: 1,
  ECONOMY_CATEGORY: 2,
  FUNDING_MINISTER: 3,
  MINISTER_ORGANIZATION: 4,
  CURRENCY: 5,
  POLICY_DURATION: 6,
  POLICY_TYPE: 7,
  MEMBER_POSITION: 8,
  MEMBER_TYPE: 9,
  ANNEX: 10,
  POLICY_STATUS: 11,
  COORDINATION_EVALUATION: 12,
  GROUP: 13,
  SOURCE_TYPE: 14,
  REFLECTION_TYPE: 15,
  IMPACT_POINT: 16,
  POSITION: 17,
  ROLE: 18,
  PERMISSION: 19,
  CAPACITY_TYPE: 20,
  UNIT: 21,
  INFRASTRUCTURE_NEED: 22,
  REGION_TYPE: 24,
  FIELD_TYPE: 25,
  NOTIFICATION_TYPE: 26,
  CONTACTINFO: 27,
  SYSTEM_URL: 28
};
//#region [ EndReference ]

//#region [ Policy ]
export const policyRequest = {
  id: 0,
  policy_name: "",
  policy_name_en: "",
  is_ratified_prior_law: true,
  is_close_stand: true,
  language: 0,
  policy_type_id: 0,
  policy_status_id: 0,
  goal: "",
  goal_en: "",
  total_funding_by_policy: 0,
  start_year: 0,
  end_year: 0,
  directions: [
    {
      id: 0,
      direction: "",
      direction_en: ""
    }
  ],
  policy_annexes: [
    {
      annex_id: 0,
      is_complete: 0
    }
  ],
  policy_financial_sources: [
    {
      financial_source_id: 0,
      total_funding_by_source: 0
    }
  ],
  members: [
    {
      id: 0,
      policy_id: 0,
      member_type_id: 0,
      member_position_id: 0,
      organization_id: 0,
      stakeholder_id: 0
    }
  ],
  additional_annex_names: [
    {
      id: 0,
      policy_id: 0,
      file_id: 0,
      is_deleted: true
    }
  ]
};

// #endregion

//#region [ Annex 4 ]
export const Annex4Request = {
  policy_id: "",
  a1: "",
  a1_en: "",
  a2: "",
  a2_en: "",
  a3: "",
  a3_en: "",
  b1: "",
  b1_en: "",
  b2: "",
  b2_en: "",
  b3: "",
  b3_en: "",
  c1: "",
  c1_en: "",
  c2: "",
  c2_en: "",
  d1: "",
  d1_en: "",
  d2: "",
  d2_en: "",
  d3: "",
  d3_en: "",

  annex4_conclusion: "",
  annex4_conclusion_en: "",

  policy_researches: []
};
//#endregion

export const policy_custom_type = {
  // Энэхүү 2 бодлогыг сонгосон үед чиглэлтэй хамт зорилт оруулна
  goverment: 75, //Засгийн газрын үйл ажиллагааны хөтөлбөр
  governor: 74, //Засаг даргын үйл ажиллагааны хөтөлбөр
  ULSIIN_EDIIN_ZASAG: 79,
  AIMAG_NIISLEL_SUM_DUURGIIN_EDIIN_ZASAG: 70
};

export const policyTabKeys = {
  general: "1",
  tentative: "2",
  article: "3",
  objective: "4",
  annex1: "5",
  annex9: "6",
  annex6: "7",
  annex2: "8",
  annex5: "9",
  annex4: "10",
  annex7: "11",
  annex8: "13",
  annex3: "14"
};

export const documentDefinition = {
  pageSize: "A4",
  pageOrientation: "landscape",
  watermark: {
    angle: 35,
    fontSize: 40,
    text: "test watermark",
    color: "#3e70ff",
    opacity: 0.2,
    bold: true,
    italics: false
  },
  content: [],
  styles: {
    annex1title: {
      alignment: "right",
      fontSize: 9,
      margin: [0, 10, 0, 10]
    },
    annextitle: {
      alignment: "center",
      fontSize: 10,
      margin: [0, 10, 0, 10]
    },
    tableExample: {
      fontSize: 9
    }
  }
};

export const colorType = {
  New: "#2db7f5", //new
  requestOn: "#87d068", //requestOn
  Ongoing: "#108ee9" //ongoing
};

export const policySmartSearctVal = [
  { value: 1, text: "Нэр" },
  { value: 2, text: "Зорилго" },
  { value: 3, text: "Чиглэл" },
  { value: 4, text: "Зорилт" },
  { value: 5, text: "Үүсгэсэн байгууллага" },
  { value: 6, text: "Багийн гишүүн" },
  { value: 7, text: "Нарын бичиг" },
  { value: 8, text: "Үүсгэсэн хэрэглэгч" },
  { value: 9, text: "Хавсралт" }
];

export const researchSmartSearctVal = [
  { value: 1, text: "Нэр" },
  { value: 2, text: "Холбоотой бодлого" },
  { value: 3, text: "Зорилго" },
  { value: 4, text: "Үүсгэсэн байгууллага" },
  { value: 5, text: "Үүсгэсэн хэрэглэгч" },
  { value: 6, text: "Багийн гишүүн" }
];

export const annex5Type = [
  { value: 1, text: "Бодлого" },
  { value: 2, text: "Чиглэл" },
  { value: 3, text: "Зорилт" }
];

export const chart_1Option = {
  responsive: true,
  legend: {
    position: "right",
    align: "middle",
    labels: {
      boxWidth: 8,
      padding: 16,
      fontColor: "#77879D",
      fontFamily: "TT Hoves",
      fontSize: 8,
      fontStyle: "bold",
      usePointStyle: true
      // fontSize: 0,
    },
    fullWidth: true
  },
  title: {
    display: false
  },
  scale: {
    ticks: {
      beginAtZero: true,
      callback: function() {
        return "";
      },
      backdropColor: "transparent"
      // maxTicksLimit: 1,
    },
    angleLines: {
      color: "#77879D",
      borderWidth: 1
    },
    gridLines: {
      color: "#77879D",
      borderWidth: 1
    }
  },
  label: {
    display: false,
    enabled: false
  },
  tooltips: {
    backgroundColor: "#14151F",
    titleFontColor: "#FFFFFF",
    titleFontFamily: "TT Hoves",
    titleFontSize: 0,
    titleFontStyle: "bold",
    bodyFontColor: "#FFFFFF",
    bodyFontFamily: "TT Hoves",
    bodyFontSize: 12,
    bodyFontStyle: "bold",
    xPadding: 12,
    yPadding: 14,
    cornerRadius: 4,
    // multiKeyBackground: 'transparent',
    caretSize: 6,
    caretPadding: 10
  },
  layout: {
    padding: {
      left: 1,
      right: 0,
      top: 0,
      bottom: 0
    }
  }
};

export const chart_2Option = {
  title: {
    display: false
  },
  tooltips: {
    enabled: true
  },
  legend: {
    display: false
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          zeroLineWidth: 1,
          zeroLineColor: "rgba(13,115,212,0.32)",
          color: "transparent",
          // offsetGridLines: true,
          tickMarkLength: 0,
          drawBorder: false
        },
        ticks: {
          beginAtZero: false,
          callback: function(value, index, values) {
            return "";
          },
          padding: 0
        },
        scaleLabel: {
          display: true,
          labelString: "ТӨГРӨГ (САЯ)",
          fontColor: "#77879D",
          fontFamily: "TT Hoves",
          fontSize: 8,
          fontStyle: "bold",
          padding: 0
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          // zeroLineWidth: 1,
          zeroLineColor: "transparent",
          tickMarkLength: 0,
          color: "transparent"
        },
        ticks: {
          callback: function(value, index, values) {
            return "";
          }
        },
        scaleLabel: {
          display: true,
          labelString: "АРГА ХЭМЖЭЭ",
          fontColor: "#77879D",
          fontFamily: "TT Hoves",
          fontSize: 8,
          fontStyle: "bold",
          padding: 0
        }
      }
    ]
  }
};

export const chart_3Option = {
  legend: {
    position: "right",
    align: "middle",
    labels: {
      boxWidth: 8,
      padding: 11,
      fontColor: "#77879D",
      fontFamily: "TT Hoves",
      fontSize: 8,
      fontStyle: "bold",
      usePointStyle: true,
      innerWidth: 45
      // fontSize: 0,
    }
  },
  title: {
    display: false
  },
  scale: {
    ticks: {
      beginAtZero: true,
      callback: function() {
        return "";
      },
      backdropColor: "transparent"
      // maxTicksLimit: 1,
    },
    angleLines: {
      color: "#77879D",
      borderWidth: 1
    },
    gridLines: {
      color: "#77879D",
      borderWidth: 1
    }
  },
  label: {
    display: false,
    enabled: false
  },
  tooltips: {
    backgroundColor: "#14151F",
    titleFontColor: "#FFFFFF",
    titleFontFamily: "TT Hoves",
    titleFontSize: 0,
    titleFontStyle: "bold",
    bodyFontColor: "#FFFFFF",
    bodyFontFamily: "TT Hoves",
    bodyFontSize: 12,
    bodyFontStyle: "bold",
    xPadding: 12,
    yPadding: 14,
    cornerRadius: 4,
    // multiKeyBackground: 'transparent',
    caretSize: 6,
    caretPadding: 10
  },
  layout: {
    padding: {
      left: 1,
      right: 0,
      top: 0,
      bottom: 0
    }
  }
};

export const chart_4Option = {
  title: {
    display: false
  },
  legend: {
    display: false
  },

  tooltips: {
    // Disable the on-canvas tooltip
    enabled: false,
    custom: function(tooltipModel) {
      // Tooltip Element
      var tooltipEl = document.getElementById("chartjs-tooltip");
      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.id = "chartjs-tooltip";
        tooltipEl.innerHTML = "<table></table>";
        document.body.appendChild(tooltipEl);
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set caret Position
      tooltipEl.classList.remove("above", "below", "no-transform");
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
        tooltipEl.classList.add("no-transform");
      }

      function getBody(bodyItem) {
        return bodyItem.lines;
      }

      // Set Text
      if (tooltipModel.body) {
        var titleLines = tooltipModel.title || [];
        var bodyLines = tooltipModel.body.map(getBody);

        var innerHtml = "<thead>";

        titleLines.forEach(function(title) {
          innerHtml += "<tr><th>" + title + "</th></tr>";
        });
        innerHtml += "</thead><tbody>";

        bodyLines.forEach(function(body, i) {
          var style = "background:#fff";
          style += "; border-color:#fff";
          style += "; border-width: 2px";
          style += "; z-index: 10000";
          var span = '<span style="' + style + '"></span>';
          innerHtml += "<tr><td>" + span + body + "</td></tr>";
        });
        innerHtml += "</tbody>";
        var tableRoot = tooltipEl.querySelector("table");
        tableRoot.innerHTML = innerHtml;
      }

      // `this` will be the overall tooltip
      var position = this._chart.canvas.getBoundingClientRect();

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = "absolute";
      tooltipEl.style.left =
        position.left + window.pageXOffset + tooltipModel.caretX + "px";
      tooltipEl.style.top =
        position.top + window.pageYOffset + tooltipModel.caretY + "px";
      tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
      tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
      tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
      tooltipEl.style.padding =
        tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
      tooltipEl.style.pointerEvents = "none";
    }
  },
  scale: {
    ticks: {
      beginAtZero: true,
      callback: function() {
        return "";
      },
      backdropColor: "transparent",
      maxTicksLimit: 1,
      max: 10,
      min: 0
    },
    angleLines: {
      color: "#77879D",
      borderWidth: 1
    },
    gridLines: {
      color: "#77879D",
      borderWidth: 1
    },
    pointLabels: {
      fontColor: "#77879D",
      fontFamily: "TT Hoves",
      fontSize: 8,
      fontStyle: "bold"
    }
  },
  label: {
    display: false,
    enabled: false
  }
  // tooltips: {
  //   backgroundColor: "#14151F",
  //   titleFontColor: "#FFFFFF",
  //   titleFontFamily: "TT Hoves",
  //   titleFontSize: 0,
  //   titleFontStyle: "bold",
  //   bodyFontColor: "#FFFFFF",
  //   bodyFontFamily: "TT Hoves",
  //   bodyFontSize: 12,
  //   bodyFontStyle: "bold",
  //   xPadding: 12,
  //   yPadding: 14,
  //   cornerRadius: 4,
  //   // multiKeyBackground: 'transparent',
  //   caretSize: 6,
  //   caretPadding: 10
  // }
};

export const chart_5Option = {
  title: {
    display: false
  },
  legend: {
    display: false
  },
  // responsive: true,
  scales: {
    xAxes: [
      {
        stacked: true,
        scaleLabel: {
          display: true,
          labelString: "ЗОРИЛТУУД",
          fontColor: "#77879D",
          fontFamily: "TT Hoves",
          fontSize: 8,
          fontStyle: "bold"
          // padding: 8
        },
        ticks: {
          beginAtZero: true,
          callback: function(value, index, values) {
            return "";
          },
          padding: 0
        },
        gridLines: {
          zeroLineWidth: 1,
          zeroLineColor: "rgba(13,115,212,0.32)",
          color: "transparent",
          offsetGridLines: true,
          tickMarkLength: 0,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        stacked: true,
        scaleLabel: {
          display: true,
          labelString: "БОДЛОГЫН ТОО",
          fontColor: "#77879D",
          fontFamily: "TT Hoves",
          fontSize: 8,
          fontStyle: "bold",
          padding: 0
        },
        ticks: {
          // beginAtZero: true,
          callback: function(value, index, values) {
            return "";
          },
          max: 5,
          min: -5,
          steps: 3,
          stepValue: 5,
          padding: 0
        },
        gridLines: {
          zeroLineWidth: 1,
          zeroLineColor: "rgba(13,115,212,0.32)"
          // color: 'transparent',
          // offsetGridLines: true,
          // tickMarkLength: 0,
          // drawBorder: false
        }
      }
    ]
  }
};
