import React from "react";
import { Button, Alert, Form, Spin } from "antd";
import { Link } from "react-router-dom";
import { authConfirm } from "../restAPI/main";
import IntlMessages from "util/IntlMessages";

class RegisterConfirm extends React.Component {
  state = {
    IsSuccess: false,
    loading: true,
    message: ""
  };

  componentWillMount() {
    const { match } = this.props;
    if (match.params.id) {
      authConfirm(match.params.id, "", this.onSuccess, this.onFailed);
    }
  }

  onSuccess = response => {
    this.setState({
      loading: false,
      IsSuccess: true
    });
  };

  onFailed = err => {
    this.setState({
      loading: false,
      IsSuccess: false,
      message: err
    });
  };

  render() {
    const { loading, IsSuccess, message } = this.state;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container" style={{ maxWidth: 450 }}>
          <div className="gx-app-login-main-content">
            <div className="gx-app-login-content" style={{ width: "100%" }}>
              <div className="text-center mb-5">
                <img alt="" src={require("assets/images/logo-thumb.png")} />
              </div>

              {loading ? <Spin /> : null}
              {IsSuccess ? (
                <Alert message="Амжилттай баталгаажлаа." type="success" />
              ) : (
                <Alert message={message} type="error" />
              )}
              <br />
              <Link to="/signin">
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  icon="backward"
                  className="gx-mb-0 primaryColor"
                >
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create()(RegisterConfirm);
