import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from "../../constants/ThemeSetting";

const SubMenu = Menu.SubMenu;

class SidebarContent extends Component {
  state = {
    menus: [],
    loading: true,
    language: localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "mn"
  };

  componentWillMount() {
    let menus = [];
    const tmpmenus = localStorage.getItem("menus");
    if (tmpmenus) {
      menus = JSON.parse(localStorage.getItem("menus"));
    }
    this.setState({
      menus
    });
  }

  getNoHeaderClass = navStyle => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const { menus, language } = this.state;

    const { navStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div
            className={`gx-sidebar-notifications ${this.getNoHeaderClass(
              navStyle
            )}`}
          >
            <UserProfile />
            <AppsNavigation />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              mode="inline"
            >
              {menus.map((eachMenu, menuIndex) => {
                return (
                  <SubMenu
                    key={menuIndex}
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={language === "mn" ? eachMenu.menu : eachMenu.menu_en}
                  >
                    {eachMenu.submenus.map(eachChildMenu => {
                      return (
                        <Menu.Item key={eachChildMenu.submenu_name}>
                          <Link
                            to={{
                              state: {
                                apis: eachMenu.apis,
                                windows: eachMenu.windows
                              },
                              pathname: eachChildMenu.submenu_route
                            }}
                          >
                            <i className="icon icon-add" />
                            {language === "mn"
                              ? eachChildMenu.submenu_name
                              : eachChildMenu.submenu_name_en}
                          </Link>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                );
              })}
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  return { navStyle, themeType, locale, pathname };
};
export default connect(mapStateToProps)(SidebarContent);
