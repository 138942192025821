import React from "react";

import asyncComponent from "util/asyncComponent";
import { Route, Switch, Redirect } from "react-router-dom";
const Stakeholder = ({ match }) => (
  <Switch>
    <Redirect
      exact
      from={`${match.url}/`}
      to={`${match.url}organization-list`}
    />
    <Route
      path={`${match.url}/organization-list`}
      component={asyncComponent(() => import("./OrganizationList"))}
    />
    <Route
      path={`${match.url}/new-organization`}
      component={asyncComponent(() =>
        import("./NewOrganization/Registration.js")
      )}
    />
    <Route
      path={`${match.url}/organization-position`}
      component={asyncComponent(() => import("./OrganizationPosition"))}
    />
    <Route
      path={`${match.url}/organization/:id`}
      component={asyncComponent(() =>
        import("./NewOrganization/Registration.js")
      )}
    />
    <Route
      path={`${match.url}/stakeholder-list`}
      component={asyncComponent(() => import("./StakeholderList"))}
    />
    <Route
      path={`${match.url}/edit/:id`}
      component={asyncComponent(() =>
        import("./NewStakeholder/Registration.js")
      )}
    />

    <Route
      path={`${match.url}/editded/:id`}
      component={asyncComponent(() =>
        import("./NewStakeholder/dedadmin.js")
      )}
    />

    <Route
      path={`${match.url}/permission/:id`}
      component={asyncComponent(() => import("./PermissionsAndGroup"))}
    />
    <Route
      path={`${match.url}/new-stakeholder`}
      component={asyncComponent(() =>
        import("./NewStakeholder/Registration.js")
      )}
    />
    <Route
      path={`${match.url}/new-dedadmin`}
      component={asyncComponent(() => import("./NewStakeholder/dedadmin.js"))}
    />
  </Switch>
);

export default Stakeholder;
