import React from "react";
import { Button, Form, Input, Icon, Row, Col, message } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { authLogin } from "../restAPI/main";
import { userSignIn } from "appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import JWTdecode from "jwt-decode";

const FormItem = Form.Item;

class SignIn extends React.Component {
  state = {
    loginLoad: false
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const request = {
          email: values.email,
          password: values.password
        };
        authLogin(request, this.onSuccessLogin, this.onFailed);
        this.setState({
          loginLoad: true
        });
      }
    });
  };

  onSuccessLogin = response => {
    const all_windowswithRoute = response.all_windows.concat(response.submenus);

    localStorage.setItem("apis", JSON.stringify(response.apis));
    localStorage.setItem("windows", JSON.stringify(all_windowswithRoute));
    localStorage.setItem("menus", JSON.stringify(response.menus));
    localStorage.setItem("user_token", response.token);
    localStorage.setItem(
      "user_datas",
      JSON.stringify(JWTdecode(response.token))
    );

    if (window.OneSignal) {
      window.OneSignal.setExternalUserId(JWTdecode(response.token).id);
    }
    localStorage.setItem(
      "msg",
      response.msg ? JSON.stringify(response.msg) : null
    );
    localStorage.setItem("isShowed", false);
    window.location.reload();
  };

  onFailed = err => {
    message.error(err);
    this.setState({
      loginLoad: false
    });
  };

  componentDidUpdate() {
    if (this.props.authUser !== null) {
      this.props.history.push("/dashboard");
    }
  }

  render() {
    const { loginLoad } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container" style={{ maxWidth: 450 }}>
          <div className="gx-app-login-main-content">
            <div className="gx-app-login-content" style={{ width: "100%" }}>
              <a href="/home">
                <div className="text-center">
                  <img alt="" src={require("assets/images/logo-thumb.png")} />
                </div>
              </a>

              <Form
                onSubmit={this.handleSubmit}
                className="mt-5 gx-signin-form gx-form-row0"
              >
                <FormItem>
                  {getFieldDecorator("email", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        type: "email",
                        message: "Цахим хаягаа оруулна уу!"
                      }
                    ]
                  })(
                    <Input
                      size="large"
                      prefix={<Icon type="mail" />}
                      placeholder="Цахим хаяг"
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("password", {
                    initialValue: "",
                    rules: [
                      { required: true, message: "Нууц үгээ оруулна уу!" }
                    ]
                  })(
                    <Input
                      size="large"
                      prefix={<Icon type="key" />}
                      type="password"
                      placeholder="Нууц үг"
                    />
                  )}
                </FormItem>

                <FormItem>
                  <Button
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      cursor: "point"
                    }}
                    type="primary"
                    size="large"
                    className="gx-mb-0"
                    loading={loginLoad}
                    htmlType="submit"
                  >
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                </FormItem>
                <Row>
                  <Col span={8}>
                    <FormItem className="text-left">
                      <Link to="/home">
                        <Button
                          style={{ padding: 0 }}
                          type="link"
                          icon="backward"
                          className="gx-mb-0"
                        >
                          Нүүр хуудас
                        </Button>
                      </Link>
                    </FormItem>
                  </Col>
                  <Col span={16}>
                    <FormItem className="text-right ">
                      <Link style={{ color: "#999" }} to="/signup">
                        <IntlMessages id="app.userAuth.signUp" />
                      </Link>
                      {" / "}
                      <Link
                        style={{ color: "#999" }}
                        to="/auth/forget_password"
                      >
                        <IntlMessages id="app.userAuth.forgotPassword" />
                      </Link>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignIn
})(WrappedNormalLoginForm);
