import React, { Fragment } from "react";
import { Row, Col, Form, message, Spin, Radio } from "antd";
import IntlMessages from "util/IntlMessages";
import styles from "./style.module.scss";
import { policyViewAnnexThreePublicWeb } from "../../../../restAPI/main";

class AnnexThree extends React.Component {
  state = {
    annexThreeData: [],
    loading: true
  };

  componentWillMount() {
    const { policy } = this.props;
    policyViewAnnexThreePublicWeb(
      policy.id,
      "",
      this.onSuccessGet,
      this.onFailed
    );
  }

  onSuccessGet = response => {
    this.setState({
      annexThreeData: response,
      loading: false
    });
  };

  onFailed = err => {
    message.error(err);
    this.setState({
      loading: false
    });
  };

  render() {
    const { annexThreeData, loading } = this.state;
    return (
      <Fragment>
        <Row>
          <Col className="text-right" span={24}>
            <IntlMessages id="annexLeftTitle" values={{ annexNumber: `3` }} />
          </Col>
        </Row>
        <div className={styles.title}>
          <IntlMessages id="annex3.title" />
        </div>

        {loading ? (
          <Spin />
        ) : (
          <table class={styles.tg}>
            <thead>
              <tr>
                <th class={styles.tgc3ow} rowSpan="2">
                  #
                </th>
                <th
                  style={{ minWidth: "150px", overflowWrap: "break-word" }}
                  class={styles.tgc3ow}
                  rowSpan="2"
                >
                  Бодлгогын баримт бичгийн холбогдох зорилго, зорилт
                </th>
                <th
                  style={{ minWidth: "200px" }}
                  class={styles.tg0pky}
                  rowSpan="2"
                >
                  Байгууллага хувь хүн
                </th>
                <th class={styles.tg0lax} colSpan="4">
                  Санал гаргасан
                </th>
                <th
                  style={{ minWidth: "400px" }}
                  class={styles.tgc3ow}
                  rowSpan="2"
                >
                  Тусгасан эсэх(Тайлбар, үндэслэл)
                </th>
              </tr>
              <tr>
                <td style={{ minWidth: "100px" }} class={styles.tg0lax}>
                  Нэмэлт оруулсан
                </td>
                <td style={{ minWidth: "100px" }} class={styles.tg0lax}>
                  Хасах
                </td>
                <td style={{ minWidth: "100px" }} class={styles.tg0lax}>
                  Өөрчлөх
                </td>
                <td style={{ minWidth: "100px" }} class={styles.tgc3ow}>
                  найруулах
                </td>
              </tr>
            </thead>
            <tbody>
              {annexThreeData.map((eachCons, mainIndex) => {
                return (
                  <tr>
                    <td class={styles.tgc3ow}>{mainIndex + 1}</td>
                    <td class={styles.tgc3ow} style={{ textAlign: "justify" }}>
                      {eachCons.annex}
                    </td>
                    <td style={{ textAlign: "justify" }} class={styles.tg0pky}>
                      {eachCons.created_name}
                    </td>
                    <td class={styles.tg0lax}>
                      {eachCons.reflection_type_id === 29
                        ? eachCons.remark
                        : null}
                    </td>
                    <td class={styles.tg0lax}>
                      {eachCons.reflection_type_id === 28
                        ? eachCons.remark
                        : null}
                    </td>
                    <td class={styles.tg0lax}>
                      {eachCons.reflection_type_id === 27
                        ? eachCons.remark
                        : null}
                    </td>
                    <td class={styles.tg0lax}>
                      {eachCons.reflection_type_id === 35
                        ? eachCons.remark
                        : null}
                    </td>

                    <td class={styles.tgc3ow}>
                      {eachCons.is_grand_approved ? (
                        <Fragment>
                          <Radio.Group
                            disabled
                            value={eachCons.is_grand_approved}
                          >
                            <Radio value={1}>Дэмжсэн</Radio>
                            <Radio value={2}>Дэмжээгүй</Radio>
                          </Radio.Group>{" "}
                          <br />
                          <br />
                          {eachCons.grand_approve_desc}
                        </Fragment>
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </Fragment>
    );
  }
}

export default Form.create()(AnnexThree);
