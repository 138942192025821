import React from "react";

import asyncComponent from "util/asyncComponent";
import { Route, Switch, Redirect } from "react-router-dom";

const Configuration = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}chat`} />
    <Route
      path={`${match.url}/chat/:id`}
      component={asyncComponent(() => import("./Chat"))}
    />
    <Route
      path={`${match.url}/chat`}
      component={asyncComponent(() => import("./Chat"))}
    />
    <Route
      path={`${match.url}/file`}
      component={asyncComponent(() => import("./FileShare"))}
    />
  </Switch>
);

export default Configuration;
