import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "components/Footer/index.js";
import { Row, Col } from "antd";
import UserInfo from "components/UserInfo";

class System extends React.Component {
  render() {
    const { authUser } = this.props;

    return (
      <div
        className={`uk-clearfix tw-pt-50 tw-pt-30@m tw-pb-50 tw-pb-30@m tw-home-page`}
      >
        <div className="tw-header uk-container uk-container-large tw-mb-50 tw-mb-100@m">
          <header className="tw-header">
            <nav className="uk-navbar-container  uk-navbar">
              <div className="uk-navbar-left">
                <Link className="uk-logo uk-flex uk-flex-center" to="/home">
                  <img alt="" src="assets/images/logo.png" />
                </Link>
              </div>

              <div className="uk-navbar-right">
                <ul className="uk-navbar-nav uk-visible@l tw-main-menu uk-height-1-1">
                  <li>
                    <Link to="/home">Нүүр</Link>
                  </li>

                  <li className="uk-open">
                    <a href="/system">Системийн Танилцуулга</a>
                    <div className="uk-navbar-dropdown tw-button-primary uk-light">
                      <ul className="uk-nav uk-navbar-dropdown-nav">
                        <li className="uk-active">
                          <a href="#">Гарын авлага</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a href="/policy">Бодлого</a>
                  </li>
                  {authUser ? (
                    <li>
                      <a href="/dashboard">Хянах самбар</a>
                    </li>
                  ) : null}
                  <li>
                    <a href="/gis">Орон зайн мэдээлэл</a>
                  </li>
                  <li>
                    <div id="google_translate_element"></div>
                  </li>
                </ul>
                <ul className="uk-navbar-nav uk-flex uk-flex-middle tw-ml-60 uk-height-1-1 uk-visible@s">
                  {authUser ? (
                    <li className="uk-margin-remove tw-mr-15">
                      <UserInfo />
                    </li>
                  ) : (
                    <Fragment>
                      <li className="uk-margin-remove tw-mr-15">
                        <Link
                          className="uk-button tw-button-primary"
                          to="/signin"
                        >
                          Нэвтрэх
                        </Link>
                      </li>
                      <li className="uk-margin-remove tw-mr-20">
                        <Link
                          className="uk-button tw-button-warning"
                          style={{
                            background: "#F4C30E",
                            color: "#ffffff"
                          }}
                          to="/signup"
                        >
                          Бүртгүүлэх
                        </Link>
                      </li>
                    </Fragment>
                  )}
                </ul>
                <a
                  className="mobile-menu uk-navbar-toggle uk-hidden@l tw-mr-20"
                  href="#"
                  data-uk-toggle="target: #mobile-menu-modal"
                >
                  <span uk-icon="menu"></span>
                </a>
              </div>
            </nav>
          </header>
        </div>
        <div id="mobile-menu-modal" className="uk-modal-full" data-uk-modal>
          <div className="uk-modal-dialog">
            <button
              className="uk-modal-close-full"
              type="button"
              data-uk-close
            ></button>
            <div
              className="uk-height-viewport tw-mobile-modal uk-flex uk-flex-middle uk-flex-center"
              data-uk-scrollspy="target:>ul>li,>div>a; cls:uk-animation-slide-bottom-medium; delay: 150;"
            >
              <ul className="uk-nav-default uk-nav-parent-icon" data-uk-nav>
                <li>
                  <a href="index.html">Нүүр</a>
                </li>
                <li >
                  <a href="/system">Системийн Танилцуулга</a>
                  
                </li>
                <li>
                  <a href="/policy">Бодлого</a>
                </li>
                {authUser ? (
                  <li>
                    <a href="/dashboard">Хянах самбар</a>
                  </li>
                ) : null}
                <li>
                  <a href="/gis"> Орон зайн мэдээлэл</a>
                </li>
                <li>
                  <div id="google_translate_element"></div>
                </li>
                <li className="uk-hidden@m">
                  <Link to="/signin">Нэвтрэх</Link>
                </li>
                <li className="uk-hidden@m">
                  <Link to="/signup">Бүртгүүлэх</Link>
                </li>
              </ul>

              <div className="tw-socials tw-socials-minimal">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/ndagovMN/"
                >
                  <i className="ion-social-facebook"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/ndagovMN"
                >
                  <i className="ion-social-twitter"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCwa3Yh0SrzZlUR9IzkJHo5Q"
                >
                  <i className="ion-social-pinterest"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <section className="uk-section tw-card-2">
          <div
            className="uk-container uk-container-large"
            data-uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-small; delay: 300;"
          >
            <div className="uk-flex-center uk-grid-medium" data-uk-grid>
              <div className="uk-width-4-5 uk-width-2-3@m uk-width-1-2@l">
                <div className="tw-element tw-mr-30-rev tw-ml-30-rev">
                  <div className="uk-card uk-card-default uk-card-large uk-card-body">
                    <h3 className="uk-card-title uk-text-center uk-margin-remove">
                      Танилцуулга
                    </h3>
                    <div
                      className="uk-child-width-1-1 uk-child-width-1-3@s uk-grid-collapse tw-mt-50 tw-mb-50 text-justify"
                      data-uk-grid
                    >
                      Азийн хөгжлийн банкнаас Монгол Улсын Засгийн газарт үзүүлж
                      буй буцалтгүй тусламжийн хүрээнд Үндэсний хөгжлийн газарт
                      Хөгжлийн бодлого, төлөвлөлтийн тухай хуулийн хэрэгжилтийг
                      хангахад бүх түвшний бодлого боловсруулагч нарт дэмжлэг
                      үзүүлэх, иргэд, олон нийт, эрдэм шинжилгээ, судалгааны
                      байгууллагуудын оролцоог нэмэгдүүлэхэд технологийн
                      дэвшлийг ашиглан хялбаршуулах зорилгоор хэрэгжиж буй MON
                      TA-9371: "Хөгжлийн бодлого, төлөвлөлтийн мэдээллийн
                      нэгдсэн систем хөгжүүлэх төсөл"-ийн хүрээнд тус системийг
                      боловсруулсан.
                      <br />
                      <br />
                      НҮБ-ын Ерөнхий Ассамблейн 2015 оны чуулганаар Дэлхийн 2030
                      он хүртлэх тогтвортой хөгжлийн зорилтуудыг баталсны дагуу
                      улс орнууд эдгээр зорилтыг хэрэгжүүлэхийн тулд өөрийн орны
                      онцлогт тохируулан урт хугацааны хөгжлийн бодлогын баримт
                      бичгүүдийг боловсруулан хэрэгжүүлж эхлээд байна. Манай
                      улсын хувьд 2016 оны хоёрдугаар сард Улсын Их Хурлын 19
                      дүгээр тогтоолоор "Монгол Улсын тогтвортой хөгжлийн үзэл
                      баримтал-2030" баримт бичгийг батлан гаргаж, ирэх 15
                      жилийн алсын хараагаа тодорхойлсон нь олон улсын
                      байгууллагын зүгээс ихээхэн сайшаал хүртээд байгаа юм.
                      Үүнтэй холбогдуулан 2016 онд Засгийн газрын бүтцэд
                      Үндэсний хөгжлийн газрыг Ерөнхий сайдын шууд харьяанд
                      байгуулан үндэсний хөгжлийн нэгдсэн бодлого төлөвлөлт,
                      хөрөнгө оруулалтын хөтөлбөр, хөгжлийн бодлого төлөвлөлтийн
                      нэгдсэн мэдээллийн санг хариуцуулаад байна.
                      <br />
                      <br />
                      Түүнчлэн Улсын Их Хурлаас улс орны өмнө тулгарч буй
                      хүндрэл бэрхшээлүүдийг даван туулахад юуны өмнө салбарын
                      болон орон нутгийн хөгжлийн бодлогыг үндэсний хөгжлийн урт
                      хугацааны бодлогод нийцүүлэх, салбар хоорондын уялдаа
                      холбоог хангах, нэгдсэн удирдлага зохион байгуулалтаар улс
                      орны хөгжлийг удирдах тогтолцоог бий болгохыг Үндэсний
                      хөгжлийн газарт үүрэг болгосон. Энэ хүрээнд одоо хүчин
                      төгөлдөр мөрдөгдөж буй хөгжлийн бодлогын баримт бичгүүдийн
                      хоорондын уялдааг хангах, давхардал, хийдлийг арилгах,
                      нэгтгэх, хүчингүй болгох, шинэчлэн боловсруулах ажлыг
                      Үндэсний хөгжлийн газраас холбогдох байгууллагуудтай
                      хамтран зохион байгуулж эхлээд байна. Энэхүү ажлыг орчин
                      үеийн дэвшилтэд технологид тулгуурлан хөгжлийн бодлого
                      төлөвлөлтийн цахим тогтолцоог үүсгэж, бүх талын оролцоог
                      ханган зохион байгуулах нь зүйтэй гэж үзэж байгаа бөгөөд
                      өнгөрсөн хугацаанд гарсан алдааг давтахгүйн тулд үүлэн
                      тооцооллын системд тулгуурлан хэрэгжүүлж, 2019 онд багтаан
                      дуусгахаар төлөвлөн ажиллаж байна.
                      <br />
                      <br />
                      Засгийн газраас 2018 онд батлан гаргаад буй "Гурван
                      тулгуурт хөгжлийн бодлого"-д 2020 он гэхэд төрийн
                      үйлчилгээг цахимжуулж, мэдээллийн ил тод байдлыг хангах,
                      хөгжлийн бодлого төлөвлөлтийн цахим тогтолцоог бүрдүүлэх
                      зорилтуудыг баталсан.
                      <br />
                      <br />
                      Үүний дагуу Үндэсний хөгжлийн газар, олон улсын
                      байгууллагуудтай хамтран "Хөгжлийн бодлого төлөвлөлтийн
                      нэгдсэн систем"-ийг ашигласнаар бодлого боловсруулагч нар
                      иргэд, эрдэмтэн судлаачид, төрийн бус байгууллагууд,
                      хувийн хэвшилтэй байнга зөвлөлдөж, хамтран ажиллах бодлого
                      төлөвлөлтийн босоо болон хэвтээ тогтолцооны цахим шийдлийг
                      бий болгоно. Өөрөөр хэлбэл, өнөөгийн бодлого төлөвлөлтийн
                      босоо тогтолцоон дээр хэвтээ төлөвлөлтийн тогтолцоог бий
                      болгосноор бодлого төлөвөлөлтийн цогц тогтолцоонд шилжинэ
                      гэсэн үг юм. Энэхүү хөгжлийн бодлого төлөвлөлтийн
                      платформыг байгуулснаар Монгол Улсын хөгжлийн бодлого
                      төлөвлөлт тогтолцооны хувьд үсрэнгүй шилжилтийг хийж,
                      Засгийн газрын дэвшүүлсэн НЭГ ТӨР–НЭГ БОДЛОГО гэсэн зорилт
                      хэрэгжих үндэс суурь бүрдэх юм. Хөгжлийн бодлого
                      төлөвлөлтийн цахим шилжилтийг хийснээр оролцогч талуудад
                      дараах үр дүнгүүд гарна. Үүнд: Үндэсний түвшинд, бодлого
                      боловсруулах ажил шинжлэх ухааны үндэслэлтэй нэгдсэн арга
                      зүй, тооцоо судалгаатай болж, хөгжлийн бодлогын баримт
                      бичгүүдийн уялдааг хангах боломж бүрдэнэ.Төрийн ой
                      санамжийг сайжруулж, бодлого боловсруулагч нарыг холбосон
                      үүлэн тооцооллын системийг бий болгоно. Хөрөнгө оруулалтыг
                      оновчтой хуваарилах боломж бүрдэнэ. Бодлогын суурь
                      судалгаа, тоон мэдээллийг нэгтгэснээр үндэсний түвшний
                      бодлого төлөвлөлтийг боловсруулах, хэрэгжүүлэх, тооцоолол
                      хийх, нэгдсэн удирдлага зохион байгуулалтаар хангахад
                      хялбар болно гэж үзэж байна. Салбарын түвшинд, бодлого
                      боловсруулахад үндэсний түвшний бодлогын уялдааг хангахаас
                      гадна дэд салбарын түвшин дэх уялдаа холбоо сайжирна.
                      Тухайн салбарт хийгдсэн бүх цаг үеийн судалгааг ашиглах,
                      бусад салбарын төлөвлөлтэй уялдуулах боломж бүрдэнэ.
                      Тухайн салбарын хувийн хэвшил, мэргэжлийн холбоодын хамтын
                      ажиллагаа сайжирч, томоохон төслүүдийг хэрэгжүүлэхэд
                      хоорондоо өрсөлдөх биш харин хамтран хэрэгжүүлэх чиглэлд
                      үйл ажиллагааны өөрчлөлтүүд гарна.
                      <br />
                      <br />
                      Орон нутгийн түвшинд, бодлого боловсруулахад аймаг, сумын
                      хөгжлийн загвар, тэргүүлэх чиглэлийг тодорхойлох мэргэжил
                      арга зүйн зөвлөгөө өгч, нэгдсэн мэдээллээр хангана. Монгол
                      Улсын таван бүс, 21 аймаг, 330 сумын хөгжлийг
                      индексжүүлэх, үйлдвэрийн кластерыг тодорхойлох, хөрөнгө
                      оруулалтыг сумын түвшний нарийвчлалтай, салбараар оновчлох
                      боломж бүрдэнэ. Энэ систем ашиглалтад орсон бөгөөд
                      өнөөдрийн байдлаар улсын хэмжээнд давхардсан тоогоор 700
                      мэргэжилтэн ажиллаж байна. Олон улсын байгууллагын
                      түвшинд, Монгол Улсын Засгийн газартай хамтран ажиллах
                      чиглэлийг тодорхойлоход хөгжлийн бодлогын нэгдсэн
                      мэдээллээр хангах, түүний хүрээнд хэрэгжих төслүүд нэгдсэн
                      мэдээллээр хангагдана. Төсөл хөтөлбөр, судалгааны ажлын
                      мэдээллийг нэгтгэж, хандивлагч байгууллагад бодит мэдээлэл
                      хүргүүлсэнээр хөрөнгийн үр ашгийг дээшлүүлж, давхардлыг
                      арилгаж, уялдааг сайжруулна.
                      <br />
                      <br />
                      Хилийн чанадад байгаа иргэдийн түвшинд, хөгжлийн бодлого
                      төлөвлөлтийн талаар цогц мэдээллээр хангах замаар "Дэлхийн
                      Монголчууд" хөтөлбөрийг бодит ажил болгоно. Эх орныхоо
                      хөгжилд бодитой хувь нэмэр оруулах санал, санаачилга,
                      санхүү, хөрөнгө оруулалтыг татах үйл ажиллагааг орон зай,
                      цаг хугацаа харгалзахгүй хүргэх боломж бүрдэнэ. Хувийн
                      хэвшлийн түвшинд, хөгжлийн бодлого төлөвлөлтийн талаар
                      цогц мэдээллээр хангахаас гадна бодлого боловсруулах,
                      хэрэгжүүлэх үйл явцад хувийн хэвшлийн оролцоог хангаж,
                      харилцан зөвлөлдөх механизмыг цахим хэлбэрээр бүрдүүлнэ.
                      Улсын хэмжээнд хэрэгжүүлэхээр төлөвлөж буй бүх салбарын
                      бүтээн байгуулалтын төслүүдийн мэдээллийг цахимаар
                      танилцуулж, хамтын ажиллагааг хангана.
                      <br />
                      <br />
                      Төрийн бус байгууллагын түвшинд, хөгжлийн бодлого
                      төлөвлөлтийн талаар цогц мэдээллээр хангахаас гадна
                      бодлого боловсруулах, хэрэгжүүлэх үйл явцыг тухайн төрийн
                      бус байгууллагын түвшинд нэгтгэн зохион байгуулна. Гишүүн
                      байгууллагуудтай нь нэгдсэн бодлого, нэг цонхоор хамтран
                      ажиллах боломж бүрдэнэ. Төр хувийн хэвшлийн зааг тодорхой
                      болно.
                      <br />
                      <br />
                      Эрдэмтэн судлаачдын түвшинд, судалгааны нэгдсэн мэдээллээр
                      хангана. Судлаачдын хийсэн судалгааг нэгдсэн бүртгэлд
                      оруулж цахим тинк танк бий болгоно. Хөгжлийн бодлого
                      боловсруулах, хэрэгжүүлэх үйл явцад судлаачдын оролцоог
                      нэмэгдүүлж, судалгааны үр дүнг авч ашигладаг тогтолцоо
                      бүрдэнэ. Бодлогын судалгааг давхардуулан хийлгэж, цаг,
                      хөрөнгийг үр ашиггүй зарцуулдаг байдлыг зогсооно. Иргэдийн
                      түвшинд, хөгжлийн бодлого төлөвлөлтийн талаар цогц
                      мэдээллээр хангаж, түүний хүрээнд амьдарч буй газарт нь
                      хэрэгжих салбаруудын бүтээн байгуулалтын талаарх
                      мэдээллээр хангаж, оролцоог нэмэгдүүлнэ. Хөгжлийн бодлого
                      боловсруулах, хэрэгжүүлэх явцад олон нийтийн оролцоог
                      хангаж, мэдээллийн ил тод, нээлттэй, ойлгомжтой байдлыг
                      нэмэгдүүлнэ. Дэлхийн улс орнууд мэдээлжсэн эринд шилжиж
                      буй энэ цаг үед Монгол Улсын хөгжлийн бодлого төлөвлөлтийг
                      оновчлох, эдийн засаг, нийгмийн хөгжлийг түргэтгэх
                      зорилгоор Үндэсний хөгжлийн газраас эхлүүлсэн "ХӨГЖЛИЙН
                      БОДЛОГО ТӨЛӨВЛӨЛТИЙН ЦАХИМ ШИЛЖИЛТ" бодит үр дүнд хүрнэ
                      гэдэгт бид итгэлтэй байна.
                      <br />
                      <br />
                    </div>
                    <Row>
                      <Col span={12}></Col>
                      <Col className="text-center" span={12}>
                        <b>
                          <i>Үндэсний хөгжлийн газар 2019 он</i>
                        </b>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer publicLang={true} />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};
export default connect(mapStateToProps)(System);
