import React, { Component, Fragment } from "react";
import { Form, Row, Col, Spin, Tabs } from "antd";
import Inner from "./inner";
import External from "./external";
import Final from "./final";
import { getReferenceListPublicWeb } from "../../../../restAPI/main";
import { MagicReference } from "../../../../restAPI/request";
import styles from "./style.module.scss";
import IntlMessages from "util/IntlMessages";

const { TabPane } = Tabs;

class AnnexNine extends Component {
  state = {
    evaluations: [],
    evaluationsLoading: true,
    isFinalRefresh: false
  };

  componentWillMount() {
    this.fetchEvalution();
  }

  fetchEvalution() {
    getReferenceListPublicWeb(
      MagicReference.IMPACT_POINT,
      "",
      this.onSuccessRef,
      this.onFailed,
      "POST"
    );
  }

  onSuccessRef = response => {
    this.setState({
      evaluations: response,
      evaluationsLoading: false
    });
  };

  setFinalRef = state => {
    this.setState({
      isFinalRefresh: state
    });
  };

  render() {
    const { evaluationsLoading, evaluations } = this.state;
    return evaluationsLoading ? (
      <Spin />
    ) : (
      <Fragment>
        <Row>
          <Col className="text-right" span={24}>
            <IntlMessages id="annexLeftTitle" values={{ annexNumber: `9` }} />
          </Col>
        </Row>
        <Tabs className="mt-4" tabPosition="left" defaultActiveKey="1">
          <TabPane tab="Хүснэгт 1" key="1">
            <Row gutter={10}>
              <Col span={24}>
                <h5 className={styles.title}>
                  <IntlMessages id="annex9.table1" />
                </h5>
              </Col>
              <Col span={24}>
                <Inner
                  policy={this.props.policy}
                  evaluations={evaluations}
                  evaluationsLoading={evaluationsLoading}
                  setFinalRef={this.setFinalRef}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Хүснэгт 2" key="2">
            <Row gutter={10}>
              <Col span={24}>
                <h5 className={styles.title}>
                  <IntlMessages id="annex9.table2" />
                </h5>
              </Col>

              <Col span={24}>
                <External
                  onChangeTabKey={this.props.onChangeTabKey}
                  evaluations={evaluations}
                  evaluationsLoading={evaluationsLoading}
                  policy={this.props.policy}
                  setFinalRef={this.setFinalRef}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Хүснэгт 3" key="3">
            <Row gutter={10}>
              <Col span={24}>
                <h5 className={styles.title}>
                  <IntlMessages id="annex9.table3" />
                </h5>
              </Col>

              <Col span={24}>
                <Final
                  evaluations={evaluations}
                  evaluationsLoading={evaluationsLoading}
                  policy={this.props.policy}
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Fragment>
    );
  }
}

export default Form.create()(AnnexNine);
