exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_tg__2eU_y {\n  border-collapse: collapse;\n  border-spacing: 0;\n  width: 100%;\n  font-size: 14px;\n  -webkit-font-feature-settings: \"tnum\";\n          font-feature-settings: \"tnum\";\n  font-variant: tabular-nums;\n  line-height: 1.3;\n  list-style: none;\n  border: 1px solid #e8e8e8;\n  border-radius: 6px 6px 0 0;\n  border-collapse: separate;\n  table-layout: fixed;\n  overflow-x: auto;\n  overflow: auto; }\n\n.style_tg__2eU_y td {\n  font-size: 14px;\n  padding: 10px 5px;\n  border-style: solid;\n  border-width: 1px;\n  overflow: hidden;\n  word-break: normal;\n  border: 1px solid #e8e8e8;\n  align-items: center; }\n\n.style_tg__2eU_y th {\n  font-size: 14px;\n  font-weight: normal;\n  padding: 10px 5px;\n  border-style: solid;\n  border-width: 1px;\n  overflow: hidden;\n  word-break: normal;\n  color: #535353;\n  text-align: left;\n  background: #fafafa;\n  border: 1px solid #e8e8e8; }\n\n.style_tg__2eU_y .style_tgBaqh__3aWnt {\n  text-align: center;\n  vertical-align: top; }\n\n.style_tg__2eU_y .style_tgNrix__2eSy- {\n  text-align: center;\n  vertical-align: middle; }\n", ""]);

// exports
exports.locals = {
	"tg": "style_tg__2eU_y",
	"tgBaqh": "style_tgBaqh__3aWnt",
	"tgNrix": "style_tgNrix__2eSy-"
};