import React from "react";

import asyncComponent from "util/asyncComponent";
import { Route, Switch, Redirect } from "react-router-dom";
const Stakeholder = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
    <Route
      path={`${match.url}/createPolicy/:id`}
      component={asyncComponent(() => import("./create/index2.js"))}
    />
    <Route
      path={`${match.url}/createInvesment/:id`}
      component={asyncComponent(() => import("./create"))}
    />
    <Route
      path={`${match.url}/regulation/:id`}
      component={asyncComponent(() => import("./create/regulation.js"))}
    />

    <Route
      path={`${match.url}/policy`}
      component={asyncComponent(() => import("./create/policyList.js"))}
    />

    <Route
      path={`${match.url}/ref/edit/:id`}
      component={asyncComponent(() => import("./create/tentativeSolve.js"))}
    />

    <Route
      path={`${match.url}/viewInvestment/:id`}
      component={asyncComponent(() => import("./create/viewInvestment.js"))}
    />
    <Route
      path={`${match.url}/viewPolicy/:id`}
      component={asyncComponent(() => import("./create/viewPolicy.js"))}
    />
    <Route
      path={`${match.url}/regulationList/:id`}
      component={asyncComponent(() => import("./create/regulationList.js"))}
    />
    <Route
      path={`${match.url}/history/:id`}
      component={asyncComponent(() => import("./create/history.js"))}
    />
    <Route
      path={`${match.url}/editInvest/:id`}
      component={asyncComponent(() => import("./create/editInvest"))}
    />
    <Route
      path={`${match.url}/editPolicy/:id`}
      component={asyncComponent(() => import("./create/editPolicy.js"))}
    />

    <Route
      path={`${match.url}/list`}
      component={asyncComponent(() => import("./list"))}
    />

    {/* REPORT */}
    <Route
      path={`${match.url}/report/one/:id`}
      component={asyncComponent(() => import("./report/one/"))}
    />
    <Route
      path={`${match.url}/report/two/:id`}
      component={asyncComponent(() => import("./report/two/"))}
    />

    <Route
      path={`${match.url}/report/three/:id`}
      component={asyncComponent(() => import("./report/three/"))}
    />
    <Route
      path={`${match.url}/report/four/:id`}
      component={asyncComponent(() => import("./report/four/"))}
    />
    <Route
      path={`${match.url}/report/five/:id`}
      component={asyncComponent(() => import("./report/five/"))}
    />
    <Route
      path={`${match.url}/report/six/:id`}
      component={asyncComponent(() => import("./report/six/"))}
    />
    <Route
      path={`${match.url}/report/seven/:id`}
      component={asyncComponent(() => import("./report/seven/"))}
    />
    <Route
      path={`${match.url}/report/eight/:id`}
      component={asyncComponent(() => import("./report/eight/"))}
    />

    {/* Саналын жагсаалт */}
    <Route
      path={`${match.url}/sanal/:id`}
      component={asyncComponent(() => import("./sanal/"))}
    />
  </Switch>
);

export default Stakeholder;
