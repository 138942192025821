import antdMN from "antd/lib/locale-provider/mn_MN";
import appLocaleData from "react-intl/locale-data/mn";
import mnMessages from "../locales/mn_MN.json";

const mnLang = {
  messages: {
    ...mnMessages
  },
  antd: antdMN,
  locale: "mn",
  data: appLocaleData
};
export default mnLang;
