import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  listPolicyPublicWeb,
  typeCountPolicyPublicWeb
} from "../../restAPI/main";
import UserInfo from "components/UserInfo";
import { message } from "antd";

class Home extends React.Component {
  state = {
    Policies: [],
    PoliciesLoading: true,
    splicedPolicies: [],
    types: {
      by_type: []
    },
    pages: [],
    typesLoading: true,
    currentPage: 1,
    currentType: 0
  };

  componentWillMount() {
    typeCountPolicyPublicWeb("", this.onSuccessType, this.onFailed);
    this.fetchList(0);
  }

  fetchList = type_id => {
    this.setState({
      currentType: type_id
    });
    listPolicyPublicWeb(type_id, "", this.onSuccessPolicy, this.onFailed);
  };

  onSuccessPolicy = response => {
    const pages = [];
    const pageTotal = (response.length / 10).toFixed(0);

    for (var i = 1; i < parseInt(pageTotal) + 1; i++) {
      pages.push(i);
    }

    const tmp = [...response];
    const splicedPolicies = tmp.splice(0, 10);

    this.setState({
      Policies: response,
      PoliciesLoading: false,
      pages,
      splicedPolicies
    });
  };

  onSuccessType = response => {
    const types = response;
    types.by_type.splice(0, 1, {
      policy_type_name: "Бүгд",
      policy_title: "Бүгд",
      policy_type_id: 0,
      policy_count: response.total_count,
      color: "#202e3c"
    });

    this.setState({
      types,
      typesLoading: false
    });
  };

  onFailed = err => {
    message.error(err);
  };

  changePage = page_number => {
    const { Policies } = this.state;
    const tmo = [...Policies];

    let from = 0;
    let to = 10;

    if (page_number !== 1) {
      from = 10 * (page_number - 1);
    }

    this.setState({
      splicedPolicies: tmo.splice(from, to),
      currentPage: page_number
    });
  };

  render() {
    const {
      pages,
      splicedPolicies,
      types,
      currentPage,
      currentType
    } = this.state;
    const { authUser } = this.props;

    return (
      <div
        style={{
          backgroundImage: "url(assets/svg/bg-footer.svg)",
          backgroundPosition: "center 100px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain"
        }}
        className="uk-clearfix tw-pt-50 tw-pt-30@m tw-pb-50 tw-pb-30@m tw-single-page"
      >
        <div className="tw-header uk-container uk-container-large tw-mb-50 tw-mb-100@m">
          <header className="tw-header">
            <nav className="uk-navbar-container uk-navbar">
              <div className="uk-navbar-left">
                <a className="uk-logo uk-flex uk-flex-center" to="/dashboard">
                  <img alt="" src="assets/images/logo.png" />
                </a>
              </div>

              <div className="uk-navbar-right">
                <ul className="uk-navbar-nav uk-visible@l tw-main-menu uk-height-1-1">
                  <li className="uk-open">
                    <Link to="/home">Нүүр</Link>
                  </li>
                  <li>
                    <a href="/system">Системийн Танилцуулга</a>
                    <div className="uk-navbar-dropdown tw-button-primary uk-light">
                      <ul className="uk-nav uk-navbar-dropdown-nav">
                        <li className="uk-active">
                          <a href="#">Гарын авлага</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a href="/policy">Бодлого</a>
                  </li>
                  {authUser ? (
                    <li>
                      <a href="/dashboard">Хянах самбар</a>
                    </li>
                  ) : null}

                  <li>
                    <a href="/gis">Орон зайн мэдээлэл</a>
                  </li>
                </ul>

                <ul className="uk-navbar-nav uk-flex uk-flex-middle tw-ml-60 uk-height-1-1 uk-visible@s">
                  <li className="uk-margin-remove tw-mr-10 tw-mr-30@s uk-height-1-1">
                    <button
                      className="uk-button uk-button-default boundary-align tw-dropdown-button uk-flex uk-flex-middle uk-height-1-1"
                      type="button"
                    >
                      <img
                        alt=""
                        className="tw-mr-10"
                        src="assets/images/mn.png"
                      />
                      <span uk-icon="icon: chevron-down; ratio: 0.7"></span>
                    </button>
                    <div
                      className="uk-dropdown uk-dropdown-bottom-center uk-margin-remove"
                      uk-dropdown="pos: bottom-center"
                      style={{ minWidth: 80 }}
                    >
                      <ul className="uk-nav uk-dropdown-nav">
                        <li>
                          <a href="#">
                            <img alt="" src="assets/images/mn.png" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>

                  {authUser ? (
                    <li className="uk-margin-remove tw-mr-15">
                      <UserInfo />
                    </li>
                  ) : (
                    <Fragment>
                      <li className="uk-margin-remove tw-mr-15">
                        <Link
                          className="uk-button tw-button-primary"
                          to="/signin"
                        >
                          Нэвтрэх
                        </Link>
                      </li>
                      <li className="uk-margin-remove tw-mr-20">
                        <Link
                          className="uk-button tw-button-warning"
                          to="/signup"
                        >
                          Бүртгүүлэх
                        </Link>
                      </li>
                    </Fragment>
                  )}
                </ul>
                <a
                  className="mobile-menu uk-navbar-toggle uk-hidden@l tw-mr-20"
                  href="#"
                  data-uk-toggle="target: #mobile-menu-modal"
                >
                  <span uk-icon="menu"></span>
                </a>
              </div>
            </nav>
          </header>
        </div>
        <div id="mobile-menu-modal" className="uk-modal-full" data-uk-modal>
          <div className="uk-modal-dialog">
            <button
              className="uk-modal-close-full"
              type="button"
              data-uk-close
            ></button>
            <div
              className="uk-height-viewport tw-mobile-modal uk-flex uk-flex-middle uk-flex-center"
              data-uk-scrollspy="target:>ul>li,>div>a; cls:uk-animation-slide-bottom-medium; delay: 150;"
            >
              <ul className="uk-nav-default uk-nav-parent-icon" data-uk-nav>
                <li>
                  <a href="index.html">Нүүр</a>
                </li>
                <li>
                  <a href="/system">Системийн Танилцуулга</a>
                </li>
                <li>
                  <a href="/policy">Бодлого</a>
                </li>
                {authUser ? (
                  <li>
                    <a href="/dashboard">Хянах самбар</a>
                  </li>
                ) : null}
                <li>
                  <a href="/gis">Орон зайн мэдээлэл</a>
                </li>

                {authUser ? (
                  <li className="uk-margin-remove tw-mr-15">
                    <UserInfo />
                  </li>
                ) : (
                  <Fragment>
                    <li className="uk-margin-remove tw-mr-15">
                      <Link
                        className="uk-button tw-button-primary"
                        to="/signin"
                      >
                        Нэвтрэх
                      </Link>
                    </li>
                    <li className="uk-margin-remove tw-mr-20">
                      <Link
                        className="uk-button tw-button-warning"
                        to="/signup"
                      >
                        Бүртгүүлэх
                      </Link>
                    </li>
                  </Fragment>
                )}
              </ul>

              <div className="tw-socials tw-socials-minimal">
                <a href="#">
                  <i className="ion-social-facebook"></i>
                </a>
                <a href="#">
                  <i className="ion-social-twitter"></i>
                </a>
                <a href="#">
                  <i className="ion-social-pinterest"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <section className="uk-section tw-card-1">
          <div
            className="uk-container uk-container-large"
            data-uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-small; delay: 300;"
          >
            <div className="uk-flex-center uk-grid-medium" data-uk-grid>
              <div className="uk-width-1-2">
                <div className="tw-element tw-mr-30-rev tw-ml-30-rev">
                  <div className="uk-card uk-card-default uk-card-large uk-card-body">
                    <h3 className="uk-card-title tw-card-title-small uk-text-center uk-margin-remove">
                      Хүчин төгөлдөр бодлогууд
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="tw-box-4 uk-container uk-container-large">
          <section className="uk-section uk-section-large tw-pl-0 tw-pr-0 tw-pt-0">
            <div
              className="uk-grid-medium"
              data-uk-grid
              data-uk-scrollspy="target: > *; cls:uk-animation-slide-bottom-medium; delay: 300;"
            >
              <div className="uk-width-1-1 uk-width-4-12@l">
                <div className="uk-background-default tw-shadow">
                  <div className="uk-overflow-auto">
                    <table
                      className="uk-table tw-table uk-table-top uk-table-hover tw-table-hover uk-margin-remove"
                      style={{ minWidth: "300px" }}
                    >
                      <thead className="tw-button-primary uk-light">
                        <tr>
                          <th className="uk-table-expand" colSpan="2">
                            Бодлогын төрлүүд
                          </th>
                        </tr>
                      </thead>
                      <tbody className="tw-pt-10 tw-pb-30 uk-display-block">
                        {types.by_type.map(eachType => {
                          return (
                            <tr
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.fetchList(eachType.policy_type_id)
                              }
                              key={eachType.policy_type_id}
                              className={
                                currentType === eachType.policy_type_id
                                  ? "active"
                                  : null
                              }
                            >
                              <td>
                                <a href="#!">{eachType.policy_type_name}</a>
                              </td>
                              <td>
                                <a href="#!">{eachType.policy_count}</a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-1 uk-width-8-12@l">
                <div className="uk-background-default tw-shadow">
                  <div className="uk-overflow-auto">
                    <table
                      className="uk-table tw-table uk-table-middle uk-table-hover uk-margin-remove"
                      style={{ minWidth: "950px" }}
                    >
                      <thead className="tw-button-primary uk-light">
                        <tr>
                          <th className="tw-width-medium">Бодлогын нэр</th>
                          <th className="uk-text-nowrap">
                            Хэрэгжүүлэх хугацаа
                          </th>
                          <th className="tw-width-small">Хавсралт</th>
                        </tr>
                      </thead>
                      <tbody>
                        {splicedPolicies.map(eachPolicy => {
                          return (
                            <tr key={eachPolicy.id}>
                              <td>
                                <a href="#!">{eachPolicy.policy_name}</a>
                              </td>
                              <td className="uk-text-nowrap">
                                <ul className="uk-list tw-yellow-circle">
                                  <li className="active">
                                    <a href="#!">
                                      {eachPolicy.start_year} -{" "}
                                      {eachPolicy.end_year}
                                    </a>
                                  </li>
                                </ul>
                              </td>
                              <td className="uk-text-nowrap uk-flex uk-flex-between">
                                <ul className="uk-pagination uk-margin-remove tw-pagination tw-mr-40">
                                  {eachPolicy.PolicyAnnexes.map(eachAnnex => {
                                    return (
                                      <li key={eachAnnex.id}>
                                        <a href="#!">{eachAnnex.rank}</a>
                                      </li>
                                    );
                                  })}
                                </ul>
                                <div>
                                  <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="tw-mr-25"
                                    href={`/detail?policy=${eachPolicy.id}`}
                                  >
                                    <img alt="" src="assets/svg/list.svg" />
                                  </a>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="tw-pl-30 tw-pr-30 tw-pb-40 tw-mt-10">
                    <hr />
                    <ul className="uk-pagination uk-margin-remove uk-flex-right tw-pagination style-2">
                      {pages.map(eachPage => {
                        return (
                          <li
                            className={
                              eachPage === currentPage ? "uk-active" : null
                            }
                            onClick={() => this.changePage(eachPage)}
                            key={eachPage}
                          >
                            <span style={{ cursor: "pointer" }}>
                              {eachPage}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="uk-container tw-footer uk-container-large"
          data-uk-scrollspy="target: > *; cls:uk-animation-slide-bottom-medium; delay: 300;"
        >
          <footer className="uk-section uk-section-default">
            <div
              className="uk-grid-medium"
              data-uk-grid
              data-uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-medium; delay: 300;"
            >
              <div className="uk-width-1-1 uk-width-1-2@m uk-width-4-12@l">
                <div className="uk-flex uk-flex-bottom tw-mb-20">
                  <a href="#" className="tw-mr-25">
                    <img alt="" src="assets/images/adb.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/itools.png" />
                  </a>
                </div>
                <p className="uk-margin-remove uk-text-lead">
                  ADB-ийн санхүүжилтээр iTools ХК гүйцэтгэв.
                </p>
                <p className="uk-margin-remove uk-text-lead tw-mb-35">
                  Зохиогчийн эрх хуулиар хамгаалагдсан ©2019 он
                </p>
                <ul className="uk-iconnav uk-iconnav-horizontal tw-social-icon">
                  <li className="flip-box">
                    <a className="side-f" href="#">
                      <img alt="" src="assets/svg/facebook.svg" />
                    </a>
                    <a className="tw-bg-facebook side-b" href="#">
                      <img alt="" src="assets/svg/facebook.svg" />
                    </a>
                  </li>
                  <li className="flip-box">
                    <a className="side-f" href="#">
                      <img alt="" src="assets/svg/twitter.svg" />
                    </a>
                    <a className="tw-bg-twitter side-b" href="#">
                      <img alt="" src="assets/svg/twitter.svg" />
                    </a>
                  </li>
                  <li className="flip-box">
                    <a className="side-f" href="#">
                      <img alt="" src="assets/svg/youtube.svg" />
                    </a>
                    <a className="tw-bg-youtube side-b" href="#">
                      <img alt="" src="assets/svg/youtube.svg" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tw-link uk-width-1-1 uk-width-1-2@m uk-width-3-12@l">
                <h6 className="uk-text-uppercase">Холбоос линк</h6>
                <ul className="uk-list">
                  <li>
                    <a
                      href="https://president.mn/"
                      className="uk-button-text"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Монгол Улсын Ерөнхийлөгч
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.parliament.mn/"
                      className="uk-button-text"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Монгол Улсын Их Хурал
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.iaac.mn/"
                      className="uk-button-text"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Авлигатай тэмцэх газар
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://burtgel.mn/"
                      className="uk-button-text"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Улсын бүртгэлийн ерөнхий газар
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tw-link uk-width-1-1 uk-width-1-2@m uk-width-5-12@l">
                <h6 className="uk-text-uppercase">Холбоо барих</h6>
                <ul className="uk-list">
                  <li>
                    Үйлчилгээ, захидал харилцааны ажилтан:
                    <span className="tw-ml-10">Б.Шинэбаяр, М.Сувданчимэг</span>
                  </li>
                  <li>
                    Утас:
                    <span className="tw-ml-10">
                      <a
                        href="#"
                        className="uk-button-text"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        51-263333
                      </a>
                    </span>
                  </li>
                  <li>
                    Нэг цэгийн үйлчилгээний төв:
                    <span className="tw-ml-10">
                      <a
                        href="tel:77001717"
                        className="uk-button-text"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        7700 1717
                      </a>
                    </span>
                  </li>
                  <li>
                    И-Мэйл:
                    <span className="tw-ml-10">
                      <a
                        href="mailto:info@nda.gov.mn"
                        className="uk-button-text"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        info@nda.gov.mn
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <ul
              className="uk-iconnav uk-iconnav-horizontal tw-social-icon tw-social-icon-large"
              style={{ position: "absolute", top: "-19px", right: "40px" }}
            >
              <li className="flip-box">
                <a
                  id="target-1"
                  className="tw-button-primary side-f"
                  href="#top"
                  data-uk-scroll
                >
                  <img alt="" src="assets/svg/arrow.svg" />
                </a>
                <a
                  id="target-2"
                  className="tw-button-warning side-b"
                  href="#top"
                  data-uk-scroll
                >
                  <img alt="" src="assets/svg/arrow.svg" />
                </a>
              </li>
            </ul>
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};
export default connect(mapStateToProps)(Home);
