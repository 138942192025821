import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import UserInfo from "components/UserInfo";
import queryString from "query-string";
import Detail from "../../routes/Research/ResearchDetail";

class PublicResearchDetail extends React.Component {
  state = {
    searchKey: ""
  };

  render() {
    const { location } = this.props;
    let research_id = "";
    if (location) {
      research_id = queryString.parse(location.search).research;
    }
    const { authUser } = this.props;

    return (
      <div
        className={`uk-clearfix tw-pt-50 tw-pt-30@m tw-pb-50 tw-pb-30@m tw-home-page`}
      >
        <div className="tw-header uk-container uk-container-large tw-mb-50 tw-mb-100@m">
          <header className="tw-header">
            <nav className="uk-navbar-container  uk-navbar">
              <div className="uk-navbar-left">
                <Link className="uk-logo uk-flex uk-flex-center" to="/home">
                  <img alt="" src={"/assets/images/logo.png"} />
                </Link>
              </div>

              <div className="uk-navbar-right">
                <ul className="uk-navbar-nav uk-visible@l tw-main-menu uk-height-1-1">
                  <li className="uk-open">
                    <Link to="/home">Нүүр</Link>
                  </li>
                  <li>
                    <a href="/system">Системийн Танилцуулга</a>
                    <div className="uk-navbar-dropdown tw-button-primary uk-light">
                      <ul className="uk-nav uk-navbar-dropdown-nav">
                        <li className="uk-active">
                          <a href="#">Гарын авлага</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a href="/policy">Бодлого</a>
                  </li>
                  {authUser ? (
                    <li>
                      <a href="/dashboard">Хянах самбар</a>
                    </li>
                  ) : null}
                  <li>
                    <a href="/gis">Орон зайн мэдээлэл</a>
                  </li>
                  <li>
                    <div id="google_translate_element"></div>
                  </li>
                </ul>
                <ul className="uk-navbar-nav uk-flex uk-flex-middle tw-ml-60 uk-height-1-1 uk-visible@s">
                  <li className="uk-margin-remove tw-mr-10 tw-mr-30@s uk-height-1-1">
                    <button
                      className="uk-button uk-button-default boundary-align tw-dropdown-button uk-flex uk-flex-middle uk-height-1-1"
                      type="button"
                    >
                      <img
                        alt=""
                        className="tw-mr-10"
                        src="/assets/images/mn.png"
                      />
                      <span uk-icon="icon: chevron-down; ratio: 0.7"></span>
                    </button>
                    <div
                      className="uk-dropdown uk-dropdown-bottom-center uk-margin-remove"
                      uk-dropdown="pos: bottom-center"
                      style={{ minWidth: "80px" }}
                    >
                      <ul className="uk-nav uk-dropdown-nav">
                        <li>
                          <a href="#">
                            <img alt="" src="/assets/images/mn.png" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>

                  {authUser ? (
                    <li className="uk-margin-remove tw-mr-15">
                      <UserInfo />
                    </li>
                  ) : (
                    <Fragment>
                      <li className="uk-margin-remove tw-mr-15">
                        <Link
                          className="uk-button tw-button-primary"
                          to="/signin"
                        >
                          Нэвтрэх
                        </Link>
                      </li>
                      <li className="uk-margin-remove tw-mr-20">
                        <Link
                          className="uk-button tw-button-warning"
                          style={{
                            background: "#F4C30E",
                            color: "#ffffff"
                          }}
                          to="/signup"
                        >
                          Бүртгүүлэх
                        </Link>
                      </li>
                    </Fragment>
                  )}
                </ul>
                <a
                  className="mobile-menu uk-navbar-toggle uk-hidden@l tw-mr-20"
                  href="#"
                  data-uk-toggle="target: #mobile-menu-modal"
                >
                  <span uk-icon="menu"></span>
                </a>
              </div>
            </nav>
          </header>
        </div>
        <div id="mobile-menu-modal" className="uk-modal-full" data-uk-modal>
          <div className="uk-modal-dialog">
            <button
              className="uk-modal-close-full"
              type="button"
              data-uk-close
            ></button>
            <div
              className="uk-height-viewport tw-mobile-modal uk-flex uk-flex-middle uk-flex-center"
              data-uk-scrollspy="target:>ul>li,>div>a; cls:uk-animation-slide-bottom-medium; delay: 150;"
            >
              <ul className="uk-nav-default uk-nav-parent-icon" data-uk-nav>
                <li>
                  <a href="/home">Нүүр</a>
                </li>
                <li >
                  <a href="/system">Системийн Танилцуулга</a>
                  
                </li>
                <li>
                  <a href="/policy">Бодлого</a>
                </li>
                {authUser ? (
                  <li>
                    <a href="/dashboard">Хянах самбар</a>
                  </li>
                ) : null}
                <li>
                  <a href="/gis"> Орон зайн мэдээлэл</a>
                </li>
                <li className="uk-hidden@m">
                  <Link to="/signin">Нэвтрэх</Link>
                </li>
                <li className="uk-hidden@m">
                  <Link to="/signup">Бүртгүүлэх</Link>
                </li>
              </ul>

              <div className="tw-socials tw-socials-minimal">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/ndagovMN/"
                >
                  <i className="ion-social-facebook"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/ndagovMN"
                >
                  <i className="ion-social-twitter"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCwa3Yh0SrzZlUR9IzkJHo5Q"
                >
                  <i className="ion-social-pinterest"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <section className="uk-section tw-card-2">
          <div
            className="uk-container uk-container-large"
            data-uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-small; delay: 300;"
          >
            <div className="uk-flex-center uk-grid-medium" data-uk-grid>
              <div className="uk-width-4-5 uk-width-12-12@m uk-width-12-12@l">
                <div className="tw-element tw-mr-30-rev tw-ml-30-rev">
                  <div className="uk-card uk-card-default uk-card-large uk-card-body">
                    <Detail searchResearch={research_id} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="uk-container tw-footer uk-container-large"
          data-uk-scrollspy="target: > *; cls:uk-animation-slide-bottom-medium; delay: 300;"
        >
          <footer className="uk-section uk-section-default">
            <div
              className="uk-grid-medium"
              data-uk-grid
              data-uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-medium; delay: 300;"
            >
              <div
                style={{ margin: "auto" }}
                className="uk-width-1-1 uk-width-1-2@m uk-width-4-12@l"
              >
                <div className="uk-flex uk-flex-bottom tw-mb-20">
                  <a href="#" className="tw-mr-25">
                    <img alt="" width="350" src="/assets/images/logo.png" />
                  </a>
                </div>
              </div>
              <div className="tw-link uk-width-1-1 uk-width-1-2@m uk-width-4-12@l">
                <h6 className="uk-text-uppercase">
                  <b>Холбоо барих</b>
                </h6>
                <ul className="uk-list" style={{ marginTop: 20 }}>
                  <li>
                    Салбарын хөгжлийн бодлого, зохицуулалтын газрын мэргэжилтэн:
                    <span className="tw-ml-10">Д. Сугар</span>
                  </li>
                  <li>
                    Утас:
                    <span className="tw-ml-10">
                      <a
                        href="#"
                        className="uk-button-text"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        261568
                      </a>
                    </span>
                  </li>
                  <li>
                    И-Мэйл:
                    <span className="tw-ml-10">
                      <a
                        href="mailto:sugar.d@nda.gov.mn"
                        className="uk-button-text"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        sugar.d@nda.gov.mn
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
              <div
                style={{ marginTop: 34 }}
                className="tw-link uk-width-1-1 uk-width-1-2@m uk-width-4-12@l"
              >
                <p
                  style={{ marginTop: 20 }}
                  className="uk-margin-remove uk-text-lead tw-mb-35"
                >
                  <b>Үндэсний Хөгжлийн Газар</b> <br />
                  Улаанбаатар хот, Чингэлтэй дүүрэг, Нэгдсэн Үндэстний Гудамж
                  5/1 Засгийн газрын ll байр
                </p>
                <ul
                  style={{ float: "right" }}
                  className="uk-iconnav uk-iconnav-horizontal tw-social-icon uk-text-right"
                >
                  <li className="flip-box">
                    <a
                      className="side-f"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/ndagovMN/"
                    >
                      <img alt="" src="/assets/svg/facebook.svg" />
                    </a>
                    <a
                      className="tw-bg-facebook side-b"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/ndagovMN/"
                    >
                      <img alt="" src="/assets/svg/facebook.svg" />
                    </a>
                  </li>
                  <li className="flip-box">
                    <a
                      className="side-f"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/ndagovMN"
                    >
                      <img alt="" src="/assets/svg/twitter.svg" />
                    </a>
                    <a
                      className="tw-bg-twitter side-b"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/ndagovMN"
                    >
                      <img alt="" src="/assets/svg/twitter.svg" />
                    </a>
                  </li>
                  <li className="flip-box">
                    <a
                      className="side-f"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/channel/UCwa3Yh0SrzZlUR9IzkJHo5Q"
                    >
                      <img alt="" src="/assets/svg/youtube.svg" />
                    </a>
                    <a
                      className="tw-bg-youtube side-b"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/channel/UCwa3Yh0SrzZlUR9IzkJHo5Q"
                    >
                      <img alt="" src="/assets/svg/youtube.svg" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <ul
              className="uk-iconnav uk-iconnav-horizontal tw-social-icon tw-social-icon-large"
              style={{ position: "absolute", top: "-19px", right: "40px" }}
            >
              <li className="flip-box">
                <a
                  key="target-1"
                  className="tw-button-primary side-f"
                  href="#top"
                  data-uk-scroll
                >
                  <img alt="" src="/assets/svg/arrow.svg" />
                </a>
                <a
                  key="target-2"
                  className="tw-button-warning side-b"
                  href="#top"
                  data-uk-scroll
                >
                  <img alt="" src="/assets/svg/arrow.svg" />
                </a>
              </li>
            </ul>
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};
export default connect(mapStateToProps)(PublicResearchDetail);
