import React, { Component, Fragment } from "react";
import { Col, Form, message, Row, Select } from "antd";
import ShowMoreText from "react-show-more-text";
import IntlMessages from "util/IntlMessages";
import {
  policyViewAnnexEighPublicWeb,
  getReferenceListPublicWeb
} from "../../../../restAPI/main";
import { MagicReference } from "../../../../restAPI/request";
import styles from "./style.module.scss";

const { Option } = Select;

class AnnexEight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objIndex: 0,
      outIndex: 0,
      partType: 1,
      choose_org: false,
      is_choice_groups: false,
      source_organization_id: null,
      selectedOrgs: [],
      mainFinancials: [],
      mainFinancialsLoading: true,
      formData: [
        {
          policy_id: 0,
          start_year: 2019,
          end_year: 2019,
          direction_id: 0,
          direction_name: "",
          direction_name_en: "",
          objective_id: 0,
          objective_name: "",
          objective_name_en: "",
          activity: {
            activity_id: 0,
            outputs: [
              {
                output_id: 0,
                output: null,
                output_en: null,
                start_year: 2019,
                end_year: 2019,
                financial_source_id: 0,
                total_funding_by_output: 0,
                budgets: [
                  {
                    budget_id: 0,
                    policy_id: 0,
                    output_id: 0,
                    target_year: 0,
                    outcome: "",
                    outcome_en: "",
                    budget: 0,
                    moneys: []
                  }
                ],
                organizations_1: [
                  {
                    organization_id: 0,
                    policy_id: 0,
                    activity_output_id: 0,
                    participatory_type: 1,
                    is_group: true,
                    org_or_group_id: null
                  }
                ],
                organizations_2: [
                  {
                    organization_id: 0,
                    policy_id: 0,
                    activity_output_id: 0,
                    participatory_type: 2,
                    is_group: true,
                    org_or_group_id: null
                  }
                ],
                organizations_3: [
                  {
                    organization_id: 0,
                    policy_id: 0,
                    activity_output_id: 0,
                    participatory_type: 3,
                    is_group: true,
                    org_or_group_id: null
                  }
                ]
              }
            ]
          }
        }
      ]
    };
  }

  componentWillMount() {
    this.fetchgetPolicyAnnex8();
    this.fetchFinancialList();
  }

  fetchgetPolicyAnnex8 = () => {
    const { policy } = this.props;
    policyViewAnnexEighPublicWeb(
      policy.id,
      "",
      this.onSuccessGet,
      err => this.onFailed(err, ""),
      "post"
    );
  };

  onSuccessGet = response => {
    response.map(obj => {
      if (obj.activity) {
        if (obj.activity.outputs.length === 0) {
          obj.activity.outputs = [
            {
              output_id: 0,
              output: null,
              output_en: null,
              start_year: obj.start_year,
              end_year: obj.end_year,
              financial_source_id: 0,
              total_funding_by_output: 0,
              budgets: [],
              organizations: [
                {
                  organization_id: 0,
                  policy_id: obj.policy_id,
                  activity_output_id: 0,
                  participatory_type: 1,
                  is_group: true,
                  org_or_group_id: null
                }
              ]
            }
          ];
        }
        obj.activity.outputs.map(out => {
          if (out.start_year < obj.start_year) {
            out.start_year = obj.start_year;
          }

          if (out.end_year > obj.end_year) {
            out.end_year = obj.end_year;
          } else if (out.end_year < obj.start_year) {
            out.end_year = obj.start_year;
          }

          if (out.budgets.length === 0) {
            for (var year = obj.start_year; year <= obj.end_year; year++) {
              out.budgets.push({
                budget_id: 0,
                policy_id: obj.policy_id,
                output_id: out.output_id,
                target_year: year,
                outcome: "",
                outcome_en: "",
                budget: 0,
                moneys: []
              });
            }
          }
          let tempOrg1 = [];
          let tempOrg2 = [];
          let tempOrg3 = [];
          out.organizations.map(org => {
            if (org.participatory_type === 1) {
              tempOrg1.push(org);
            } else if (org.participatory_type === 2) {
              tempOrg2.push(org);
            } else if (org.participatory_type === 3) {
              tempOrg3.push(org);
            }
            return null;
          });
          out.organizations_1 = tempOrg1;
          out.organizations_2 = tempOrg2;
          out.organizations_3 = tempOrg3;
          return null;
        });
      } else {
        obj.activity = {
          activity_id: 0,
          outputs: [
            {
              output_id: 0,
              output: null,
              output_en: null,
              start_year: obj.start_year,
              end_year: obj.end_year,
              financial_source_id: 0,
              total_funding_by_output: 0,
              evaluation: 0,
              budgets: [],
              organizations_1: [],
              organizations_2: [],
              organizations_3: []
            }
          ]
        };
        for (var year = obj.start_year; year <= obj.end_year; year++) {
          obj.activity.outputs[obj.activity.outputs.length - 1].budgets.push({
            budget_id: 0,
            policy_id: obj.policy_id,
            output_id: obj.output_id,
            target_year: year,
            outcome: "",
            outcome_en: "",
            budget: 0,
            moneys: []
          });
        }
      }

      return null;
    });

    this.setState({
      formData: response
    });
  };

  checkBudgetsYear = (budgets, year) => {
    budgets.some(bud => {
      if (bud.target_year === year) {
        return true;
      } else {
        return false;
      }
    });
  };

  fetchFinancialList = () => {
    getReferenceListPublicWeb(
      MagicReference.FINANCIAL_SOURCE,
      "",
      this.onSuccessFinancial,
      err => this.onFaild(err, "mainFinancialsLoading"),
      "POST"
    );
  };

  onSuccessFinancial = response => {
    this.setState({
      mainFinancials: response,
      mainFinancialsLoading: false
    });
  };

  onFailed = (err, stateNameLoading) => {
    this.setState({
      [stateNameLoading]: false
    });
    message.error(err);
  };

  yearRendarCalc = () => {
    if (this.state.formData[0]) {
      const { start_year, end_year } = this.state.formData[0];
      let result = [];
      for (var year = start_year; year <= end_year; year++) {
        result.push(
          <th
            style={{ minWidth: 300 }}
            key={"yeear_" + year}
            className={styles.tg9wq8}
            colSpan="2"
          >
            {year}
          </th>
        );
      }
      return result;
    }
    return null;
  };

  yearRendarCalc2 = () => {
    if (this.state.formData[0]) {
      const { start_year, end_year } = this.state.formData[0];
      let result = [];
      for (var year = start_year; year <= end_year; year++) {
        result.push(
          <Fragment key={"ye_" + year}>
            <th className={styles.tg9wq8}>
              <IntlMessages id="annex8.outcomes" />
            </th>
            <th className={styles.tg9wq8}>
              <IntlMessages id="annex8.budget" />
            </th>
          </Fragment>
        );
      }
      return result;
    }
    return null;
  };

  yearRendarCalc3 = (getFieldDecorator, objIndex, obj, outIndex, out) => {
    if (this.state.formData[0]) {
      const { start_year, end_year } = this.state.formData[0];
      let result = [];
      for (var year = start_year; year <= end_year; year++) {
        result.push(
          this.budgetRendar(
            getFieldDecorator,
            objIndex,
            obj,
            outIndex,
            out,
            year - start_year,
            year
          )
        );
      }
      return result;
    }
    return null;
  };

  budgetRendar = (
    getFieldDecorator,
    objIndex,
    obj,
    outIndex,
    out,
    budIndex,
    year
  ) => {
    const tempArray = this.state.formData;
    return (
      <Fragment key={"bud_" + budIndex}>
        <td style={{ minWidth: 100 }} className={styles.tgNrix}>
          {tempArray[objIndex].activity.outputs[outIndex].start_year <= year &&
          year <= tempArray[objIndex].activity.outputs[outIndex].end_year ? (
            <Fragment>
              <Form.Item style={{ marginBottom: 0 }}>
                {out.budgets[budIndex] ? out.budgets[budIndex].outcome : null}
              </Form.Item>
            </Fragment>
          ) : null}
        </td>
        <td className={styles.tgNrix} style={{ minWidth: 100 }}>
          {tempArray[objIndex].activity.outputs[outIndex].start_year <= year &&
          year <= tempArray[objIndex].activity.outputs[outIndex].end_year ? (
            <Fragment>
              {out.budgets[budIndex] ? out.budgets[budIndex].moneys.map((eachMoney, eachMoneyIndex) => {
                return (
                  this.getFinianName(eachMoney.financial_source_id) +
                  " - " +
                  eachMoney.amount
                );
              }) : null}
            </Fragment>
          ) : null}
        </td>
      </Fragment>
    );
  };

  yearPickerItem = (type, objIndex, outIndex) => {
    if (this.state.formData[0]) {
      const { start_year, end_year } = this.state.formData[0];
      const tempArray = this.state.formData;
      let result = [];
      for (let i = start_year; i <= end_year; i++) {
        if (type === 1) {
          result.push(
            <Option
              key={"year__" + i}
              disabled={
                tempArray[objIndex].activity.outputs[outIndex].end_year < i
              }
              value={i}
            >
              {i}
            </Option>
          );
        } else if (type === 2) {
          result.push(
            <Option
              key={"year__" + i}
              disabled={
                tempArray[objIndex].activity.outputs[outIndex].start_year > i
              }
              value={i}
            >
              {i}
            </Option>
          );
        }
      }
      return result;
    }
    return null;
  };

  calcOutAllFinancial = out => {
    const { mainFinancials } = this.state;
    let sum = {};
    mainFinancials.map(eachData => {
      sum[eachData.id] = 0;
      return "";
    });

    out.budgets.map(i => {
      i.moneys.map(money => {
        if (money.financial_source_id) {
          sum[money.financial_source_id] += money.amount;
        }
        return "";
      });
      return "";
    });
    return sum;
  };

  getFinianName = id => {
    const { mainFinancials } = this.state;
    let retVal = "";
    if (mainFinancials.find(x => x.id === parseInt(id))) {
      retVal = mainFinancials.find(x => x.id === parseInt(id)).name;
    }
    return retVal;
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 20 },
        sm: { span: 20 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };

    const { formData } = this.state;

    const yearSpanLength = formData[0]
      ? formData[0].end_year - formData[0].start_year + 1
      : 0;

    const yearSpan = formData[0]
      ? formData[0].start_year + "-" + formData[0].end_year
      : 0;

    return (
      <Fragment>
        <Row>
          <Col className="text-right" span={24}>
            <IntlMessages id="annexLeftTitle" values={{ annexNumber: `8` }} />
          </Col>
        </Row>
        <div className={styles.title}>
          {yearSpan} ОНЫ ҮЙЛ АЖИЛЛАГААНЫ ХӨТӨЛБӨРИЙГ ХЭРЭГЖҮҮЛЭХ АРГА ХЭМЖЭЭНИЙ
          ТӨЛӨВЛӨГӨӨ
          {/* <IntlMessages id="annex8.a_title" /> */}
        </div>

        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <table className={styles.tg}>
            <thead>
              <tr>
                <th rowSpan="3" className={styles.tg9wq8}>
                  <IntlMessages id="annex8.goal_reflected" />
                </th>
                <th rowSpan="3" className={styles.tg9wq8}>
                  #
                </th>
                <th
                  style={{ minWidth: 150 }}
                  rowSpan="3"
                  className={styles.tg9wq8}
                >
                  <IntlMessages id="annex8.measures_implement" />
                </th>
                <th rowSpan="3" className={styles.tg9wq8}>
                  <IntlMessages id="annex8.implementation_period" />
                </th>
                <th rowSpan="3" className={styles.tg9wq8}>
                  <IntlMessages id="annex8.funds_finacial_source" />
                </th>
                <th className={styles.tg9wq8} colSpan={2 * yearSpanLength}>
                  <IntlMessages id="annex8.level_outcomes_financials" />
                </th>
                <th className={styles.tg9wq8} rowSpan="2" colSpan="4">
                  <IntlMessages id="annex8.implemention_organization" />
                </th>
                <th className={styles.tg9wq8} rowSpan="3">
                  {" "}
                </th>
              </tr>
              <tr>{this.yearRendarCalc()}</tr>
              <tr>
                {this.yearRendarCalc2()}
                <th className={styles.tg9wq8}>
                  <IntlMessages id="annex8.key" />
                </th>
                <th className={styles.tg9wq8}>
                  <IntlMessages id="annex8.partner" />
                </th>
                <th className={styles.tg9wq8}>
                  <IntlMessages id="annex8.participant" />
                </th>
              </tr>
            </thead>
            <tbody>
              {formData.map((obj, objIndex) => {
                return (
                  <Fragment key={"obj_" + objIndex}>
                    <tr>
                      <td
                        className={`${styles.tgNrix} ${styles.grey_background}`}
                        colSpan={2 * yearSpanLength + 10}
                      >
                        <ShowMoreText
                          lines={3}
                          more="Дэлгэрэнгүй"
                          less="Хураах"
                          anchorClass=""
                          expanded={false}
                          className="text-left"
                        >
                          {obj.direction_name}
                        </ShowMoreText>
                      </td>
                    </tr>
                    {obj.activity.outputs.map((out, outIndex) => {
                      return (
                        <Fragment key={"out_" + outIndex}>
                          <tr>
                            {outIndex === 0 ? (
                              <td
                                style={{ minWidth: 200 }}
                                rowSpan={(obj.activity.outputs.length > 1
                                  ? obj.activity.outputs.length
                                  : "1"
                                ).toString()}
                                className={`${styles.tgNrix} text-justify`}
                              >
                                <ShowMoreText
                                  lines={3}
                                  more="Дэлгэрэнгүй"
                                  less="Хураах"
                                  anchorClass=""
                                  expanded={false}
                                  className="text-left"
                                >
                                  {obj.objective_name}
                                </ShowMoreText>
                              </td>
                            ) : null}
                            <td className={styles.tgNrix}>{outIndex + 1}</td>
                            <td
                              style={{ minWidth: "300px" }}
                              className={styles.tgNrix}
                            >
                              <Form.Item style={{ marginBottom: 0 }}>
                                {out.output}
                              </Form.Item>
                            </td>
                            <td className={styles.tgNrix}>
                              <Form.Item style={{ marginBottom: 0 }}>
                                {out.start_year}
                              </Form.Item>
                              <Form.Item style={{ marginBottom: 0 }}>
                                {out.end_year}
                              </Form.Item>
                            </td>
                            <td
                              className={styles.tgNrix}
                              style={{ minWidth: 200, textAlign: "left" }}
                            >
                              <Row gutter={10}>
                                <Col span={24}>
                                  <ul>
                                    {Object.keys(
                                      this.calcOutAllFinancial(out)
                                    ).map(key => {
                                      return this.calcOutAllFinancial(out)[
                                        key
                                      ] ? (
                                        <li>{`${this.getFinianName(key)} - ${
                                          this.calcOutAllFinancial(out)[key]
                                        }`}</li>
                                      ) : null;
                                    })}
                                  </ul>
                                </Col>
                              </Row>
                            </td>
                            {this.yearRendarCalc3(
                              getFieldDecorator,
                              objIndex,
                              obj,
                              outIndex,
                              out
                            )}
                            <td
                              style={{ minWidth: "350px" }}
                              className={styles.tgNrix}
                            >
                              <Fragment>
                                <ul className="text-justify">
                                  {out.organizations_1.map(
                                    (eachOrganizations, indexOrgan) => {
                                      return (
                                        <Row
                                          style={{ width: 350 }}
                                          key={indexOrgan}
                                          gutter={0}
                                        >
                                          <Col span={20}>
                                            <li>{eachOrganizations.name} </li>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  )}
                                </ul>
                              </Fragment>
                            </td>
                            <td
                              style={{ minWidth: "350px" }}
                              className={styles.tgNrix}
                            >
                              <Fragment>
                                <ul className="text-justify">
                                  {out.organizations_2.map(
                                    (eachOrganizations, indexOrgan) => {
                                      return (
                                        <Row
                                          style={{ width: 350 }}
                                          key={indexOrgan}
                                          gutter={0}
                                        >
                                          <Col span={20}>
                                            <li>{eachOrganizations.name} </li>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  )}
                                </ul>
                              </Fragment>
                            </td>
                            <td
                              style={{ minWidth: "350px" }}
                              className={styles.tgNrix}
                            >
                              <Fragment>
                                <ul className="text-justify">
                                  {out.organizations_3.map(
                                    (eachOrganizations, indexOrgan) => {
                                      return (
                                        <Row
                                          style={{ width: 350 }}
                                          key={indexOrgan}
                                          gutter={0}
                                        >
                                          <Col span={20}>
                                            <li>{eachOrganizations.name} </li>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  )}
                                </ul>
                              </Fragment>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </Form>
      </Fragment>
    );
  }
}

export default Form.create()(AnnexEight);
