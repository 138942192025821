import React, { Component, Fragment } from "react";
import { Row, Form, Col, message, Spin } from "antd";

import { policyViewAnnexFourPublicWeb } from "../../../../restAPI/main";

import IntlMessages from "util/IntlMessages";
import styles from "./style.module.scss";

class GeneralInformation extends Component {
  state = {
    policy: this.props.policy,
    MainResearch: [],
    MainResearchLoading: true,

    dataRequest: {
      policy_id: this.props.policy.id,
      datas: [],
      annex4_conclusion: "",
      annex4_conclusion_en: "",
      policy_researches: [
        {
          id: 0,
          policy_id: 0,
          research_id: 0,
          is_related_annex4: 0
        }
      ]
    },
    saveLoad: false,
    loading: true
  };

  componentWillMount() {
    this.fetchGet();
  }

  fetchGet = () => {
    const { policy } = this.props;
    this.setState({
      loading: true
    });
    policyViewAnnexFourPublicWeb(
      policy.id,
      "",
      this.ongetSuccess,
      this.onFailed
    );
  };

  ongetSuccess = response => {
    const { policy } = this.props;
    response.policy_id = policy.id;
    if (response.policy_researches.length === 0) {
      response.policy_researches = [
        {
          id: 0,
          policy_id: 0,
          research_id: 0,
          is_related_annex4: 0
        }
      ];
    }

    if (response.datas.length === 0) {
      response.datas = [
        {
          name: "",
          children: [
            {
              key: "",
              value: ""
            }
          ]
        }
      ];
    }

    this.setState({
      dataRequest: response,
      loading: false
    });
  };

  onSuccessResearch = response => {
    this.setState({
      MainResearch: response,
      MainResearchLoading: false
    });
  };

  onFailed = err => {
    message.error(`${err}`);
    this.setState({
      saveLoad: false
    });
  };

  onhandleResearch = (event, index) => {
    const { dataRequest } = this.state;
    dataRequest.policy_researches[index].research_id = event;
    this.setState({
      dataRequest
    });
  };

  addResearch = () => {
    const { dataRequest } = this.state;
    const temp = {
      id: 0,
      policy_id: this.props.policy.id,
      research_id: 0,
      is_related_annex4: 0
    };
    dataRequest.policy_researches.push(temp);
    this.setState({
      dataRequest
    });
  };

  removeResearch = index => {
    const { dataRequest } = this.state;
    dataRequest.policy_researches.splice(index, 1);
    dataRequest.policy_researches.map((i, subIndex) => {
      this.props.form.setFieldsValue({
        [`research${subIndex}`]: i.research_id === 0 ? undefined : i.research_id
      });
      return "";
    });

    this.setState({
      dataRequest
    });
  };

  onChangeMain = (event, stateName) => {
    const { dataRequest } = this.state;
    dataRequest[stateName] = event.target.value;
    this.setState({
      dataRequest
    });
  };

  addParent = () => {
    const { dataRequest } = this.state;
    const tmp = {
      name: "",
      children: [
        {
          key: "",
          value: ""
        }
      ]
    };
    dataRequest.datas.push(tmp);
    this.setState({
      dataRequest
    });
  };

  addChildren = mainIndex => {
    const { dataRequest } = this.state;
    const tmp = {
      key: "",
      value: ""
    };
    dataRequest.datas[mainIndex].children.push(tmp);
    this.setState({
      dataRequest
    });
  };

  removeParent = mainIndex => {
    const { dataRequest } = this.state;
    dataRequest.datas.splice(mainIndex, 1);
    dataRequest.datas.map((parent, mainIndex) => {
      this.props.form.setFieldsValue({
        [`name${mainIndex}`]: parent.name || undefined
      });

      parent.children.map((i, index) => {
        this.props.form.setFieldsValue({
          [`key${mainIndex}${index}`]: i.key || undefined
        });
        this.props.form.setFieldsValue({
          [`value${mainIndex}${index}`]: i.value || undefined
        });
        return "";
      });

      return "";
    });

    this.setState({
      dataRequest
    });
  };

  removeChildren = (mainIndex, index) => {
    const { dataRequest } = this.state;
    if (dataRequest.datas[mainIndex].children.length === 1) {
      this.removeParent(mainIndex);
    } else {
      dataRequest.datas[mainIndex].children.splice(index, 1);
      dataRequest.datas[mainIndex].children.map((i, index) => {
        this.props.form.setFieldsValue({
          [`key${mainIndex}${index}`]: i.key || undefined
        });
        this.props.form.setFieldsValue({
          [`value${mainIndex}${index}`]: i.value || undefined
        });
        return "";
      });
    }

    this.setState({
      dataRequest
    });
  };

  onChangeChildren = (event, mainIndex, index, stateName) => {
    const { dataRequest } = this.state;
    dataRequest.datas[mainIndex].children[index][stateName] =
      event.target.value;
    this.setState({
      dataRequest
    });
  };

  onChangeParent = (event, mainIndex) => {
    const { dataRequest } = this.state;
    dataRequest.datas[mainIndex].name = event.target.value;
    this.setState({
      dataRequest
    });
  };

  render() {
    const { dataRequest, loading } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };
    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Row>
          <Col className="text-right" span={24}>
            <IntlMessages id="annexLeftTitle" values={{ annexNumber: `4` }} />
          </Col>
        </Row>
        <div className={styles.title}>
          <IntlMessages id="annex4.title" />
        </div>

        {loading ? (
          <Spin />
        ) : (
          <table className="table table-bordered" id="table-to-xls">
            <tbody>
              {dataRequest.datas.map((each, mainIndex) => {
                return (
                  <Fragment key={mainIndex}>
                    <tr style={{ backgroundColor: "#eeeeee" }}>
                      <th
                        colSpan={2}
                        className={styles.title}
                        style={{
                          padding: "15px 91px 15px 91px"
                        }}
                      >
                        <Row gutter={10}>
                          <Col span={24}>{`${each.name}`}</Col>
                        </Row>
                      </th>
                    </tr>
                    {each.children.map((i, index) => {
                      return (
                        <tr key={index}>
                          <th
                            className={`${styles.policyCol} text-justify`}
                            scope="row"
                          >
                            {i.key}
                          </th>
                          <td>
                            <Row gutter={10}>
                              <Col span={24}>
                                <span style={{ whiteSpace: "pre-line" }}>
                                  {`${i.value}` || null}
                                </span>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      );
                    })}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        )}

        <Row gutter={16} className="mb-4 mt-2">
          <Col span={24}>
            <span className="custom-title">
              <IntlMessages id="annex4.conclusion" />
            </span>
            <br />
            {dataRequest.annex4_conclusion}
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <span className="custom-title">
              <IntlMessages id="sidebar.research" />
            </span>
            <br />

            <ul>
              {dataRequest.policy_researches.map((eachResearch, index) => {
                return <li>{eachResearch.research_name}</li>;
              })}
            </ul>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create()(GeneralInformation);
