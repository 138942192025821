import React from "react";

import asyncComponent from "util/asyncComponent";
import { Route, Switch, Redirect } from "react-router-dom";
import { checkWindow } from "util/helper";

const Research = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/research-list`} />
    <Route
      path={`${match.url}/research-list`}
      component={asyncComponent(() => import("./ResearchList"))}
    />{" "}
    {checkWindow("/research/new-research") ? (
      <Route
        exact
        path={`${match.url}/new-research`}
        component={asyncComponent(() => import("./NewResearch"))}
      />
    ) : null}
    {checkWindow("/research/edit") ? (
      <Route
        exact
        path={`${match.url}/edit/:id`}
        component={asyncComponent(() => import("./NewResearch"))}
      />
    ) : null}
    {checkWindow("/research/my-jobrequests") ? (
      <Route
        exact
        path={`${match.url}/my-jobrequests`}
        component={asyncComponent(() => import("./myJobRequest"))}
      />
    ) : null}
    {checkWindow("/research/new-job") ? (
      <Route
        path={`${match.url}/new-job`}
        component={asyncComponent(() => import("./NewJob"))}
      />
    ) : null}
    {checkWindow("/research/edit/job") ? (
      <Route
        exact
        path={`${match.url}/edit/job/:id`}
        component={asyncComponent(() => import("./NewJob"))}
      />
    ) : null}
    {checkWindow("/research/job-list") ? (
      <Route
        path={`${match.url}/job-list`}
        component={asyncComponent(() => import("./JobList"))}
      />
    ) : null}
    {checkWindow("/research/profile/{id}") ? (
      <Route
        exact
        path={`${match.url}/profile/:id`}
        component={asyncComponent(() => import("./MemberProfile"))}
      />
    ) : null}
    {checkWindow("/research/choosemember/{id}") ? (
      <Route
        exact
        path={`${match.url}/choosemember/:id`}
        component={asyncComponent(() => import("./ChooseMember"))}
      />
    ) : null}
    {checkWindow("/research/researcher-list") ? (
      <Route
        exact
        path={`${match.url}/researcher-list`}
        component={asyncComponent(() => import("./researcher"))}
      />
    ) : null}
    <Route
      exact
      path={`${match.url}/request/job/:id`}
      component={asyncComponent(() => import("./RequestJob"))}
    />
    <Route
      exact
      path={`${match.url}/detail/:id`}
      component={asyncComponent(() => import("./ResearchDetail"))}
    />
    <Route
      exact
      path={`${match.url}/jobDetail/:id`}
      component={asyncComponent(() => import("./JobDetail"))}
    />
    <Route
      exact
      path={`${match.url}/research-proposal/:id`}
      component={asyncComponent(() => import("./ResearchProposal"))}
    />
  </Switch>
);

export default Research;
