exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_title__n0XJ1 {\n  text-align: center;\n  margin: 20px 0px;\n  font-size: 17px;\n  font-weight: 500; }\n\n.style_marginTop__3qMpY {\n  margin-top: 48px; }\n", ""]);

// exports
exports.locals = {
	"title": "style_title__n0XJ1",
	"marginTop": "style_marginTop__3qMpY"
};