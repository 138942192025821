import React, { Component, Fragment } from "react";
import { Row, Form, Col, Spin, message } from "antd";
import {
  policyViewGetArticlePublicWeb,
  policyViewGetArticleListPublicWeb
} from "../../../../restAPI/main";

class GeneralInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policyLangBoth: props.policy.language === 0 ? false : true,
      mainArticle: {},
      loading: true,
      articles: []
    };
  }

  componentWillMount() {
    const { policy } = this.props;
    policyViewGetArticlePublicWeb(
      policy.id,
      "",
      this.onSuccessMain,
      this.onFailed
    );

    policyViewGetArticleListPublicWeb(
      policy.id,
      "",
      this.onSuccessList,
      this.onFailed
    );
  }

  onSuccessMain = response => {
    this.setState({
      mainArticle: response
    });
  };

  onSuccessList = response => {
    this.setState({
      loading: false,
      articles: response
    });
  };

  onFailed = err => {
    message.error(err);
    this.setState({
      loading: false
    });
  };

  checkChildren = datas => {
    return datas.map((article, index) => {
      return (
        <Fragment key={index}>
          <Row gutter={16}>
            <Col
              span={20}
              style={{ marginLeft: `${article.key.toString().length}9px` }}
            >
              <Row>
                <Col className="mt-2" span={1}>
                  <b>{article.key}</b>
                </Col>
                <Col span={20}>
                  <span style={{ whiteSpace: "pre-line" }}>
                    {article.article}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          {article.Children.length !== 0
            ? this.checkChildren(article.Children)
            : null}
        </Fragment>
      );
    });
  };

  //#endregion

  render() {
    const { policyLangBoth, loading, mainArticle, articles } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        {loading ? (
          <Spin />
        ) : (
          <Fragment>
            <Row className="mt-3" gutter={16}>
              <Col className="gutter-row" span={policyLangBoth ? 12 : 24}>
                <span style={{ whiteSpace: "pre-line" }}>{mainArticle.article_general}</span>
              </Col>
            </Row>
            {this.checkChildren(articles)}
          </Fragment>
        )}
      </Form>
    );
  }
}

export default Form.create()(GeneralInformation);
