import React from "react";
import { Button, message, Form, Input, Alert } from "antd";
import { authForgetEmail } from "../restAPI/main";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";

const FormItem = Form.Item;

class ForgetPassword extends React.Component {
  state = {
    isSendEmail: false,
    loadingBtn: false
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        authForgetEmail(values.email, "", this.onSuccess, this.onFailed);
        this.setState({
          loadingBtn: true
        });
      }
    });
  };

  onSuccess = () => {
    this.setState({
      isSendEmail: true,
      loadingBtn: false
    });
  };

  onFailed = err => {
    message.error(err);
    this.setState({
      loadingBtn: false
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isSendEmail, loadingBtn } = this.state;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container" style={{ maxWidth: 400 }}>
          <div className="gx-app-login-main-content">
            <div className="gx-app-login-content" style={{ width: "100%" }}>
              <div className="text-center">
                <img alt="" src={require("assets/images/logo-thumb.png")} />
              </div>

              <Form
                onSubmit={this.handleSubmit}
                className="mt-5 gx-signin-form gx-form-row0"
              >
                {isSendEmail ? (
                  <FormItem>
                    <Alert
                      className
                      message="Нууц үг солих зааврыг таны цахим шуудангийн хаяг руу илгээлээ."
                      type="success"
                    />
                  </FormItem>
                ) : (
                  ""
                )}
                <FormItem>
                  {getFieldDecorator("email", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        type: "email",
                        message: "Цахим хаягаа оруулна уу"
                      }
                    ]
                  })(<Input placeholder="Цахим хаяг" />)}
                </FormItem>

                <FormItem>
                  <Link to="/signin">
                    <Button
                      style={{ padding: 0 }}
                      type="link"
                      icon="backward"
                      className="gx-mb-0"
                    >
                      <IntlMessages id="app.userAuth.signIn" />
                    </Button>
                  </Link>

                  <Button
                    style={{ float: "right" }}
                    loading={loadingBtn}
                    type="primary"
                    className="text-right gx-mb-0 primaryColor"
                    htmlType="submit"
                  >
                    <IntlMessages id="app.userAuth.send" />
                  </Button>
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create()(ForgetPassword);
