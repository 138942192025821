import {
  HIDE_MESSAGE,
  INIT_URL,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  CHANGE_APIS,
  CHANGE_WINDOWS
} from "constants/ActionTypes";

import JWTdecode from "jwt-decode";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  initURL: "",
  authUser: localStorage.getItem("user_token"),
  autUserData : localStorage.getItem("user_token") ? JWTdecode(localStorage.getItem("user_token")) : null,
  apis : [],
  windows : []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_APIS: {
      return {
        ...state,
        apis: action.payload
      };
    }
    case CHANGE_WINDOWS: {
      return {
        ...state,
        windows: action.payload
      };
    }

    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: "/home",
        loader: false
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false
      };
    }
   
    default:
      return state;
  }
};
