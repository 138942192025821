import React, { Component, Fragment } from "react";
import { Spin, Form, message } from "antd";

import { policyViewAnnexSevenPublicWeb } from "../../../../restAPI/main";
import Bwindow from "./b";

class AnnexSeven extends Component {
  state = {
    loading: true,
    formData: {}
  };

  componentWillMount() {
    const { policy } = this.props;
    policyViewAnnexSevenPublicWeb(
      policy.id,
      "",
      this.onSuccess,
      this.onFailed,
      "POST"
    );
  }

  onSuccess = response => {
    this.setState({
      formData: response,
      loading: false
    });
  };

  onFailed = err => {
    message.error(err);
    this.setState({
      loading: false
    });
  };

  render() {
    const { loading, formData } = this.state;
    const { onChangeTabKey, prev_rank, policy } = this.props;
    return (
      <Fragment>
        {loading ? (
          <Spin />
        ) : (
          <div>
            <Bwindow
              formData={formData}
              policy={policy}
              onChangeTabKey={onChangeTabKey}
              prev_rank={prev_rank}
              history={this.props.history}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

export default Form.create()(AnnexSeven);
