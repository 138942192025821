import React from "react";

import Widget from "components/Widget/index";

const EcommerceStatus = ({
  icon,
  title,
  subTitle,
  eachType,
  colorTitle,
  colorSubTitle,
  height,
  onSelectType,
  selecetTypeID,
  policy_title
}) => {
  return (
    <Widget
      styleName={`policyTypeHeight gx-card-full gx-py-4 gx-px-2 ${
        selecetTypeID === eachType.policy_type_id ? `policyTypeBorder` : null
      }`}
    >
      <div
        style={{ height: height ? height : 135 }}
        className="gx-flex-row gx-justify-content-center"
      >
        <span
          style={{ width: "100%" }}
          className="mb-3 gx-flex-row gx-justify-content-center gx-align-items-center"
        >
          <b>{policy_title}</b>
        </span>
        <br />
        <span
          style={{
            cursor: "pointer",
            borderColor: "#038fde",
            color: "#038fde"
          }}
          onClick={() => onSelectType(eachType)}
          className={`gx-size-80 gx-border gx-border-${colorTitle} gx-text-${colorTitle} gx-flex-row gx-justify-content-center gx-align-items-center gx-rounded-circle`}
        >
          <i className={`${icon} fa-3x`} />
        </span>
      </div>
      <div className="gx-text-center">
        <h2
          style={{
            color: "#038fde"
          }}
          className={`gx-fs-xxxl gx-font-weight-medium gx-text-${colorTitle}`}
        >
          {title}
        </h2>
        <p className={`gx-mb-0 gx-mb-sm-3 gx-text-${colorSubTitle}`}>
          {eachType.policy_type_id !== 0 ? subTitle : null}
        </p>
      </div>
    </Widget>
  );
};

export default EcommerceStatus;
