import React from "react";
import NotificationItem from "./NotificationItem";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";
import IntlMessages from "util/IntlMessages";

const AppNotification = ({ notifications, fetchNotifList }) => {
  return (
    <Auxiliary>
      <div className="gx-popover-header">
        <h3 className="gx-mb-0"><IntlMessages id="notification" /> </h3>
      </div>
      <CustomScrollbars className="gx-popover-scroll">
        <ul className="gx-sub-popover">
          {notifications.map((notification, index) => (
            <NotificationItem
              key={index}
              fetchNotifList={fetchNotifList}
              notification={notification}
            />
          ))}
        </ul>
      </CustomScrollbars>
    </Auxiliary>
  );
};

export default AppNotification;
