import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import Footer from "components/Footer/index.js";
import JWTdecode from "jwt-decode";
import { isMobile } from "react-device-detect";
import {
  listpollingpoliciesPublic,
  listjobsPublic,
  searchPublic,
  getgeneralinfo
} from "../../restAPI/main";
import { Input, Button, AutoComplete, message, Popover } from "antd";

import UserInfo from "components/UserInfo";
import ReactSVG from "react-svg";

const { Option, OptGroup } = AutoComplete;

function content(record) {
  return (
    <div>
      <a
        href={`/tentativeproposal/createPolicy/${record.object_id}?public=true`}
        style={{ paddingRight: "5px" }}
      >
        <Button style={{ backgroundColor: "#8EC441", color: "white" }}>
          <IntlMessages id="tentativeproposal.newTentativePolicy" />
        </Button>
      </a>
      <a
        href={`/tentativeproposal/createInvesment/${record.object_id}?public=true`}
        style={{ paddingRight: "5px" }}
      >
        <Button style={{ backgroundColor: "#04D0D0", color: "white" }}>
          <IntlMessages id="tentativeproposal.newTentativeInvestment" />
        </Button>
      </a>
    </div>
  );
}

function renderTitle(title, item) {
  return (
    <span>
      {title}
      <span style={{ float: "right" }}>Нийт: {item.children.length}</span>
    </span>
  );
}

function renderOption(item) {
  return (
    <OptGroup key={item.title} label={renderTitle(item.title, item)}>
      {item.children.map(opt => (
        <Option
          key={opt.policy_name || opt.name}
          value={opt.policy_name || opt.name}
        >
          <a
            without
            rel="noopener noreferrer"
            target="_blank"
            href={
              opt.policy_name
                ? `/detail?policy=${opt.id}`
                : `/research?research=${opt.id}`
            }
          >
            {opt.policy_name || opt.name}
          </a>
        </Option>
      ))}
    </OptGroup>
  );
}

class Home extends React.Component {
  state = {
    policiesAndReseach: {
      policies: [],
      researches: [],
      policies_tp: []
    },
    policiesAndReseachLoading: true,

    researchJobs: [],
    researchJobLoading: true,
    jobPages: [],
    splicedJobs: [],
    jobCurrentPage: 1,

    searchKey: "",
    mergeData: [],
    generalInfo: {},

    researchPages: [],
    researchCurrentPage: 1,
    splicedResearch: [],

    policyCurrentPage: 1,
    policiesPages: [],
    splicedPolicies: [],

    tpCurrentPage: 1,
    tpPages: [],
    splicedTp: []
  };

  componentWillMount() {
    listpollingpoliciesPublic("", this.onSuccesList, this.onFailed);
    listjobsPublic("", this.onSuccessJob, this.onFailed);
    getgeneralinfo("", this.onSuccessGeneral, this.onFailed);
  }

  onFailed = err => {
    message.error(err);
  };

  onSuccessGeneral = response => {
    this.setState({
      generalInfo: response
    });
  };

  onSuccesList = response => {
    const researchPages = [];
    const researchpageTotal = (response.researches.length / 10).toFixed(0);
    for (var i = 1; i < parseInt(researchpageTotal) + 1; i++) {
      researchPages.push(i);
    }
    const tmp = [...response.researches];
    const splicedResearch = tmp.splice(0, 10);

    const policiesPages = [];
    const policiespageTotal = (response.policies.length / 10).toFixed(0);
    for (var k = 1; k < parseInt(policiespageTotal) + 1; k++) {
      policiesPages.push(k);
    }
    const tmpPolicy = [...response.policies];
    const splicedPolicies = tmpPolicy.splice(0, 10);

    const tpPages = [];
    const tppageTotal = (response.policies_tp.length / 10).toFixed(0);
    for (var j = 1; j < parseInt(tppageTotal) + 1; j++) {
      tpPages.push(j);
    }
    const tmpTp = [...response.policies_tp];
    const splicedTp = tmpTp.splice(0, 10);

    this.setState({
      policiesAndReseach: response,
      policiesAndReseachLoading: true,
      researchPages,
      policiesPages,
      tpPages,
      splicedResearch,
      splicedPolicies,
      splicedTp
    });
  };

  onSuccessJob = response => {
    const jobPages = [];
    const jobPageTotal = (response.length / 10).toFixed(0);

    for (var i = 1; i < parseInt(jobPageTotal) + 1; i++) {
      jobPages.push(i);
    }

    const tmp = [...response];
    const splicedJobs = tmp.splice(0, 10);

    this.setState({
      researchJobs: response,
      researchJobLoading: false,
      jobPages,
      splicedJobs
    });
  };

  onSearch = () => {
    const { searchKey } = this.state;
    const request = {
      key: searchKey
    };
    searchPublic(request, this.onSuccessSearch, this.onFailed);
  };

  onSuccessSearch = response => {
    const mergeData = [];
    let tmpPolicy = {
      title: "Бодлого",
      children: response.policies
    };
    let tmpResearch = {
      title: "Судалгаа",
      children: response.researches
    };
    mergeData.push(tmpPolicy);
    mergeData.push(tmpResearch);

    this.setState({
      mergeData
    });
  };

  _handleKeyPress = e => {
    if (e.key === "Enter") {
      this.onSearch();
    }
  };

  changeResearchPage = page_number => {
    const { policiesAndReseach } = this.state;
    const tmo = [...policiesAndReseach.researches];

    let from = 0;
    let to = 10;

    if (page_number !== 1) {
      from = 10 * (page_number - 1);
    }

    this.setState({
      splicedResearch: tmo.splice(from, to),
      researchCurrentPage: page_number
    });
  };

  changePolicyPage = page_number => {
    const { policiesAndReseach } = this.state;
    const tmo = [...policiesAndReseach.policies];

    let from = 0;
    let to = 10;

    if (page_number !== 1) {
      from = 10 * (page_number - 1);
    }

    this.setState({
      splicedPolicies: tmo.splice(from, to),
      policyCurrentPage: page_number
    });
  };

  tpCurrentPage = page_number => {
    const { policiesAndReseach } = this.state;
    const tmo = [...policiesAndReseach.policies_tp];

    let from = 0;
    let to = 10;

    if (page_number !== 1) {
      from = 10 * (page_number - 1);
    }

    this.setState({
      splicedTp: tmo.splice(from, to),
      tpCurrentPage: page_number
    });
  };

  changeJobPage = page_number => {
    const { researchJobs } = this.state;
    const tmo = [...researchJobs];

    let from = 0;
    let to = 10;

    if (page_number !== 1) {
      from = 10 * (page_number - 1);
    }

    this.setState({
      splicedJobs: tmo.splice(from, to),
      jobCurrentPage: page_number
    });
  };

  render() {
    const {
      splicedJobs,
      jobCurrentPage,
      jobPages,

      mergeData,
      generalInfo,

      researchPages,
      researchCurrentPage,
      splicedResearch,

      policiesPages,
      policyCurrentPage,
      splicedPolicies,

      tpPages,
      tpCurrentPage,
      splicedTp
    } = this.state;
    const { authUser } = this.props;
    let userForiengn = false;
    if (authUser) {
      let userJSONdata = JWTdecode(authUser);
      userForiengn = userJSONdata.is_foreign === "False" ? false : true;
    }

    return (
      <div
        className={`uk-clearfix tw-pt-50 tw-pt-30@m tw-pb-50 tw-pb-30@m tw-home-page`}
      >
        <div className="tw-header uk-container uk-container-large tw-mb-50 tw-mb-100@m">
          <header className="tw-header">
            <nav className="uk-navbar-container  uk-navbar">
              <div className="uk-navbar-left">
                <Link className="uk-logo uk-flex uk-flex-center" to="/home">
                  <img alt="" src="assets/images/logo.png" />
                </Link>
              </div>

              <div className="uk-navbar-right">
                <ul className="uk-navbar-nav uk-visible@l tw-main-menu uk-height-1-1">
                  <li className="uk-open">
                    <Link to="/home">Нүүр</Link>
                  </li>

                  <li>
                    <a href="/system">Системийн Танилцуулга</a>
                    <div className="uk-navbar-dropdown tw-button-primary uk-light">
                      <ul className="uk-nav uk-navbar-dropdown-nav">
                        <li className="uk-active">
                          <a href="#">Гарын авлага</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a href="/policy">Бодлого</a>
                  </li>
                  {authUser ? (
                    <li>
                      <a href="/dashboard">Хянах самбар</a>
                    </li>
                  ) : null}
                  <li>
                    <a href="/gis">Орон зайн мэдээлэл</a>
                  </li>
                  {isMobile ? null : (
                    <li>
                      <div id="google_translate_element"></div>
                    </li>
                  )}
                </ul>
                <ul className="uk-navbar-nav uk-flex uk-flex-middle tw-ml-60 uk-height-1-1 uk-visible@s">
                  {authUser ? (
                    <li className="uk-margin-remove tw-mr-15">
                      <UserInfo />
                    </li>
                  ) : (
                    <Fragment>
                      <li className="uk-margin-remove tw-mr-15">
                        <Link
                          className="uk-button tw-button-primary"
                          to="/signin"
                        >
                          Нэвтрэх
                        </Link>
                      </li>
                      <li className="uk-margin-remove tw-mr-20">
                        <Link
                          className="uk-button tw-button-warning"
                          style={{
                            background: "#F4C30E",
                            color: "#ffffff"
                          }}
                          to="/signup"
                        >
                          Бүртгүүлэх
                        </Link>
                      </li>
                    </Fragment>
                  )}
                </ul>
                <a
                  className="mobile-menu uk-navbar-toggle uk-hidden@l tw-mr-20"
                  href="#"
                  data-uk-toggle="target: #mobile-menu-modal"
                >
                  <span uk-icon="menu"></span>
                </a>
              </div>
            </nav>
          </header>
        </div>
        <div id="mobile-menu-modal" className="uk-modal-full" data-uk-modal>
          <div className="uk-modal-dialog">
            <button
              className="uk-modal-close-full"
              type="button"
              data-uk-close
            ></button>
            <div
              className="uk-height-viewport tw-mobile-modal uk-flex uk-flex-middle uk-flex-center"
              data-uk-scrollspy="target:>ul>li,>div>a; cls:uk-animation-slide-bottom-medium; delay: 150;"
            >
              <ul className="uk-nav-default uk-nav-parent-icon" data-uk-nav>
                <li>
                  <a href="index.html">Нүүр</a>
                </li>
                <li>
                  <a href="/system">Системийн Танилцуулга</a>
                </li>
                <li>
                  <a href="/policy">Бодлого</a>
                </li>
                {authUser ? (
                  <li>
                    <a href="/dashboard">Хянах самбар</a>
                  </li>
                ) : null}
                <li>
                  <a href="/gis"> Орон зайн мэдээлэл</a>
                </li>

                <li className="uk-hidden@m">
                  <Link to="/signin">Нэвтрэх</Link>
                </li>
                <li className="uk-hidden@m">
                  <Link to="/signup">Бүртгүүлэх</Link>
                </li>
              </ul>

              <div className="tw-socials tw-socials-minimal">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/ndagovMN/"
                >
                  <i className="ion-social-facebook"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/ndagovMN"
                >
                  <i className="ion-social-twitter"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCwa3Yh0SrzZlUR9IzkJHo5Q"
                >
                  <i className="ion-social-pinterest"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <section className="uk-section tw-card-2">
          <div
            className="uk-container uk-container-large"
            data-uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-small; delay: 300;"
          >
            <div className="uk-flex-center uk-grid-medium" data-uk-grid>
              <div className="uk-width-4-5 uk-width-2-3@m uk-width-1-2@l">
                <div className="tw-element tw-mr-30-rev tw-ml-30-rev">
                  <div className="uk-card uk-card-default uk-card-large uk-card-body">
                    <h3 className="uk-card-title uk-text-center uk-margin-remove">
                      ХӨГЖЛИЙН БОДЛОГО ТӨЛӨВЛӨЛТИЙН МЭДЭЭЛЛИЙН НЭГДСЭН СИСТЕМ
                    </h3>
                    <div
                      className="uk-child-width-1-1 uk-child-width-1-3@s uk-grid-collapse tw-mt-50 tw-mb-50"
                      data-uk-grid
                    >
                      <div className="uk-text-center">
                        <dl className="uk-description-list uk-flex uk-flex-center">
                          <dt>14</dt>
                          <dd>Яам</dd>
                        </dl>
                      </div>
                      <div className="uk-text-center">
                        <dl className="uk-description-list uk-flex uk-flex-center">
                          <dt>22</dt>
                          <dd>Нийслэл/Аймаг</dd>
                        </dl>
                      </div>
                      <div className="uk-text-center">
                        <dl className="uk-description-list uk-flex uk-flex-center">
                          <dt>339</dt>
                          <dd>Сум/Дүүрэг</dd>
                        </dl>
                      </div>
                    </div>
                    <div className="uk-margin uk-text-center">
                      <div className="uk-inline uk-width-6-7">
                        <AutoComplete
                          className="global-search publicWeb"
                          style={{
                            width: "100%",
                            height: "64px",
                            fontSize: "14px",
                            lineHeight: "18px"
                          }}
                          dataSource={mergeData.map(renderOption)}
                          onSelect={this.onSelect}
                          onSearch={this.handleSearch}
                          onChange={value => {
                            this.setState({
                              searchKey: value
                            });
                          }}
                          placeholder="Хайх түлхүүр үгээ оруулна уу …"
                          optionLabelProp="text"
                        >
                          <Input
                            onKeyUp={this._handleKeyPress}
                            className="publicWebInput"
                            suffix={
                              <Button
                                style={{
                                  height: 64,
                                  marginRight: -12,
                                  marginTop: 14
                                }}
                                className="uk-form-icon uk-form-icon-flip tw-button-primary"
                                onClick={() => this.onSearch()}
                              >
                                <img alt="" src="assets/svg/search.svg" />
                              </Button>
                            }
                          />
                        </AutoComplete>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="tw-box-1 uk-section uk-padding-remove tw-mb-100">
          <div className="uk-container uk-container-large">
            <div
              className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m"
              data-uk-grid
              data-uk-scrollspy="target: > div > div; cls:uk-animation-slide-bottom-medium; delay: 100;"
            >
              <div>
                <div className="tw-element tw-box">
                  <div
                    className="tw-front-box tw-shadow"
                    style={{ background: "#ffffff" }}
                  >
                    <Link
                      className="inner tw-box uk-flex uk-flex-center tw-pr-25 tw-pl-25"
                      to="/policy"
                    >
                      <div className="tw-box-image uk-flex uk-flex-middle uk-flex-center tw-mr-30">
                        <img
                          alt=""
                          src="assets/svg/box-information.svg"
                          style={{ width: "65px" }}
                        />
                      </div>
                      <div className="tw-box-content uk-flex uk-flex-center uk-flex-column uk-flex-top">
                        <h1 className="uk-margin-remove">
                          {`${generalInfo.policy_count || 0}` || 0}
                        </h1>
                        <p className="uk-margin-remove tw-text-large">
                          Хүчин төгөлдөр бодлогууд
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div>
                <div className="tw-element tw-box flip">
                  <div
                    className="tw-front-box tw-shadow"
                    style={{ background: "#ffffff" }}
                  >
                    <a
                      className="inner tw-box uk-flex uk-flex-center tw-pr-25 tw-pl-25"
                      href="#!"
                    >
                      <div className="tw-box-image uk-flex uk-flex-middle uk-flex-center tw-mr-30">
                        <img alt="" src="assets/svg/box-search.svg" />
                      </div>
                      <div className="tw-box-content uk-flex uk-flex-center uk-flex-column uk-flex-top">
                        <h1 className="uk-margin-remove">
                          {" "}
                          {`${generalInfo.research_count || 0}` || 0}
                        </h1>
                        <p className="uk-margin-remove tw-text-large">
                          Судалгааны Төслүүд
                        </p>
                      </div>
                    </a>
                  </div>
                  <div
                    className="tw-back-box tw-shadow"
                    style={{ background: "#fff" }}
                  >
                    <a
                      className="inner tw-box uk-flex uk-flex-center tw-pr-25 tw-pl-25"
                      href="#!"
                    >
                      <div className="tw-box-content uk-width-1-1 uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                        <h4 className="uk-margin-remove h4-theme">
                          Тун удахгүй...
                        </h4>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="tw-element tw-box">
                  <div
                    className="tw-front-box tw-shadow"
                    style={{ background: "#ffffff" }}
                  >
                    <a
                      className="inner tw-box uk-flex uk-flex-center tw-pr-25 tw-pl-25"
                      href="/gis"
                    >
                      <div className="tw-box-image uk-flex uk-flex-middle uk-flex-center tw-mr-30">
                        <img alt="" src="assets/svg/gis.svg" />
                      </div>
                      <div className="tw-box-content uk-flex uk-flex-center uk-flex-column uk-flex-top">
                        <img
                          alt=""
                          className="tw-mb-12"
                          src="assets/svg/location.svg"
                          data-uk-svg
                        />
                        <p className="uk-margin-remove tw-text-large">
                          Орон зайн мэдээлэл
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="tw-element tw-box flip">
                  <div
                    className="tw-front-box tw-shadow"
                    style={{ background: "#ffffff" }}
                  >
                    <a
                      className="inner tw-box uk-flex uk-flex-center tw-pr-25 tw-pl-25"
                      href="#!"
                    >
                      <div className="tw-box-image uk-flex uk-flex-middle uk-flex-center tw-mr-30">
                        <img alt="" src="assets/svg/box-scientist.svg" />
                      </div>
                      <div className="tw-box-content uk-flex uk-flex-center uk-flex-column uk-flex-top">
                        <h1 className="uk-margin-remove">
                          {`${generalInfo.researcher_count || 0}` || 0}
                        </h1>
                        <p className="uk-margin-remove tw-text-large">
                          Эрдэмтэн судлаачид
                        </p>
                      </div>
                    </a>
                  </div>
                  <div
                    className="tw-back-box tw-shadow"
                    style={{ background: "#fff" }}
                  >
                    <a
                      className="inner tw-box uk-flex uk-flex-center tw-pr-25 tw-pl-25"
                      href="#!"
                    >
                      <div className="tw-box-content uk-width-1-1 uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                        <h4 className="h4-theme uk-margin-remove">
                          Тун удахгүй...
                        </h4>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="tw-box-2 uk-section uk-padding-remove tw-mb-100">
          <div className="uk-container uk-container-large">
            <div
              className="uk-grid-medium uk-grid-match"
              data-uk-grid
              data-uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-medium; delay: 300;"
            >
              {!userForiengn ? (
                <div className="uk-width-1-1 uk-width-1-2@l tw-mb-30 tw-flex-first@l">
                  <div>
                    <h1 className="uk-heading-small uk-margin-remove">
                      Санал авч буй бодлогууд
                      {/* <div className="uk-heading-small-count">10</div> */}
                    </h1>
                  </div>
                </div>
              ) : null}

              {!userForiengn ? (
                <div className="uk-width-1-1 uk-width-1-2@l tw-mb-30 tw-flex-third@l">
                  <div>
                    <h1 className="uk-heading-small uk-margin-remove tw-blue-circle">
                      Урьдчилсан санал авч буй бодлогууд
                      {/* <div className="uk-heading-small-count">10</div> */}
                    </h1>
                  </div>
                </div>
              ) : null}

              {!userForiengn ? (
                <div className="uk-width-1-1 uk-width-1-2@l uk-margin-remove tw-flex-second@l">
                  <div className="uk-background-default tw-shadow uk-overflow-auto">
                    <table className="uk-table tw-table uk-table-middle uk-table-hover">
                      <thead className="tw-button-primary uk-light">
                        <tr>
                          <th className="uk-table-expand">Бодлогын нэр</th>
                          <th className="tw-width-small" colSpan="2">
                            Санал авах хугацаа
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {splicedPolicies.map((eachPolicy, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Link
                                  to={`/detail?policy=${eachPolicy.policy_id}`}
                                >
                                  {eachPolicy.policy_name}
                                </Link>
                              </td>
                              <td>
                                <ul className="uk-list tw-yellow-circle">
                                  <li style={{ padding: 0 }} className="active">
                                    <a href="#!">
                                      {eachPolicy.startdate} -{" "}
                                      {eachPolicy.enddate}
                                    </a>
                                  </li>
                                </ul>
                              </td>
                              <td>
                                <Link
                                  className="tw-text-second"
                                  to={`/proposal/create/${eachPolicy.consultation_id}?object_id=${eachPolicy.object_id}&public=true`}
                                >
                                  Санал өгөх
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="tw-pl-30 tw-pr-30 tw-pb-40 tw-mt-10">
                    <hr />
                    <ul className="uk-pagination uk-margin-remove uk-flex-right tw-pagination style-2">
                      {policiesPages.map(eachPage => {
                        return (
                          <li
                            className={
                              eachPage === policyCurrentPage
                                ? "uk-active"
                                : null
                            }
                            onClick={() => this.changePolicyPage(eachPage)}
                            key={eachPage}
                          >
                            <span style={{ cursor: "pointer" }}>
                              {eachPage}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              ) : null}

              {!userForiengn ? (
                <div className="uk-width-1-1 uk-width-1-2@l uk-margin-remove tw-flex-second@l">
                  <div className="uk-background-default tw-shadow uk-overflow-auto">
                    <table className="uk-table tw-table uk-table-middle uk-table-hover">
                      <thead className="tw-button-primary uk-light">
                        <tr>
                          <th className="uk-table-expand">Бодлогын нэр</th>
                          <th className="tw-width-small" colSpan="2">
                            Хэрэгжүүлэх хугацаа
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {splicedTp.map(eachPolicy => {
                          return (
                            <tr key={eachPolicy.policy_id}>
                              <td>
                                <Link
                                  to={`/detail?policy=${eachPolicy.policy_id}`}
                                >
                                  {eachPolicy.policy_name}
                                </Link>
                              </td>
                              <td>
                                <ul className="uk-list tw-yellow-circle">
                                  <li style={{ padding: 0 }} className="active">
                                    <a href="#!">
                                      {eachPolicy.start_year} -{" "}
                                      {eachPolicy.end_year}
                                    </a>
                                  </li>
                                </ul>
                              </td>
                              <td>
                                <Popover
                                  content={content(eachPolicy)}
                                  title={
                                    <IntlMessages id="tentativeproposal.newTentative" />
                                  }
                                  trigger="click"
                                >
                                  <span
                                    style={{ cursor: "pointer" }}
                                    className="tw-text-second"
                                    to={`/proposal/list`}
                                  >
                                    Санал өгөх
                                  </span>
                                </Popover>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="tw-pl-30 tw-pr-30 tw-pb-40 tw-mt-10">
                    <hr />
                    <ul className="uk-pagination uk-margin-remove uk-flex-right tw-pagination style-2">
                      {tpPages.map(eachPage => {
                        return (
                          <li
                            className={
                              eachPage === tpCurrentPage ? "uk-active" : null
                            }
                            onClick={() => this.changeTpPage(eachPage)}
                            key={eachPage}
                          >
                            <span style={{ cursor: "pointer" }}>
                              {eachPage}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              ) : null}

              {!userForiengn ? (
                <div className="uk-width-1-1 uk-width-1-2@l tw-mb-30 tw-flex-first@l">
                  <div>
                    <h1 className="uk-heading-small uk-margin-remove">
                      Санал авч буй судалгаанууд
                      {/* <div className="uk-heading-small-count">10</div> */}
                    </h1>
                  </div>
                </div>
              ) : null}

              {!userForiengn ? (
                <div className="uk-width-1-1 uk-width-1-2@l tw-mb-30 tw-flex-third@l">
                  <div>
                    <h1 className="uk-heading-small uk-margin-remove tw-blue-circle">
                      Шинээр зарлагдсан судалгаа
                      {/* <div className="uk-heading-small-count">10</div> */}
                    </h1>
                  </div>
                </div>
              ) : null}

              {!userForiengn ? (
                <div className="uk-width-1-1 uk-width-1-2@l uk-margin-remove tw-flex-second@l">
                  <div className="uk-background-default tw-shadow uk-overflow-auto">
                    <table className="uk-table tw-table uk-table-middle uk-table-hover">
                      <thead className="tw-button-primary uk-light">
                        <tr>
                          <th className="uk-table-expand">Судалгааны нэр</th>
                          <th className="tw-width-small" colSpan="2">
                            Дуусах хугацаа
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {splicedResearch.map(eachResearch => {
                          return (
                            <tr key={eachResearch.research_id}>
                              <td>
                                <Link
                                  to={`/research?research=${eachResearch.research_id}`}
                                >
                                  {eachResearch.research_name}
                                </Link>
                              </td>
                              <td>
                                <ul className="uk-list tw-yellow-circle">
                                  <li style={{ padding: 0 }} className="active">
                                    <a href="#!">
                                      {eachResearch.research_deadline}
                                    </a>
                                  </li>
                                </ul>
                              </td>
                              <td>
                                <Link
                                  className="tw-text-second"
                                  to={`/research/research-proposal/${eachResearch.research_id}`}
                                >
                                  Санал өгөх
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="tw-pl-30 tw-pr-30 tw-pb-40 tw-mt-10">
                    <hr />
                    <ul className="uk-pagination uk-margin-remove uk-flex-right tw-pagination style-2">
                      {researchPages.map(eachPage => {
                        return (
                          <li
                            className={
                              eachPage === researchCurrentPage
                                ? "uk-active"
                                : null
                            }
                            onClick={() => this.changeResearchPage(eachPage)}
                            key={eachPage}
                          >
                            <span style={{ cursor: "pointer" }}>
                              {eachPage}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              ) : null}

              {!userForiengn ? (
                <div className="uk-width-1-1 uk-width-1-2@l uk-margin-remove tw-flex-fourth@l">
                  <div className="uk-background-default tw-shadow uk-overflow-auto">
                    <table className="uk-table tw-table uk-table-middle uk-table-hover">
                      <thead className="tw-button-primary uk-light">
                        <tr>
                          <th className="uk-table-expand">Судалгааны нэр</th>
                          <th className="tw-width-small" colSpan="2">
                            Хүсэлт хүлээж авах хугацаа
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {splicedJobs.map(eachJob => {
                          return (
                            <tr key={eachJob.id}>
                              <td>
                                <Link to={`/research?research=${eachJob.id}`}>
                                  {eachJob.name}
                                </Link>
                              </td>

                              <td style={{ padding: 0 }}>
                                <ul className="uk-list tw-yellow-circle">
                                  <li style={{ padding: 0 }} className="active">
                                    <a href="#!">
                                      {new Date(
                                        eachJob.request_deadline
                                      ).getFullYear()}{" "}
                                      -{" "}
                                      {new Date(
                                        eachJob.research_deadline
                                      ).getFullYear()}
                                    </a>
                                  </li>
                                </ul>
                              </td>
                              <td>
                                <Link
                                  className="tw-text-second"
                                  to={`/research/request/job/${eachJob.id}`}
                                >
                                  Хүсэлт илгээх
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="tw-pl-30 tw-pr-30 tw-pb-40 tw-mt-10">
                    <hr />
                    <ul className="uk-pagination uk-margin-remove uk-flex-right tw-pagination style-2">
                      {jobPages.map(eachPage => {
                        return (
                          <li
                            className={
                              eachPage === jobCurrentPage ? "uk-active" : null
                            }
                            onClick={() => this.changeJobPage(eachPage)}
                            key={eachPage}
                          >
                            <span style={{ cursor: "pointer" }}>
                              {eachPage}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </section>
        <div
          className="tw-box-3 uk-container uk-container-large"
          data-uk-scrollspy="target: > *; cls:uk-animation-slide-bottom-medium; delay: 300;"
        >
          <section className="uk-section uk-section-large uk-background-default tw-shadow tw-mb-130 tw-pl-45 tw-pr-45 tw-pl-89@m tw-pr-89@m">
            <div className="uk-grid-medium" data-uk-grid>
              <div
                className="uk-width-1-1 uk-width-5-12@l uk-flex-first@l"
                data-uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-medium; delay: 300;"
              >
                <div className="uk-height-1-1 uk-flex uk-flex-center uk-flex-column">
                  <h2 className="h2-theme" style={{ marginTop: 20 }}>
                    Хөгжлийн бодлого, төлөвлөлтийн мэдээллийн нэгдсэн сан
                  </h2>
                  <p className="tw-text-medium tw-mb-30">
                    Хөгжлийн бодлого төлөвлөлтөнд ашиглагддаг мэдээллийн
                    сангуудыг нэгтгэн Их өгөгдөл үүсгэх, уялдаа холбоог
                    харуулах, ХБТ-ийг хялбаршуулан Дижитал шилжилт хийхэд энэхүү
                    нэгдсэн мэдээллийн сангийн зорилго оршино.
                  </p>
                  <div>
                    <a
                      className="uk-button tw-button-primary uk-text-capitalize"
                      href="#!"
                    >
                      Дэлгэрэнгүй
                    </a>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-1 uk-width-7-12@l uk-flex-first tw-pt-30 uk-overflow-hidden uk-text-center">
                <ReactSVG src="assets/svg/illustrator.svg" />
              </div>
            </div>
          </section>
        </div>

        <Footer publicLang={true} />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};
export default connect(mapStateToProps)(Home);
