import React, { Component, Fragment } from "react";
import { Row, Form, Col, message, Spin } from "antd";
import ShowMoreText from "react-show-more-text";
import styles from "./style.module.scss";
import {
  publicFind,
  policyViewAnnexTwoPublicWeb
} from "../../../../restAPI/main";

import { policyTabKeys } from "../../../../restAPI/request";
import IntlMessages from "util/IntlMessages";
import NumberFormat from "react-number-format";

class AnnexTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainFinancials: [],
      mainFinancialsLoading: true,
      loading: true,
      start_year: 9999,
      end_year: 0,
      formData: [
        {
          direction_id: 0,
          objective_id: 0,
          direction_name: "",
          direction_name_en: "",
          objective_name: "",
          objective_name_en: "",
          activities: [
            {
              activity_id: 0,
              activity_name: "",
              activity_name_en: "",
              outputs: [
                {
                  output_id: 0,
                  output_name: "",
                  actioutput_name_envity_name_en: "",
                  funding_outputs: [
                    {
                      id: 0,
                      policy_id: 0,
                      activity_output_id: 0,
                      fiscal_year: 0,
                      total_funding: 0,
                      investment_costs: [],
                      operating_costs: []
                    }
                  ],
                  start_year: 0,
                  end_year: 0
                }
              ]
            }
          ]
        }
      ],
      mainYears: []
    };
  }

  componentWillMount() {
    this.fetchgetPolicyAnnex2();
    this.fetchFinancialList();
  }

  fetchgetPolicyAnnex2 = () => {
    const { policy } = this.props;
    policyViewAnnexTwoPublicWeb(
      policy.id,
      "",
      this.onSuccessGet,
      err => this.onFailed(err, ""),
      "post"
    );
  };

  onSuccessGet = response => {
    let mainYears = [1];
    for (let i = response.start_year; i < response.end_year; i++) {
      mainYears.push(i);
    }

    mainYears.push(9999);

    if (response.body) {
      response.body.map(obj => {
        obj.start_year = 9999;
        obj.end_year = 0;
        obj.activities.map(act => {
          act.start_year = 9999;
          act.end_year = 0;
          act.outputs.map(out => {
            if (act.start_year > out.start_year) {
              act.start_year = out.start_year;
            }
            if (act.end_year < out.end_year) {
              act.end_year = out.end_year;
            }
            return null;
          });
          if (obj.start_year > act.start_year) {
            obj.start_year = act.start_year;
          }
          if (obj.end_year < act.end_year) {
            obj.end_year = act.end_year;
          }
          return null;
        });
        return null;
      });
      this.setState({
        formData: response.body,
        start_year: response.start_year,
        end_year: response.end_year,
        loading: false,
        mainYears
      });
    } else {
      this.onFaild("Дата ирсэнгүй", "");
    }
  };

  onFaild = err => {
    message.error(err);
  };

  fetchFinancialList = () => {
    const { policy } = this.props;
    publicFind(
      "",
      this.onSuccessFinancial,
      err => this.onFaild(err, "mainFinancialsLoading"),
      "POST",
      policy.id
    );
  };

  onSuccessFinancial = response => {
    this.setState({
      mainFinancials: response,
      mainFinancialsLoading: false
    });
  };

  onFailed = (err, stateNameLoading) => {
    this.setState({
      [stateNameLoading]: false,
      loading: false
    });
    message.error(err);
  };

  onSuccessSave = (res, nextTab) => {
    const { next_rank, onChangeTabKey } = this.props;
    message.success("Амжилттай хадгаллаа.");
    if (nextTab) {
      const next_annex = `annex${next_rank}`;
      onChangeTabKey(policyTabKeys[next_annex]);
    }
  };

  funcTableHeader = (year, start_year) => {
    if (year === 1) {
      return "Төсвийн жил эхлэхээс өмнө";
    }
    if (year === 9999) {
      return "Төсвийн жил дууссаны дараа";
    }
    return `Төсвийн жил ${year - start_year + 1} - ${year}`;
  };

  calcYearHead1 = () => {
    const { start_year, mainYears } = this.state;
    let result = [];

    mainYears.map(i => {
      result.push(
        <th
          key={"yearhead1_" + i}
          className={(styles.tg9wq8, styles.bold)}
          colSpan="3"
        >
          {this.funcTableHeader(i, start_year)}
          {/* <IntlMessages id="annex2.fiscal_year" /> {i - start_year + 1} - {i}
          <br />({i - start_year + 1}) */}
        </th>
      );
      return result;
    });

    return result;
  };

  calcYearHead2 = () => {
    const { mainYears } = this.state;
    let result = [];
    mainYears.map(i => {
      result.push(
        <Fragment key={"yearhead2_" + i}>
          <th className={(styles.tg9wq8, styles.semi_bold)}>
            <IntlMessages id="annex2.total_financing_needed" />
          </th>
          <th className={(styles.tg9wq8, styles.semi_bold)}>
            <IntlMessages id="annex2.running_expenses" />
          </th>
          <th className={(styles.tg9wq8, styles.semi_bold)}>
            <IntlMessages id="annex2.investment" />
          </th>
        </Fragment>
      );
      return result;
    });

    return result;
  };

  calcYearFoot1 = () => {
    const { mainYears } = this.state;
    let result = [];

    mainYears.map(i => {
      result.push(
        <Fragment key={"yearfoot1_" + i}>
          <td className={styles.tgNrix}>
            <NumberFormat
              value={this.calcAllFundWithYearFund(i).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₮"}
            />
          </td>
          <td className={styles.tgNrix}>
            <NumberFormat
              value={this.calcAllFundWithYearRunn(i).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₮"}
            />
          </td>
          <td className={styles.tgNrix}>
            <NumberFormat
              value={this.calcAllFundWithYearInvest(i).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₮"}
            />
          </td>
        </Fragment>
      );
      return null;
    });

    return result;
  };

  calcAllFundWithYearFund = year => {
    const { formData } = this.state;
    let sum = 0;
    formData.map(obj => {
      obj.activities.map(act => {
        act.outputs.map(out => {
          out.funding_outputs.map(fund => {
            if (fund.fiscal_year === year) {
              sum += fund.total_funding;
            }
            return null;
          });
          return null;
        });
        return null;
      });
      return null;
    });
    return sum;
  };

  calcAllFund = () => {
    const { formData } = this.state;
    let sum = 0;
    formData.map(obj => {
      obj.activities.map(act => {
        act.outputs.map(out => {
          out.funding_outputs.map(fund => {
            sum += fund.total_funding;
            return null;
          });
          return null;
        });
        return null;
      });
      return null;
    });
    return sum;
  };

  calcAllFundWithYearRunn = year => {
    const { formData } = this.state;
    let sum = 0;
    formData.map(obj => {
      obj.activities.map(act => {
        act.outputs.map(out => {
          out.funding_outputs.map(fund => {
            if (fund.fiscal_year === year) {
              fund.operating_costs.map(eachOper => {
                sum += eachOper.amount;
                return null;
              });
            }
            return null;
          });
          return null;
        });
        return null;
      });
      return null;
    });
    return sum;
  };

  calcAllFundWithYearInvest = year => {
    const { formData } = this.state;
    let sum = 0;
    formData.map(obj => {
      obj.activities.map(act => {
        act.outputs.map(out => {
          out.funding_outputs.map(fund => {
            if (fund.fiscal_year === year) {
              fund.investment_costs.map(eachInvest => {
                sum += eachInvest.amount;
                return null;
              });
            }
            return null;
          });
          return null;
        });
        return null;
      });
      return null;
    });
    return sum;
  };

  calcYearFoot2 = () => {
    const { mainFinancials, mainYears } = this.state;

    let allYear = [];
    mainYears.map(i => {
      allYear.push(i);
      return null;
    });

    let result = [];
    result.push(
      mainFinancials.map((main, mainIndex) => {
        return (
          <tr key={mainIndex}>
            <td className={styles.tgNrix} colSpan="3">
              {main.name}
            </td>
            <td className={styles.tgNrix}>{this.calcAllFinanicial(main.id)}</td>
            {allYear.map(eachYear => {
              return (
                <Fragment key={"yearfoot1_" + eachYear}>
                  <td className={styles.tgNrix}>
                    <NumberFormat
                      value={this.calcAllFundTotalWithFinanical(
                        eachYear,
                        main.id
                      ).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₮"}
                    />
                  </td>
                  <td className={styles.tgNrix}>
                    <NumberFormat
                      value={this.calcAllFundOperWithFinanical(
                        eachYear,
                        main.id
                      ).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₮"}
                    />
                  </td>
                  <td className={styles.tgNrix}>
                    <NumberFormat
                      value={this.calcAllFundInvestWithFinanical(
                        eachYear,
                        main.id
                      ).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₮"}
                    />
                  </td>
                </Fragment>
              );
            })}
          </tr>
        );
      })
    );
    return result;
  };

  calcAllFinanicial = finalcial_sources_id => {
    const { formData } = this.state;
    let sum = 0;
    formData.map(obj => {
      obj.activities.map(act => {
        act.outputs.map(out => {
          out.funding_outputs.map(fund => {
            fund.operating_costs.map(eachOper => {
              if (eachOper.financial_source_id === finalcial_sources_id) {
                sum += eachOper.amount;
              }
              return null;
            });
            fund.investment_costs.map(eachInvest => {
              if (eachInvest.financial_source_id === finalcial_sources_id) {
                sum += eachInvest.amount;
              }
              return null;
            });
            return null;
          });
          return null;
        });
        return null;
      });
      return null;
    });
    return sum;
  };

  calcAllFundTotalWithFinanical = (year, finalcial_sources_id) => {
    const { formData } = this.state;
    let sum = 0;
    formData.map(obj => {
      obj.activities.map(act => {
        act.outputs.map(out => {
          out.funding_outputs.map(fund => {
            if (fund.fiscal_year === year) {
              fund.operating_costs.map(eachOper => {
                if (eachOper.financial_source_id === finalcial_sources_id) {
                  sum += eachOper.amount;
                }
                return null;
              });
              fund.investment_costs.map(eachInvest => {
                if (eachInvest.financial_source_id === finalcial_sources_id) {
                  sum += eachInvest.amount;
                }
                return null;
              });
            }
            return null;
          });
          return null;
        });
        return null;
      });
      return null;
    });
    return sum;
  };

  calcAllFundOperWithFinanical = (year, finalcial_sources_id) => {
    const { formData } = this.state;
    let sum = 0;
    formData.map(obj => {
      obj.activities.map(act => {
        act.outputs.map(out => {
          out.funding_outputs.map(fund => {
            if (fund.fiscal_year === year) {
              fund.operating_costs.map(eachOper => {
                if (eachOper.financial_source_id === finalcial_sources_id) {
                  sum += eachOper.amount;
                }
                return null;
              });
            }
            return null;
          });
          return null;
        });
        return null;
      });
      return null;
    });
    return sum;
  };

  calcAllFundInvestWithFinanical = (year, finalcial_sources_id) => {
    const { formData } = this.state;
    let sum = 0;
    formData.map(obj => {
      obj.activities.map(act => {
        act.outputs.map(out => {
          out.funding_outputs.map(fund => {
            if (fund.fiscal_year === year) {
              fund.investment_costs.map(eachInvest => {
                if (eachInvest.financial_source_id === finalcial_sources_id) {
                  sum += eachInvest.amount;
                }
                return null;
              });
            }
            return null;
          });
          return null;
        });
        return null;
      });
      return null;
    });
    return sum;
  };

  calcYearBody1 = (obj, objIndex) => {
    let result = [];

    result.push(
      <tr>
        <Fragment>
          <td className={(styles.tgNrix, styles.grey_background)}>
            {objIndex + 1}
          </td>
          <td
            style={{ textAlign: "justify" }}
            className={(styles.tgNrix, styles.grey_background)}
          >
            <ShowMoreText
              lines={3}
              more="Дэлгэрэнгүй"
              less="Хураах"
              anchorClass=""
              expanded={false}
              className="text-justify"
            >
              {obj.objective_name}
            </ShowMoreText>
          </td>

          <td className={(styles.tgNrix, styles.grey_background)}>
            {obj.start_year === 9999 ? "" : obj.start_year} -{" "}
            {obj.end_year === 0 ? "" : obj.end_year}
          </td>
        </Fragment>
        <td className={(styles.tgNrix, styles.grey_background)}>
          <NumberFormat
            value={this.calcObjectTotalWithFinalWithOutYear(objIndex).toFixed(
              2
            )}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"₮"}
          />
        </td>
        {this.calcYearBody1_sub(objIndex)}
      </tr>
    );
    return result;
  };

  calcObjectTotalWithFinalWithOutYear = (objIndex, final) => {
    const { formData } = this.state;
    let sum = 0;
    formData[objIndex].activities.map(act => {
      act.outputs.map(out => {
        out.funding_outputs.map(fund => {
          sum += fund.total_funding;
          // sum += fund.running_expense;
          return null;
        });
        return null;
      });
      return null;
    });
    return sum;
  };

  calcYearBody1_sub = objIndex => {
    const { mainYears } = this.state;
    let result = [];
    mainYears.map(i => {
      result.push(
        <Fragment key={"yearBody1_sub_" + i}>
          <td className={(styles.tgNrix, styles.grey_background)}>
            <NumberFormat
              value={this.calcObjectTotalWithFinalFund(objIndex, i).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₮"}
            />
          </td>
          <td className={(styles.tgNrix, styles.grey_background)}>
            <ul>
              {Object.keys(
                this.calcObjectTotalWithFinalRunning(objIndex, i)
              ).map(key => {
                return this.calcObjectTotalWithFinalRunning(objIndex, i)[
                  key
                ] ? (
                  <li key={key}>
                    {this.getFinianName(key)} -{" "}
                    <NumberFormat
                      value={this.calcObjectTotalWithFinalRunning(objIndex, i)[
                        key
                      ].toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₮"}
                    />
                  </li>
                ) : null;
              })}
            </ul>
          </td>
          <td className={(styles.tgNrix, styles.grey_background)}>
            <ul>
              {Object.keys(
                this.calcObjectTotalWithFinalInvest(objIndex, i)
              ).map(key => {
                return this.calcObjectTotalWithFinalInvest(objIndex, i)[key] ? (
                  <li key={key}>
                    {this.getFinianName(key)} -{" "}
                    <NumberFormat
                      value={this.calcObjectTotalWithFinalInvest(objIndex, i)[
                        key
                      ].toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₮"}
                    />
                  </li>
                ) : null;
              })}
            </ul>
          </td>
        </Fragment>
      );
      return null;
    });

    return result;
  };

  calcObjectTotalWithFinalFund = (objIndex, year) => {
    const { formData } = this.state;
    let sum = 0;
    formData[objIndex].activities.map(act => {
      act.outputs.map(out => {
        out.funding_outputs.map(fund => {
          if (fund.fiscal_year === year) {
            sum += fund.total_funding;
          }
          return null;
        });
        return null;
      });
      return null;
    });
    return sum;
  };

  calcObjectTotalWithFinalRunning = (objIndex, year) => {
    const { formData, mainFinancials } = this.state;
    let sum = {};
    mainFinancials.map(fin => {
      sum[fin.id] = 0;
      return null;
    });

    formData[objIndex].activities.map(act => {
      act.outputs.map(out => {
        out.funding_outputs.map(fund => {
          if (fund.fiscal_year === year) {
            fund.operating_costs.map(eachOper => {
              if (eachOper.financial_source_id) {
                sum[eachOper.financial_source_id] += eachOper.amount;
              }
              return null;
            });
          }
          return null;
        });
        return null;
      });
      return null;
    });
    return sum;
  };

  calcObjectTotalWithFinalInvest = (objIndex, year) => {
    const { formData, mainFinancials } = this.state;
    let sum = {};
    mainFinancials.map(fin => {
      sum[fin.id] = 0;
      return null;
    });

    formData[objIndex].activities.map(act => {
      act.outputs.map(out => {
        out.funding_outputs.map(fund => {
          if (fund.fiscal_year === year) {
            fund.investment_costs.map(eachInvest => {
              if (eachInvest.financial_source_id) {
                sum[eachInvest.financial_source_id] += eachInvest.amount;
              }
              return null;
            });
          }
          return null;
        });
        return null;
      });
      return null;
    });
    return sum;
  };

  calcYearBody2 = (objIndex, act, actIndex) => {
    let result = [];
    result.push(
      <tr>
        <Fragment>
          <td className={(styles.tgNrix, styles.light_grey_background)}>
            {objIndex + 1}.{actIndex + 1}
          </td>
          <td
            style={{ textAlign: "justify" }}
            className={(styles.tgNrix, styles.light_grey_background)}
          >
            <ShowMoreText
              lines={3}
              more="Дэлгэрэнгүй"
              less="Хураах"
              anchorClass=""
              expanded={false}
              className="text-justify"
            >
              {act.activity_name}
            </ShowMoreText>
          </td>
          <td className={(styles.tgNrix, styles.light_grey_background)}>
            {act.start_year === 9999 ? "" : act.start_year} -{" "}
            {act.end_year === 0 ? "" : act.end_year}
          </td>
        </Fragment>
        <td className={(styles.tgNrix, styles.light_grey_background)}>
          <NumberFormat
            value={this.calcActivityTotalWithFinalWithOutYear(
              objIndex,
              actIndex
            ).toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"₮"}
          />
        </td>
        {this.calcYearBody2_sub(objIndex, actIndex)}
      </tr>
    );
    return result;
  };

  calcYearBody2_sub = (objIndex, actIndex) => {
    const { mainYears } = this.state;
    let result = [];
    mainYears.map(i => {
      result.push(
        <Fragment key={"yearBody2_sub_" + i}>
          <td className={(styles.tgNrix, styles.light_grey_background)}>
            <NumberFormat
              value={this.calcActivityTotalWithFinalFund(
                objIndex,
                actIndex,
                i
              ).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₮"}
            />
          </td>
          <td className={(styles.tgNrix, styles.light_grey_background)}>
            <ul>
              {Object.keys(
                this.calcActivityTotalWithFinalRunning(objIndex, actIndex, i)
              ).map(key => {
                return this.calcActivityTotalWithFinalRunning(
                  objIndex,
                  actIndex,
                  i
                )[key] ? (
                  <li key={key}>
                    {this.getFinianName(key)} -{" "}
                    <NumberFormat
                      value={this.calcActivityTotalWithFinalRunning(
                        objIndex,
                        actIndex,
                        i
                      )[key].toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₮"}
                    />
                  </li>
                ) : null;
              })}
            </ul>
          </td>
          <td className={(styles.tgNrix, styles.light_grey_background)}>
            <ul>
              {Object.keys(
                this.calcActivityTotalWithFinalInvest(objIndex, actIndex, i)
              ).map(key => {
                return this.calcActivityTotalWithFinalInvest(
                  objIndex,
                  actIndex,
                  i
                )[key] ? (
                  <li key={key}>
                    {this.getFinianName(key)} -{" "}
                    <NumberFormat
                      value={this.calcActivityTotalWithFinalInvest(
                        objIndex,
                        actIndex,
                        i
                      )[key].toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₮"}
                    />
                  </li>
                ) : null;
              })}
            </ul>
          </td>
        </Fragment>
      );
      return null;
    });

    return result;
  };

  calcActivityTotalWithFinalFund = (objIndex, actIndex, year) => {
    const { formData } = this.state;
    let sum = 0;
    formData[objIndex].activities[actIndex].outputs.map(out => {
      out.funding_outputs.map(fund => {
        if (fund.fiscal_year === year) {
          sum += fund.total_funding;
        }
        return null;
      });
      return null;
    });
    return sum;
  };

  calcActivityTotalWithFinalWithOutYear = (objIndex, actIndex) => {
    const { formData } = this.state;
    let sum = 0;
    formData[objIndex].activities[actIndex].outputs.map(out => {
      out.funding_outputs.map(fund => {
        sum += fund.total_funding;
        return null;
      });
      return null;
    });
    return sum;
  };

  calcActivityTotalWithFinalRunning = (objIndex, actIndex, year) => {
    const { formData, mainFinancials } = this.state;
    let sum = {};
    mainFinancials.map(fin => {
      sum[fin.id] = 0;
      return null;
    });

    formData[objIndex].activities[actIndex].outputs.map(out => {
      out.funding_outputs.map(fund => {
        if (fund.fiscal_year === year) {
          fund.operating_costs.map(eachOper => {
            if (eachOper.financial_source_id) {
              sum[eachOper.financial_source_id] += eachOper.amount;
            }
            return null;
          });
        }
        return null;
      });
      return null;
    });
    return sum;
  };

  calcActivityTotalWithFinalInvest = (objIndex, actIndex, year) => {
    const { formData, mainFinancials } = this.state;
    let sum = {};
    mainFinancials.map(fin => {
      sum[fin.id] = 0;
      return null;
    });

    formData[objIndex].activities[actIndex].outputs.map(out => {
      out.funding_outputs.map(fund => {
        if (fund.fiscal_year === year) {
          fund.investment_costs.map(eachInvest => {
            if (eachInvest.financial_source_id) {
              sum[eachInvest.financial_source_id] += eachInvest.amount;
            }
            return null;
          });
        }
        return null;
      });
      return null;
    });
    return sum;
  };

  getFinianName = id => {
    const { mainFinancials } = this.state;
    let retVal = "";
    if (mainFinancials.find(x => x.id === parseInt(id))) {
      retVal = mainFinancials.find(x => x.id === parseInt(id)).name;
    }
    return retVal;
  };

  calcCurrentOutput = output => {
    let sum = 0;
    output.funding_outputs.map(eachfund => {
      sum += eachfund.total_funding;
      return null;
    });
    return sum;
  };

  calcOutTotalFund = (objIndex, actIndex, outIndex, fundIndex) => {
    const { formData } = this.state;
    let total = 0;
    formData[objIndex].activities[actIndex].outputs[outIndex].funding_outputs[
      fundIndex
    ].operating_costs.map(each => {
      total += each.amount;
      return "";
    });

    formData[objIndex].activities[actIndex].outputs[outIndex].funding_outputs[
      fundIndex
    ].investment_costs.map(each => {
      total += each.amount;
      return "";
    });

    formData[objIndex].activities[actIndex].outputs[outIndex].funding_outputs[
      fundIndex
    ].total_funding = total;

    this.setState({
      formData
    });
  };

  calcYearBody = (getFieldDecorator, objIndex, actIndex, outIndex) => {
    const { mainYears } = this.state;
    let result = [];
    mainYears.map(i => {
      if (
        i ===
        this.state.formData[objIndex].activities[actIndex].outputs[outIndex]
          .funding_outputs[0].fiscal_year
      ) {
        this.state.formData[objIndex].activities[actIndex].outputs[
          outIndex
        ].funding_outputs.map((fund, fundIndex) => {
          if (i === fund.fiscal_year) {
            result.push(
              <Fragment key={"fund_" + fundIndex}>
                <td className={(styles.tgNrix, styles.requerment_input)}>
                  <NumberFormat
                    value={fund.total_funding.toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₮"}
                  />
                </td>
                {/* ---------------operating_costs----------------- */}
                <td
                  style={{ minWidth: 300 }}
                  className={(styles.tgNrix, styles.requerment_input)}
                >
                  {fund.operating_costs.map((eachOpera, eachOperaIndex) => {
                    return (
                      <span>
                        {this.getFinianName(eachOpera.financial_source_id)} -
                        <NumberFormat
                          value={eachOpera.amount.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₮"}
                        />
                        <br />
                      </span>
                    );
                  })}
                </td>
                {/* ---------------investment_costs----------------- */}
                <td
                  style={{ minWidth: 300 }}
                  className={(styles.tgNrix, styles.requerment_input)}
                >
                  {fund.investment_costs.map((eachInvest, eachInvestIndex) => {
                    return (
                      <Row gutter={5} key={eachInvestIndex}>
                        <Col span={24}>
                          {this.getFinianName(eachInvest.financial_source_id)} -
                          <NumberFormat
                            value={eachInvest.amount.toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"₮"}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                </td>
              </Fragment>
            );
          }

          return null;
        });
      } else {
        result.push(
          <Fragment key={"fund_" + i}>
            <td className={styles.tgNrix}></td>
            <td className={styles.tgNrix}></td>
            <td className={styles.tgNrix}></td>
          </Fragment>
        );
      }
      return null;
    });
    return result;
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 20 },
        sm: { span: 20 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };
    const { formData, start_year, end_year, loading } = this.state;
    const spanLength = end_year - start_year + 1;

    return (
      <Fragment>
        <Row>
          <Col className="text-right" span={24}>
            <IntlMessages id="annexLeftTitle" values={{ annexNumber: `2` }} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={styles.title}>
              <IntlMessages id="annex2.a_title" />
              <br />
            </div>
          </Col>
          <Col className="text-right mb-4" span={24}>
            <IntlMessages id="annex2_sub_title" />
          </Col>
        </Row>

        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          {loading ? (
            <Spin />
          ) : (
            <table className={styles.tg}>
              <thead>
                <tr>
                  <th className={(styles.tg9wq8, styles.bold)} rowSpan="2">
                    #
                  </th>
                  <th
                    style={{ minWidth: "300px" }}
                    className={(styles.tg9wq8, styles.bold)}
                    rowSpan="2"
                  >
                    <IntlMessages id="annex2.policy_and_obj" />
                  </th>
                  <th
                    style={{ minWidth: 100 }}
                    className={(styles.tg9wq8, styles.bold)}
                    rowSpan="2"
                  >
                    <IntlMessages id="annex2.date_start_end" />
                  </th>
                  <th className={(styles.tg9wq8, styles.bold)} rowSpan="2">
                    <IntlMessages id="annex2.total" />
                  </th>
                  {this.calcYearHead1()}
                </tr>
                <tr>{this.calcYearHead2()}</tr>
              </thead>
              <tbody>
                {formData.map((obj, objIndex) => {
                  return (
                    <Fragment key={"obj_" + objIndex}>
                      {this.calcYearBody1(obj, objIndex)}
                      {obj.activities.map((act, actIndex) => {
                        return (
                          <Fragment key={"act_" + actIndex}>
                            {this.calcYearBody2(objIndex, act, actIndex)}
                            {act.outputs.map((out, outIndex) => {
                              return (
                                <tr key={"out_" + outIndex}>
                                  <td className={styles.tgNrix}>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                        textDecoration: "underline"
                                      }}
                                      onClick={() =>
                                        this.props.onChangeTabKey(
                                          policyTabKeys.annex6
                                        )
                                      }
                                    >
                                      {objIndex + 1}.{actIndex + 1}.
                                      {outIndex + 1}
                                    </span>
                                  </td>
                                  <td
                                    style={{ textAlign: "justify" }}
                                    className={styles.tgNrix}
                                  >
                                    <ShowMoreText
                                      lines={3}
                                      more="Дэлгэрэнгүй"
                                      less="Хураах"
                                      anchorClass=""
                                      expanded={false}
                                      className="text-justify"
                                    >
                                      {out.output_name}
                                    </ShowMoreText>
                                  </td>
                                  <td className={styles.tgNrix}>
                                    {out.start_year === 9999
                                      ? ""
                                      : out.start_year}{" "}
                                    - {out.end_year === 0 ? "" : out.end_year}
                                  </td>
                                  <td className={styles.tgNrix}>
                                    <NumberFormat
                                      value={this.calcCurrentOutput(
                                        out
                                      ).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"₮"}
                                    />
                                  </td>
                                  {this.calcYearBody(
                                    getFieldDecorator,
                                    objIndex,
                                    actIndex,
                                    outIndex
                                  )}
                                </tr>
                              );
                            })}
                          </Fragment>
                        );
                      })}
                    </Fragment>
                  );
                })}
                <tr>
                  <td className={styles.tgNrix} colSpan="3">
                    <IntlMessages id="annex2.total_amount_fund" />
                  </td>
                  <td className={styles.tgNrix}>
                    <NumberFormat
                      value={this.calcAllFund().toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₮"}
                    />
                  </td>
                  {this.calcYearFoot1()}
                </tr>
                <tr>
                  <td className={styles.tgNrix} colSpan={spanLength * 3 + 4}>
                    <IntlMessages id="annex2.finalcial_sources" />
                  </td>
                </tr>
                {this.calcYearFoot2()}
              </tbody>
            </table>
          )}
        </Form>
      </Fragment>
    );
  }
}
export default Form.create()(AnnexTwo);
