import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "components/Footer/index.js";
import IntlMessages from "util/IntlMessages";
import EcommerceStatus from "components/Metrics/EcommerceStatus";
import {
  policyviewCountPublicWeb,
  policyViewListPublicWeb
} from "../../restAPI/main";
import { message, Row, Col, Spin, Table, Tag, Input, Button, Icon } from "antd";
import Highlighter from "react-highlight-words";

import UserInfo from "components/UserInfo";

class Home extends React.Component {
  state = {
    typeCount: [],
    typeCountLoading: true,
    selecetTypeID: 0,
    policies: [],
    loading: true,
    searchText: ""
  };

  componentWillMount() {
    policyviewCountPublicWeb("", this.onSuccesCountList, this.onFailed);
    this.fetchList(0);
  }

  fetchList = type_id => {
    this.setState({
      loading: true
    });
    policyViewListPublicWeb(type_id, "", this.onSuccess, this.onFailed, "POST");
  };

  onSuccess = response => {
    const policies = [];
    response.map((i, index) => {
      i.key = index + 1;
      policies.push(i);
      return policies;
    });
    this.setState({
      policies,
      loading: false
    });
  };

  onSuccesCountList = response => {
    const typeCount = response.by_type;
    typeCount.push({
      policy_type_name: "Бүгд",
      policy_title: "Бүгд",
      policy_type_id: 0,
      policy_count: response.total_count,
      color: "#cddc39",
      icon: "fas fa-list"
    });
    this.setState({
      typeCount,
      typeCountLoading: false
    });
  };

  onFailed = err => {
    message.error(err);
  };

  onSelectType = type => {
    this.setState({
      selecetTypeID: type.policy_type_id
    });

    this.fetchList(type.policy_type_id);
  };

  getAnnext = (datas, record) => {
    return datas.map(data => {
      return (
        <Tag
          key={data.id}
          style={{
            marginRight: "5px",
            margin: 0
          }}
          color={data.is_complete ? "green" : "red"}
        >
          <a
            style={{
              color: "#f5222d"
            }}
            href={`/detail?policy=${record.id}&annex=${data.rank}`}
          >
            {data.rank}
          </a>
        </Tag>
      );
    });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Хайх`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Хайх
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Шинэчлэх
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : "0"}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const {
      typeCount,
      selecetTypeID,
      typeCountLoading,
      loading,
      policies
    } = this.state;
    const { authUser } = this.props;

    const columns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
        render: text => <span>{text}</span>,
        width: 30
      },
      {
        title: <IntlMessages id="policy.name" />,
        dataIndex: "policy_name",
        key: "policy_name",
        render: (text, record) => (
          <span style={{ textDecoration: "underline" }}>
            {<a href={`/detail?policy=${record.id}`}>{text || "-"}</a>}
          </span>
        ),
        sorter: (a, b) => ("" + a.policy_name).localeCompare(b.policy_name),
        sortDirections: ["descend", "ascend"],
        width: 350
      },
      {
        title: "Зорилтууд",
        dataIndex: "objectives_count",
        key: "objectives_count",
        render: text => <span>{text || "-"}</span>,
        sorter: (a, b) => a.objectives_count - b.objectives_count,
        sortDirections: ["descend", "ascend"],
        width: 100
      },
      {
        title: "Арга хэмжээнүүд",
        dataIndex: "activities_count",
        key: "activities_count",
        render: text => <span>{text || "-"}</span>,
        sorter: (a, b) => a.activities_count - b.activities_count,
        sortDirections: ["descend", "ascend"],
        width: 130
      },
      {
        title: "Шалгуур үзүүлэлтүүд",
        dataIndex: "indicotors_count",
        key: "indicotors_count",
        render: text => <span>{text || "-"}</span>,
        width: 60
      },

      {
        title: <IntlMessages id="policy.type" />,
        dataIndex: "policy_type_name",
        sorter: (a, b) =>
          ("" + a.policy_type_name).localeCompare(b.policy_type_name),
        sortDirections: ["descend", "ascend"],
        render: text => <span>{text || "-"}</span>,
        width: 120
      },
      {
        title: <IntlMessages id="policy.date" />,
        dataIndex: "policy_years",
        sorter: (a, b) => ("" + a.start_year).localeCompare(b.start_year),
        sortDirections: ["descend", "ascend"],
        width: 100
      },
      {
        title: <IntlMessages id="policy.date" />,
        dataIndex: "policy_years",
        sorter: (a, b) => ("" + a.start_year).localeCompare(b.start_year),
        sortDirections: ["descend", "ascend"],
        width: 120
      },
      {
        title: <IntlMessages id="policy.annex" />,
        dataIndex: "PolicyAnnexes",
        width: 250,
        render: (text, record) => (
          <span>{text ? this.getAnnext(text, record) : "-"}</span>
        )
      }
    ];

    return (
      <div
        className={`uk-clearfix tw-pt-50 tw-pt-30@m tw-pb-50 tw-pb-30@m tw-home-page`}
      >
        <div className="tw-header uk-container uk-container-large tw-mb-50 tw-mb-100@m">
          <header className="tw-header">
            <nav className="uk-navbar-container  uk-navbar">
              <div className="uk-navbar-left">
                <Link className="uk-logo uk-flex uk-flex-center" to="/home">
                  <img alt="" src="assets/images/logo.png" />
                </Link>
              </div>

              <div className="uk-navbar-right">
                <ul className="uk-navbar-nav uk-visible@l tw-main-menu uk-height-1-1">
                  <li>
                    <Link to="/home">Нүүр</Link>
                  </li>

                  <li>
                    <a href="/system">Системийн Танилцуулга</a>
                    <div className="uk-navbar-dropdown tw-button-primary uk-light">
                      <ul className="uk-nav uk-navbar-dropdown-nav">
                        <li className="uk-active">
                          <a href="#">Гарын авлага</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="uk-open">
                    <a href="/policy">Бодлого</a>
                  </li>
                  {authUser ? (
                    <li>
                      <a href="/dashboard">Хянах самбар</a>
                    </li>
                  ) : null}
                  <li>
                    <a href="/gis">Орон зайн мэдээлэл</a>
                  </li>
                  <li>
                    <div id="google_translate_element"></div>
                  </li>
                </ul>
                <ul className="uk-navbar-nav uk-flex uk-flex-middle tw-ml-60 uk-height-1-1 uk-visible@s">
                  {authUser ? (
                    <li className="uk-margin-remove tw-mr-15">
                      <UserInfo />
                    </li>
                  ) : (
                    <Fragment>
                      <li className="uk-margin-remove tw-mr-15">
                        <Link
                          className="uk-button tw-button-primary"
                          to="/signin"
                        >
                          Нэвтрэх
                        </Link>
                      </li>
                      <li className="uk-margin-remove tw-mr-20">
                        <Link
                          className="uk-button tw-button-warning"
                          style={{
                            background: "#F4C30E",
                            color: "#ffffff"
                          }}
                          to="/signup"
                        >
                          Бүртгүүлэх
                        </Link>
                      </li>
                    </Fragment>
                  )}
                </ul>
                <a
                  className="mobile-menu uk-navbar-toggle uk-hidden@l tw-mr-20"
                  href="#"
                  data-uk-toggle="target: #mobile-menu-modal"
                >
                  <span uk-icon="menu"></span>
                </a>
              </div>
            </nav>
          </header>
        </div>
        <div id="mobile-menu-modal" className="uk-modal-full" data-uk-modal>
          <div className="uk-modal-dialog">
            <button
              className="uk-modal-close-full"
              type="button"
              data-uk-close
            ></button>
            <div
              className="uk-height-viewport tw-mobile-modal uk-flex uk-flex-middle uk-flex-center"
              data-uk-scrollspy="target:>ul>li,>div>a; cls:uk-animation-slide-bottom-medium; delay: 150;"
            >
              <ul className="uk-nav-default uk-nav-parent-icon" data-uk-nav>
                <li>
                  <a href="index.html">Нүүр</a>
                </li>
                <li>
                  <a href="#">Системийн Танилцуулга</a>
                </li>
                <li>
                  <a href="/policy">Бодлого</a>
                </li>
                {authUser ? (
                  <li>
                    <a href="/dashboard">Хянах самбар</a>
                  </li>
                ) : null}
                <li>
                  <a href="/gis">Орон зайн мэдээлэл</a>
                </li>
                <li className="uk-hidden@m">
                  <Link to="/signin">Нэвтрэх</Link>
                </li>
                <li className="uk-hidden@m">
                  <Link to="/signup">Бүртгүүлэх</Link>
                </li>
              </ul>

              <div className="tw-socials tw-socials-minimal">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/ndagovMN/"
                >
                  <i className="ion-social-facebook"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/ndagovMN"
                >
                  <i className="ion-social-twitter"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCwa3Yh0SrzZlUR9IzkJHo5Q"
                >
                  <i className="ion-social-pinterest"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <section className="uk-section tw-card-2">
          <div
            className="uk-container uk-container-large"
            data-uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-small; delay: 300;"
          >
            <div className="uk-flex-center uk-grid-medium" data-uk-grid>
              <div className="uk-width-12-12 uk-width-12-12@m uk-width-12-12@l">
                <div className="tw-element tw-mr-30-rev tw-ml-30-rev">
                  <div className="uk-card uk-card-default uk-card-large uk-card-body">
                    {typeCountLoading ? (
                      <Spin />
                    ) : (
                      <Row>
                        {typeCount.map((eachType, eachTypeIndex) => {
                          return (
                            <Col
                              key={eachTypeIndex}
                              xl={3}
                              lg={6}
                              md={6}
                              sm={24}
                              xs={24}
                            >
                              <EcommerceStatus
                                height="140px"
                                icon={eachType.icon}
                                onSelectType={this.onSelectType}
                                cardColor={eachType.color}
                                eachType={eachType}
                                title={eachType.policy_count}
                                subTitle={eachType.policy_type_name}
                                selecetTypeID={selecetTypeID}
                                policy_title={eachType.policy_title}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    )}

                    <Table
                      scroll={{ x: "max-content" }}
                      className="gx-table-responsive"
                      size="small"
                      rowKey="id"
                      columns={columns}
                      dataSource={policies}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer publicLang={true} />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};
export default connect(mapStateToProps)(Home);
