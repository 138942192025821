import React, { Component, Fragment } from "react";
import { Form, message, Spin } from "antd";

import { policyViewObjectivePublicWeb } from "../../../../restAPI/main";
import styles from "./style.module.scss";
import ShowMoreText from "react-show-more-text";
import IntlMessages from "util/IntlMessages";

class Objective extends Component {
  state = {
    objectiveData: [],
    loading: true
  };

  componentWillMount() {
    const { policy_id } = this.props;
    policyViewObjectivePublicWeb(
      policy_id,
      "",
      this.onSuccessGet,
      this.onFailed
    );
  }

  onSuccessGet = response => {
    this.setState({
      objectiveData: response,
      loading: false
    });
  };

  onFailed = err => {
    message.error(err);
  };

  //#endregion

  render() {
    const { objectiveData, loading } = this.state;
    const { policy } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 20 },
        sm: { span: 20 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        {loading ? (
          <Spin />
        ) : (
          <table className={styles.tg}>
            <thead>
              <tr>
                <th style={{ width: 50 }} className={styles.tgNrix} rowSpan="2">
                  <b>#</b>
                </th>
                <th
                  style={{ fontWeight: "bold", width: 100 }}
                  className={styles.tgNrix}
                  rowSpan="2"
                >
                  <IntlMessages id="objective.general_point" />
                </th>
                <th
                  style={{ fontWeight: "bold", width: 100 }}
                  className={styles.tgNrix}
                  rowSpan="2"
                >
                  <IntlMessages id="objective.inner_output" />
                </th>
                <th
                  className={styles.tgBaqh}
                  style={{ width: 400, fontWeight: "bold" }}
                >
                  <IntlMessages id="policy.direction" />
                </th>
                <th
                  style={{ fontWeight: "bold", minWidth: "300px" }}
                  className={styles.tgBaqh}
                >
                  <IntlMessages id="objective.mongolia" />
                </th>
                {policy.language === 2 ? (
                  <th
                    style={{ minWidth: "300px", fontWeight: "bold" }}
                    className={styles.tgBaqh}
                  >
                    <IntlMessages id="objective.english" />
                  </th>
                ) : null}
              </tr>
              <tr>
                <td
                  style={{ fontWeight: "bold" }}
                  className={styles.tgBaqh}
                  colSpan="2"
                >
                  <IntlMessages id="reference.sector" />
                </td>
              </tr>
            </thead>
            <tbody>
              {objectiveData.map((data, index) => {
                return (
                  <Fragment key={index}>
                    <tr>
                      <td
                        className={styles.tgNrix}
                        rowSpan="2"
                        style={{ width: 50 }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className={styles.tgNrix}
                        rowSpan="2"
                        style={{ width: 100 }}
                      >
                        {data.inner_total + data.outer_total || ""}
                      </td>
                      <td
                        className={styles.tgNrix}
                        rowSpan="2"
                        style={{ width: 100 }}
                      >
                        {data.inner_rank} / {data.outer_rank}
                      </td>
                      <td
                        className={styles.tgBaqh}
                        style={{ width: 200, maxWidth: 350 }}
                      >
                        <ShowMoreText
                          lines={3}
                          more="Дэлгэрэнгүй"
                          less="Хураах"
                          anchorClass=""
                          expanded={false}
                          className="text-left"
                        >
                          {data.direction_name}
                        </ShowMoreText>
                      </td>
                      <td className={styles.tgBaqh}>
                        <ShowMoreText
                          lines={3}
                          more="Дэлгэрэнгүй"
                          less="Хураах"
                          anchorClass=""
                          expanded={false}
                          className="text-left"
                        >
                          {data.objective}
                        </ShowMoreText>
                      </td>
                      {policy.language === 2 ? (
                        <td className={styles.tgBaqh}>{data.objective_en}</td>
                      ) : null}
                    </tr>
                    <tr>
                      <td
                        style={{ textAlign: "left" }}
                        className={styles.tgBaqh}
                        colSpan={policy.language === 2 ? "3" : "2"}
                      >
                        <ul>
                          {data.ObjectiveEcocats.map(
                            (eachEchocat, echocatIndex) => {
                              return (
                                <li key={echocatIndex}>
                                  {eachEchocat.ecocat_name}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        )}
      </Form>
    );
  }
}

export default Form.create()(Objective);
