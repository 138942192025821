import React, { Fragment } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Col,
  Row,
  InputNumber,
  Checkbox,
  message,
  Alert
} from "antd";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { userSignUp } from "appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import Axios from "axios";
import { config } from "../restAPI/config";
import { listregions } from "../restAPI/main";

const FormItem = Form.Item;
const Option = Select.Option;

class SignUp extends React.Component {
  state = {
    loading: false,
    is_foreign: false,

    aimagAddress: [],
    aimagAddressLoading: true,

    khorooAddress: [],
    khorooAddressLoading: false,

    bagAddress: [],
    bagAddressLoading: false,
    is_success: false
  };

  componentWillMount() {
    this.fetchAddress(3, 0, "aimagAddress");
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let zda = Object.assign({}, values);

        if (!values.is_foreign) {
          zda.rd =
            zda.rd_firstLetter + zda.rd_second_letter + zda.rd.toString();
        } else {
          zda.rd = "";
        }

        zda.type = 0;

        this.setState({
          loading: true
        });

        Axios.post(config.BASE_URL + "Auth/register", zda)
          .then(response => {
            if (response.data.status_code === 0) {
              this.setState({
                is_success: true
              });
            } else {
              message.error(response.data.error_msg);
              this.setState({
                loading: false
              });
            }
          })
          .catch(error => {
            message.error(error);
            this.setState({
              loading: false
            });
          });
      }
    });
  };

  fetchAddress(type, parent_id, stateName) {
    const reuqest = {
      type,
      parent_id
    };
    listregions(
      reuqest,
      response => this.onSuccesAddress(response, stateName),
      err => this.onFailed(err, "aimagAddressLoading"),
      "POST"
    );
  }

  onFailed = error => {
    message.error(error);
  };

  onSuccesAddress = (response, stateName) => {
    this.setState({
      [stateName]: response,
      [`${stateName}Loading`]: false
    });
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("2 нууц үг ижилхэн байх ёстой!");
    } else {
      callback();
    }
  };

  onChangeAimag = (event, stateName) => {
    //  id, "khorooAddress", index, "aimag_hot_id"
    this.fetchAddress(4, event, stateName);

    if (stateName === "khorooAddress") {
      this.props.form.setFieldsValue({
        [`sum_id`]: ""
      });
      this.props.form.setFieldsValue({
        [`bag_id`]: ""
      });

      this.setState({
        bagAddress: []
      });
    } else if (stateName === "bagAddress") {
      this.props.form.setFieldsValue({
        [`bag_id`]: ""
      });
    }

    this.setState({
      [`${stateName}Loading`]: true
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      is_foriegn,
      loading,
      aimagAddress,
      aimagAddressLoading,
      khorooAddress,
      khorooAddressLoading,
      bagAddress,
      bagAddressLoading,
      is_success
    } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container" style={{ maxWidth: 850 }}>
          <div className="gx-app-login-main-content">
            <div className="gx-app-login-content" style={{ width: "100%" }}>
              <div className="text-center">
                <img alt="" src={require("assets/images/logo-thumb.png")} />
              </div>

              {is_success ? (
                <Fragment>
                  <Alert
                    className="mt-5"
                    message="Бид баталгаажуулах кодыг таны имэйл хаяг руу илгээсэн!"
                    type="success"
                  />

                  <a href="/signin" className="mt-4" style={{ float: "right" }}>
                    <Button type="primary">Нэвтрэх</Button>
                  </a>
                </Fragment>
              ) : (
                <Form
                  onSubmit={this.handleSubmit}
                  className="mt-5 gx-signup-form gx-form-row0"
                >
                  <Row gutter={10}>
                    <Col className="" span={24}>
                      <FormItem>
                        {getFieldDecorator(`is_foreign`, {
                          initialValue: is_foriegn,
                          rules: [
                            {
                              required: false
                            }
                          ]
                        })(
                          <Checkbox
                            onChange={e =>
                              this.setState({ is_foriegn: e.target.checked })
                            }
                          >
                            Гадаад хүн
                          </Checkbox>
                        )}
                      </FormItem>
                    </Col>
                    <Col className="" xl={12} xs={24} md={24}>
                      {is_foriegn ? null : (
                        <Row gutter={2}>
                          <Col span={4}>
                            <FormItem>
                              {getFieldDecorator("rd_firstLetter", {
                                initialValue: "A",
                                rules: [{ required: true }]
                              })(
                                <Select>
                                  <Option key="А" value="А">
                                    А
                                  </Option>
                                  <Option key="Б" value="Б">
                                    Б
                                  </Option>
                                  <Option key="В" value="В">
                                    В
                                  </Option>
                                  <Option key="Г" value="Г">
                                    Г
                                  </Option>
                                  <Option key="Д" value="Д">
                                    Д
                                  </Option>
                                  <Option key="Е" value="Е">
                                    Е
                                  </Option>
                                  <Option key="Ё" value="Ё">
                                    Ё
                                  </Option>
                                  <Option key="Ж" value="Ж">
                                    Ж
                                  </Option>
                                  <Option key="З" value="З">
                                    З
                                  </Option>
                                  <Option key="И" value="И">
                                    И
                                  </Option>
                                  <Option key="Й" value="Й">
                                    Й
                                  </Option>
                                  <Option key="К" value="К">
                                    К
                                  </Option>
                                  <Option key="Л" value="Л">
                                    Л
                                  </Option>
                                  <Option key="М" value="М">
                                    М
                                  </Option>
                                  <Option key="Н" value="Н">
                                    Н
                                  </Option>
                                  <Option key="О" value="О">
                                    О
                                  </Option>
                                  <Option key="Ө" value="Ө">
                                    Ө
                                  </Option>
                                  <Option key="П" value="П">
                                    П
                                  </Option>
                                  <Option key="Р" value="Р">
                                    Р
                                  </Option>
                                  <Option key="С" value="С">
                                    С
                                  </Option>
                                  <Option key="Т" value="Т">
                                    Т
                                  </Option>
                                  <Option key="У" value="У">
                                    У
                                  </Option>
                                  <Option key="Ү" value="Ү">
                                    Ү
                                  </Option>
                                  <Option key="Ф" value="Ф">
                                    Ф
                                  </Option>
                                  <Option key="Х" value="Х">
                                    Х
                                  </Option>
                                  <Option key="Ц" value="Ц">
                                    Ц
                                  </Option>
                                  <Option key="Ч" value="Ч">
                                    Ч
                                  </Option>
                                  <Option key="Ш" value="Ш">
                                    Ш
                                  </Option>
                                  <Option key="Ъ" value="Щ">
                                    Щ
                                  </Option>
                                  <Option key="П" value="Ъ">
                                    Ъ
                                  </Option>
                                  <Option key="Ь" value="Ь">
                                    Ь
                                  </Option>
                                  <Option key="Ы" value="Ы">
                                    Ы
                                  </Option>
                                  <Option key="Э" value="Э">
                                    Э
                                  </Option>
                                  <Option key="Ю" value="Ю">
                                    Ю
                                  </Option>
                                  <Option key="Я" value="Я">
                                    Я
                                  </Option>
                                </Select>
                              )}
                            </FormItem>
                          </Col>
                          <Col span={4}>
                            <FormItem>
                              {getFieldDecorator("rd_second_letter", {
                                initialValue: "A",
                                rules: [{ required: true }]
                              })(
                                <Select>
                                  <Option key="А" value="А">
                                    А
                                  </Option>
                                  <Option key="Б" value="Б">
                                    Б
                                  </Option>

                                  <Option key="В" value="В">
                                    В
                                  </Option>
                                  <Option key="Г" value="Г">
                                    Г
                                  </Option>
                                  <Option key="Д" value="Д">
                                    Д
                                  </Option>
                                  <Option key="Е" value="Е">
                                    Е
                                  </Option>
                                  <Option key="Ё" value="Ё">
                                    Ё
                                  </Option>
                                  <Option key="Ж" value="Ж">
                                    Ж
                                  </Option>
                                  <Option key="З" value="З">
                                    З
                                  </Option>
                                  <Option key="И" value="И">
                                    И
                                  </Option>
                                  <Option key="Й" value="Й">
                                    Й
                                  </Option>
                                  <Option key="К" value="К">
                                    К
                                  </Option>
                                  <Option key="Л" value="Л">
                                    Л
                                  </Option>
                                  <Option key="М" value="М">
                                    М
                                  </Option>
                                  <Option key="Н" value="Н">
                                    Н
                                  </Option>
                                  <Option key="О" value="О">
                                    О
                                  </Option>
                                  <Option key="Ө" value="Ө">
                                    Ө
                                  </Option>
                                  <Option key="П" value="П">
                                    П
                                  </Option>
                                  <Option key="Р" value="Р">
                                    Р
                                  </Option>
                                  <Option key="С" value="С">
                                    С
                                  </Option>
                                  <Option key="Т" value="Т">
                                    Т
                                  </Option>
                                  <Option key="У" value="У">
                                    У
                                  </Option>
                                  <Option key="Ү" value="Ү">
                                    Ү
                                  </Option>
                                  <Option key="Ф" value="Ф">
                                    Ф
                                  </Option>
                                  <Option key="Х" value="Х">
                                    Х
                                  </Option>
                                  <Option key="Ц" value="Ц">
                                    Ц
                                  </Option>
                                  <Option key="Ч" value="Ч">
                                    Ч
                                  </Option>
                                  <Option key="Ш" value="Ш">
                                    Ш
                                  </Option>
                                  <Option key="Ъ" value="Щ">
                                    Щ
                                  </Option>
                                  <Option key="П" value="Ъ">
                                    Ъ
                                  </Option>
                                  <Option key="Ь" value="Ь">
                                    Ь
                                  </Option>
                                  <Option key="Ы" value="Ы">
                                    Ы
                                  </Option>
                                  <Option key="Э" value="Э">
                                    Э
                                  </Option>
                                  <Option key="Ю" value="Ю">
                                    Ю
                                  </Option>
                                  <Option key="Я" value="Я">
                                    Я
                                  </Option>
                                </Select>
                              )}
                            </FormItem>
                          </Col>
                          <Col span={16}>
                            <FormItem>
                              {getFieldDecorator("rd", {
                                rules: [
                                  {
                                    required: true,
                                    message: "РД оруулна уу!",
                                    type: "number"
                                  }
                                ]
                              })(
                                <InputNumber
                                  placeholder="РД"
                                  minLength={8}
                                  maxLength={8}
                                  style={{ width: "100%" }}
                                />
                              )}
                            </FormItem>
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col className="" xl={is_foriegn ? 24 : 12} xs={24} md={24}>
                      <FormItem label="">
                        {getFieldDecorator("lastname", {
                          rules: [
                            {
                              required: true,
                              message: "Овогоо орууна уу!"
                            }
                          ]
                        })(<Input placeholder="Овог" />)}
                      </FormItem>
                    </Col>
                    <Col className="" xl={12} xs={24} md={24}>
                      <FormItem label="">
                        {getFieldDecorator("firstname", {
                          rules: [
                            {
                              required: true,
                              message: "Нэрээ орууна уу!"
                            }
                          ]
                        })(<Input placeholder="Нэр" />)}
                      </FormItem>
                    </Col>

                    <Col className="" xl={12} xs={24} md={24}>
                      <FormItem>
                        {getFieldDecorator("email", {
                          rules: [
                            {
                              required: true,
                              type: "email",
                              message: "Цахим хаягаа оруулна уу!"
                            }
                          ]
                        })(<Input placeholder="Цахим шуудан" />)}
                      </FormItem>
                    </Col>
                    <Col className="" xl={12} xs={24} md={24}>
                      <Form.Item hasFeedback>
                        {getFieldDecorator("password", {
                          rules: [
                            {
                              required: true,
                              message: "Нууц үгээ орууна уу!"
                            },
                            {
                              validator: this.validateToNextPassword
                            }
                          ]
                        })(<Input.Password placeholder="Нууц үг" />)}
                      </Form.Item>
                    </Col>
                    <Col className="" xl={12} xs={24} md={24}>
                      <Form.Item label="" hasFeedback>
                        {getFieldDecorator("confirm", {
                          rules: [
                            {
                              required: true,
                              message: "Нууц үгээ баталгаажуулна уу!"
                            },
                            {
                              validator: this.compareToFirstPassword
                            }
                          ]
                        })(
                          <Input.Password
                            placeholder="Нууц үг баталгаажуулах"
                            onBlur={this.handleConfirmBlur}
                          />
                        )}
                      </Form.Item>
                    </Col>

                    {is_foriegn ? null : (
                      <Col span={24} style={{ padding: 0 }}>
                        <Row gutter={10}>
                          <Col span={8}>
                            <FormItem>
                              {getFieldDecorator(`aimag_id`, {
                                rules: [
                                  {
                                    required: true,
                                    message: (
                                      <IntlMessages id="select_required" />
                                    )
                                  }
                                ]
                              })(
                                <Select
                                  placeholder={
                                    <IntlMessages id="region_aimag_hot" />
                                  }
                                  onChange={e =>
                                    this.onChangeAimag(e, "khorooAddress")
                                  }
                                  allowClear
                                  loading={aimagAddressLoading}
                                >
                                  {aimagAddress.map(eachAimag => {
                                    return (
                                      <Option
                                        key={eachAimag.id}
                                        value={eachAimag.id}
                                      >
                                        {eachAimag.region_name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              )}
                            </FormItem>
                          </Col>
                          <Col span={8}>
                            <FormItem>
                              {getFieldDecorator(`sum_id`, {
                                rules: [
                                  {
                                    required: true,
                                    message: (
                                      <IntlMessages id="select_required" />
                                    )
                                  }
                                ]
                              })(
                                <Select
                                  placeholder={
                                    <IntlMessages id="region_sum_district" />
                                  }
                                  onChange={e =>
                                    this.onChangeAimag(e, "bagAddress")
                                  }
                                  allowClear
                                  loading={khorooAddressLoading}
                                >
                                  {khorooAddress.map(eachKhoroo => {
                                    return (
                                      <Option
                                        key={eachKhoroo.id}
                                        value={eachKhoroo.id}
                                      >
                                        {eachKhoroo.region_name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              )}
                            </FormItem>
                          </Col>
                          <Col span={8}>
                            <FormItem>
                              {getFieldDecorator(`bag_id`, {
                                rules: [
                                  {
                                    required: true,
                                    message: (
                                      <IntlMessages id="select_required" />
                                    )
                                  }
                                ]
                              })(
                                <Select
                                  placeholder={
                                    <IntlMessages id="region_bag_khoroo" />
                                  }
                                  allowClear
                                  loading={bagAddressLoading}
                                >
                                  {bagAddress.map(eachBag => {
                                    return (
                                      <Option
                                        key={eachBag.id}
                                        value={eachBag.id}
                                      >
                                        {eachBag.region_name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              )}
                            </FormItem>
                          </Col>
                        </Row>
                      </Col>
                    )}

                    <Col span={12} style={{ float: "right" }}>
                      <Link to="/signin">
                        <Button
                          style={{ padding: 0 }}
                          type="link"
                          icon="backward"
                          className="gx-mb-0"
                        >
                          <IntlMessages id="app.userAuth.signIn" />
                        </Button>
                      </Link>
                    </Col>
                    <Col span={12} className="text-right">
                      <Button
                        loading={loading}
                        type="primary"
                        className="gx-mb-0 primaryColor"
                        htmlType="submit"
                      >
                        <IntlMessages id="app.userAuth.signUp" />
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
            {loader && (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            )}
            {showMessage && message.error(alertMessage)}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignUp
})(WrappedSignUpForm);
