import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover, Divider, message, Spin, Icon } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { download } from "util/helper";
import { getReferenceList } from "../../restAPI/main";
import { MagicReference } from "../../restAPI/request";

class UserInfo extends Component {
  state = {
    urdDatas: [],
    loading: true
  };

  componentWillMount() {
    getReferenceList(
      MagicReference.SYSTEM_URL,
      "",
      this.onSuccessURL,
      this.onFailed,
      "POST"
    );
  }

  onFailed = err => {
    message.error(err);
    this.setState({
      loading: false
    });
  };

  onSuccessURL = response => {
    this.setState({ urdDatas: response, loading: false });
  };

  render() {
    const { loading, urdDatas } = this.state;
    const tmp = localStorage.getItem("user_datas");
    let userData = {};
    if (tmp) {
      userData = JSON.parse(tmp);
    }

    const userMenuOptions = (
      <ul className="gx-user-popover" style={{ width: 250 }}>
        <li>
          <b>И-мэйл:</b> <br />
          {userData.email}
        </li>
        <li>
          <b>Овог Нэр:</b> <br />
          {userData.firstname} {userData.lastname}
        </li>
        <Divider />
        <li style={{ color: "#038fde" }}>
          {loading ? (
            <Spin />
          ) : (
            <span onClick={() => download(urdDatas[0].url, "Хэрэглэгчийн гарын авлага")}>
              <Icon type="book" /> Хэрэглэгчийн гарын авлага
            </span>
          )}
        </li>

        <li>
          <Link to="/profile">
            <Icon type="user" /> <IntlMessages id="user.profile" />{" "}
          </Link>
        </li>
        <li style={{ color: "red" }} onClick={() => this.props.userSignOut()}>
          <Icon type="logout" /> <IntlMessages id="user.logout" />
        </li>
      </ul>
    );

    return (
      <Popover
        overlayClassName="gx-popover-horizantal"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          src={userData.avatar_path}
          className="gx-avatar gx-pointer"
          alt=""
        />
      </Popover>
    );
  }
}

export default connect(null, { userSignOut })(UserInfo);
