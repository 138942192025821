import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "components/Footer/index.js";

import UserInfo from "components/UserInfo";

import { config } from "../../restAPI/config";

class PublicGIS extends React.Component {
  render() {
    const { authUser } = this.props;

    return (
      <div
        className={`uk-clearfix tw-pt-50 tw-pt-30@m tw-pb-50 tw-pb-30@m tw-home-page`}
      >
        <div className="tw-header uk-container uk-container-large tw-mb-50 tw-mb-100@m">
          <header className="tw-header">
            <nav className="uk-navbar-container  uk-navbar">
              <div className="uk-navbar-left">
                <Link className="uk-logo uk-flex uk-flex-center" to="/home">
                  <img alt="" src="assets/images/logo.png" />
                </Link>
              </div>

              <div className="uk-navbar-right">
                <ul className="uk-navbar-nav uk-visible@l tw-main-menu uk-height-1-1">
                  <li>
                    <Link to="/home">Нүүр</Link>
                  </li>

                  <li>
                    <a href="/system">Системийн Танилцуулга</a>
                    <div className="uk-navbar-dropdown tw-button-primary uk-light">
                      <ul className="uk-nav uk-navbar-dropdown-nav">
                        <li className="uk-active">
                          <a href="#">Гарын авлага</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <a href="/policy">Бодлого</a>
                  </li>
                  {authUser ? (
                    <li>
                      <a href="/dashboard">Хянах самбар</a>
                    </li>
                  ) : null}
                  <li className="uk-open">
                    <a href="/gis">Орон зайн мэдээлэл</a>
                  </li>
                  <li>
                    <div id="google_translate_element"></div>
                  </li>
                </ul>
                <ul className="uk-navbar-nav uk-flex uk-flex-middle tw-ml-60 uk-height-1-1 uk-visible@s">
                  {authUser ? (
                    <li className="uk-margin-remove tw-mr-15">
                      <UserInfo />
                    </li>
                  ) : (
                    <Fragment>
                      <li className="uk-margin-remove tw-mr-15">
                        <Link
                          className="uk-button tw-button-primary"
                          to="/signin"
                        >
                          Нэвтрэх
                        </Link>
                      </li>
                      <li className="uk-margin-remove tw-mr-20">
                        <Link
                          className="uk-button tw-button-warning"
                          style={{
                            background: "#F4C30E",
                            color: "#ffffff"
                          }}
                          to="/signup"
                        >
                          Бүртгүүлэх
                        </Link>
                      </li>
                    </Fragment>
                  )}
                </ul>
                <a
                  className="mobile-menu uk-navbar-toggle uk-hidden@l tw-mr-20"
                  href="#"
                  data-uk-toggle="target: #mobile-menu-modal"
                >
                  <span uk-icon="menu"></span>
                </a>
              </div>
            </nav>
          </header>
        </div>
        <div id="mobile-menu-modal" className="uk-modal-full" data-uk-modal>
          <div className="uk-modal-dialog">
            <button
              className="uk-modal-close-full"
              type="button"
              data-uk-close
            ></button>
            <div
              className="uk-height-viewport tw-mobile-modal uk-flex uk-flex-middle uk-flex-center"
              data-uk-scrollspy="target:>ul>li,>div>a; cls:uk-animation-slide-bottom-medium; delay: 150;"
            >
              <ul className="uk-nav-default uk-nav-parent-icon" data-uk-nav>
                <li>
                  <a href="index.html">Нүүр</a>
                </li>
                <li>
                  <a href="#">Системийн Танилцуулга</a>
                </li>
                <li>
                  <a href="/policy">Бодлого</a>
                </li>
                {authUser ? (
                  <li>
                    <a href="/dashboard">Хянах самбар</a>
                  </li>
                ) : null}
                <li>
                  <a href="#!"> Орон зайн мэдээлэл</a>
                </li>
                <li className="uk-hidden@m">
                  <Link to="/signin">Нэвтрэх</Link>
                </li>
                <li className="uk-hidden@m">
                  <Link to="/signup">Бүртгүүлэх</Link>
                </li>
              </ul>

              <div className="tw-socials tw-socials-minimal">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/ndagovMN/"
                >
                  <i className="ion-social-facebook"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/ndagovMN"
                >
                  <i className="ion-social-twitter"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCwa3Yh0SrzZlUR9IzkJHo5Q"
                >
                  <i className="ion-social-pinterest"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <section className="uk-section tw-card-2">
          <div data-uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-small; delay: 300;">
            <div className="uk-flex-center uk-grid-medium" data-uk-grid>
              <div className="uk-width-12-12 uk-width-12-12@m uk-width-12-12@l">
                <div className="tw-element tw-mr-30-rev tw-ml-30-rev">
                  <div className="uk-card uk-card-default uk-card-large uk-card-body">
                    <iframe
                      title="wegis"
                      style={{ width: "100%", height: 800 }}
                      src={authUser ? config.GIS_AUTH_URL : config.GIS_NO_AUTH_URL}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer publicLang={true} />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};
export default connect(mapStateToProps)(PublicGIS);
