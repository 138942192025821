import enLang from "./entries/en-US";
import mnLang from "./entries/mn_MN";
import { addLocaleData } from "react-intl";

const AppLocale = {
  en: enLang,
  mn: mnLang
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.mn.data);

export default AppLocale;
