import * as config from "./config";
import axios from "axios";

function baseBodyFetch(url, request, successCallback, failCallback, rMethod) {
  const token = localStorage.getItem("user_token");
  let URL = config.config.BASE_URL + url;

  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${
      token ? token.toString() : null
    }`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }

  axios({
    method: "POST",
    url: URL,
    data: request
  })
    .then(response => {
      if (response.data.status_code === -100) {
        setTimeout(function() {
          // localStorage.clear();
          // window.location.href = "/";
        }, 2000);
      }

      if (response.data.status_code === 0) {
        SuccessResponse(response.data, successCallback, url);
      } else {
        errorJob(response.data, failCallback);
      }
    })
    .catch(error => {
      console.log(error);
      if (error.response) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }

        failCallback(url + " - " + error);
      }
    });
}

function SuccessResponse(data, successCallback, url) {
  if (url === "Auth/login") {
    successCallback(data.body);
  } else {
    if (JSON.parse(data.body) !== null) {
      successCallback(JSON.parse(data.body));
    } else {
      successCallback([]);
    }
  }
}

function errorJob(error, failCallback) {
  if (error.error_msg === "Authentication failed") {
    localStorage.clear();
    window.location = "/";
  }
  failCallback(error.error_msg);
}

//#region [ Auth ]
export function register(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    "Auth/register",
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function authConfirm(
  token,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Auth/confirm/${token}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function authSetpassword(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Auth/setpassword`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
export function tentativeListOrj(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposal/list/org`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function authForgetEmail(
  email,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Auth/forgot/${email}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function authLogin(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(`Auth/login`, request, successCallback, failCallback, rMethod);
  return "";
}

//#endregion

//#region [ PolicyGeneral ]

export function liststakeholdersByPolicy(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyGeneral/liststakeholders/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function deletedirection(
  id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyGeneral/deletedirection/${id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyListAll(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `PolicyGeneral/listall`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyListMy(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `PolicyGeneral/listmy`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyListTeam(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyGeneral/listteam`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyCreate(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    "PolicyGeneral/create",
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getPolicyType(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    "PolicyGeneral/getpolicytype",
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getpolicytypeannex(
  policy_type_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyGeneral/getpolicytypeannex/${policy_type_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getPolicy(id, request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `PolicyGeneral/get/${id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listdirection(
  id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyGeneral/listdirection/${id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function exportHistoryPolicyGeneral(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyGeneral/export/history`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function relatedresearchesPolicy(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyGeneral/get/relatedresearches/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function importExcelPolicyGeneral(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyGeneral/import/excel`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
//#endregion

//#region [ TentativeProposal ]

export function solveTentative(
  tentative_proposal_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposal/solve/get/${tentative_proposal_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
export function historyTentative(
  tentative_proposal_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposal/history/${tentative_proposal_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function sendTentative(
  tentative_proposal_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposal/send/get/${tentative_proposal_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function deleteTentative(
  tp_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposal/delete/${tp_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function availableListTP(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    "TentativeProposalPolicy/list/available",
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function myListTP(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    "TentativeProposalPolicy/list/my",
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function reportTP(
  report_number,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposalPolicy/report/${report_number}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getPolicyRegulation(
  object_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposalPolicy/getpolicyinfo/${object_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function ListTentative(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `TentativeProposal/tp/main/list`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
export function ListTentativeAdmin(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposal/list/admin`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getPolicyRegulationList(
  object_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposalPolicy/listregulations/${object_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function CreateTentative(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposal/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
export function CreateSendTentative(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposal/send/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function CreateRegulation(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `tentativeProposalPolicy/createregulation`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function solveTentativeProposal(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposal/solve`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getsolveTentativeProposal(
  tentative_proposal_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposal/solve/get/${tentative_proposal_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

//#region [ Reference ]

export function createReference(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Reference/createreference`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listpermissions(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Reference/listpermissions`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function updatePermission(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Reference/updatepermission`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

//#region [ Objective ]

export function getObjectiveList(
  policyID,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyObjective/list/${policyID}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getObjectiveListB(
  policyID,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyObjective/listb/${policyID}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function createObjective(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyObjective/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function createBObjective(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyObjective/createb`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function deleteObjective(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyObjective/delete/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

//#region [ AnnexOne ]

export function listobjective(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexOne/listobjective/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listactivepolicy(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexOne/listactivepolicy/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function CreatePolicyCoordination(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexOne/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function UpdateAnnex1Remark(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexOne/updateannexOneremark`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
//#endregion

//#region [ Annex Two ]
export function getPolicyAnnex2(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexTwo/get/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function createPolicyAnnex2(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexTwo/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function publicFind(
  request,
  successCallback,
  failCallback,
  rMethod,
  policy_id,

) {
  baseBodyFetch(
    `PublicWeb/policy/view/get/annextwo/getfinancialsource/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getfinancialsourceAnnex2(
  request,
  successCallback,
  failCallback,
  rMethod,
  policy_id
) {
  baseBodyFetch(
    `PolicyAnnexTwo/getfinancialsource/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
//#endregion

//#region [ AnnexThree ]
export function getconsultationListAnnexThree(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexThree/consultation/list/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getConsultationAnnexThree(
  consultation_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexThree/consultation/get/${consultation_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function createConsultationAnnexThree(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexThree/consultation/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function deleteConsultaionAnnexThree(
  consultation_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexThree/consultation/delete/${consultation_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

//#region [ AnnexFour ]

export function listResearch(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `PolicyAnnexFour/listnames`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function CreatePolicyAnnex4(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexFour/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function GetPolicyAnnex4(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexFour/get/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

//#region [ Annex 5 ]
export function getPolicyAnnex5(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexFive/get/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function createPolicyAnnex5(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexFive/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listresearchPolicyAnnex5(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexFive/listresearch`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
export function listorganizationPolicyAnnex5(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexFive/listorganization`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
export function listorganizationandgroupPolicyAnnex5(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexFive/listorganizationandgroup`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

//#region [ Annex 6 ]
export function getPolicyAnnex6(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexSix/get/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function createPolicyAnnex6(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexSix/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listorganizationandgroupPolicyAnnex6(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexSix/listorganizationandgroup`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
//#endregion

//#region [ Annex 7 ]
export function getPolicyAnnex7(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexSeven/get/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function fileupdatePolicyAnnex7(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexSeven/update/file`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function saveandsendPolicyAnnex7(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexSeven/saveandsend`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function createPolicyAnnex7(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexSeven/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getchildfilesAnnexSeven(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexSeven/getchildfiles/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function updateDateAnnexSeven(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexSeven/update/date`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

//#region [ Annex 8 ]
export function getPolicyAnnex8(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexEight/get/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function createPolicyAnnex8(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexEight/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
//#endregion

//#region [ Annex 9 ]
export function getfilesPolicyAnnexNine(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexNine/getfiles/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function updatefilesPolicyAnnexNine(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexNine/updatefiles`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function ObjectiveCoordination(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexNine/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function ObjectiveCoordinationCreate(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexNine/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function ObjectiveCoordinationExpernalPolicy(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexNine/external/listpolicyobjectives/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function ObjectiveCoordinationExpernal(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexNine/external/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function ObjectiveCoordinationExternalCreate(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexNine/external/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function ObjectiveCoordinationRank(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexNine/objectiverank/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

//#region [ Article ]

export function ListPolicyArticle(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `policyarticle/list/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function CreatePolicyArticle(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `policyarticle/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function UpdateArticle(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `policyarticle/updatearticle`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function GetArticle(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `policyarticle/getarticle/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
//#endregion

//#region [ PublicWeb ]

export function getgeneralinfo(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/getgeneralinfo`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listPolicyPublicWeb(
  policy_type_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/list/policy/${policy_type_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function typeCountPolicyPublicWeb(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policycount/type`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getReferenceListPublicWeb(
  kind,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/reference/getkind/${kind}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function publicViewPolicy(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/view/policy/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function publicViewResearch(
  research_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/view/research/${research_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function searchPublic(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `PublicWeb/search`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function Publicpolicycounttype(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Public/policycount/type`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function findResearcher(
  key,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Public/researchteam/find/${key}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listpoliciesPublic(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/listpolicies`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listpollingpoliciesPublic(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/listpollingobjects`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listjobsPublic(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/listjobs`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listpolicyPublic(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Public/listpolicy`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listresearchPublic(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Public/listresearch`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function myjobrequestsPublic(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Public/myjobrequests`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policycounttree(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdminDashboard/policycount/tree`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
//#endregion

//#region [ Research ]

export function ListResearch(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `Research/list`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function CreateResearch(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function GetResearch(
  research_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/${research_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function createResearchJob(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/job/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listResearchJob(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/job/list`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function requestJob(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `Research/requestjob`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function GetResearchJob(
  research_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/job/get/${research_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getResearchersResearch(
  research_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/getresearchers/${research_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getProfileResearch(
  stakeholder_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/profile/${stakeholder_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function createResearchteam(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/researchteam/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function setResearchers(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/setresearchers`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listResearcherResearch(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/researcher/list`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getResearchTeamResearch(
  research_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/researcherteam/get/${research_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
//#endregion

//#region [ Proposal ]

export function filterListObjectsbyAdmin(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/filter/listobjects/admin`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function filterListorganizationsbyAdmin(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/filter/listorganizations/admin`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function filterListObjectsbyOrg(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/filter/listobjects/org`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function filterListorganizationsbyOrg(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/filter/listorganizations/org`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function historyProposal(
  proposal_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/history/${proposal_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function sendProposal(
  proposal_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/send/get/${proposal_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listProposalbyOrg(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/list/org`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function solveProposal(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `Proposal/solve`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getSolveProposal(
  proposal_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/solve/get/${proposal_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getProposal(
  proposal_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/get/${proposal_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function CreateProposal(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function ListProposal(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `Proposal/list/admin`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function DeleteProposal(
  proposal_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/delete/${proposal_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function CreateProposalSent(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/send/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

//#region [ ProposalScope ]

export function updateScopeorganization(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `ProposalScope/updateorganization`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listobjectProposalScope(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `ProposalScope/listobject`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listorganizationsProposalScope(
  object_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `ProposalScope/listorganizations/${object_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
//#endregion

//#region [ ProposalConsultation ]

export function filterListobjects(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `ProposalConsultation/filter/listobjects`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function filterListorganizations(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `ProposalConsultation/filter/listorganizations`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getUserOrganizations(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `public/getuserorganizations`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listProposalConsultation(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `ProposalConsultation/list`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function CreateProposalConsultation(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `ProposalConsultation/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function DeleteProposalConsultation(
  consultation_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `ProposalConsultation/delete/${consultation_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getProposalConsultation(
  id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `ProposalConsultation/get/${id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
export function getTentativeInvestment(
  tp_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposal/get/${tp_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
//#endregion

//#region [ organization ]
export function ecoCatlist(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `public/ecocat/listbyparent`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
export function yamatList(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `Public/listyamat`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listbyregionOrganization(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `public/organization/listbyregion`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listOrganization(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `organization/list`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function CreateOrganization(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `organization/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function GetOrganization(
  org_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `organization/${org_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function CreateOrganizationPosition(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `organization/position/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function ListOrganizationPosition(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `organization/position/list`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function DeleteOrganizationPosition(
  id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `organization/position/delete/${id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
//#endregion

//#region [ Stakeholder ]
export function listexplicitpermissions(
  stakeholder_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Stakeholder/listexplicitpermissions/${stakeholder_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function updateexplicitpermission(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Stakeholder/updateexplicitpermission`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function deleteprivilege(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Stakeholder/deleteprivilege`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function updateGroupByStakeholder(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Stakeholder/updategroup`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getgroups(
  stakeholder_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Stakeholder/getgroups/${stakeholder_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listallgroupsByStakeholder(
  stakeholder_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Stakeholder/listgroups/${stakeholder_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listallpermissions(
  stakeholder_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Stakeholder/listallpermissions/${stakeholder_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function updatepermissionByStakeholder(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Stakeholder/updatepermission`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listpermissionsByStakeholder(
  stakeholder_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Stakeholder/listpermissions/${stakeholder_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listbuckets(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `Stakeholder/listbuckets`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getFilesOrgPos(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Stakeholder/getfiles`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function downloadFile(
  file_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Stakeholder/download/${file_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function deleteFilesOrgPos(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Stakeholder/deletefile`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function CreateStakeholder(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `stakeholder/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listorganizationposition(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `stakeholder/list/organizationposition`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function GetStakeholder(
  stakeholder_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `stakeholder/${stakeholder_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function GetProfileStakeholder(
  stakeholder_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Stakeholder/getprofile/${stakeholder_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function UpdateProfile(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `Stakeholder/updateprofile`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function UpdatePassword(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Stakeholder/updatepassword`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function ListStakeholder(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `stakeholder/list`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
//#endregion

//#region [ Region ]

export function updateRegion(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `Region/update`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listregions(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `PublicWeb/listregions`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listaimagsRegion(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `public/listaimags`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listsumsRegion(
  aimag_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `public/listsums/${aimag_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

//#region [ RolePermission ]
export function createRolePermission(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `RolePermission/createpermissions`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getRolePermissions(
  role_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `RolePermission/getpermissions/${role_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getRoleGroupPermissions(
  role_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `RolePermission/getgroups/${role_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function creategroupsRolePermission(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `RolePermission/creategroups`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

//#region [ PolicyAnnex ]
export function createPolicyAnnexPolicystatus(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnex/policystatus/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listPolicyAnnex(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnex/list`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

//#region [ PolicyStatusShift ]
export function getPolicyNextStatus(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyStatusShift/get/next_policy_status_ids`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getPolicyStatus(
  policy_type_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyStatusShift/get/policy_status/${policy_type_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function createPolicyStatus(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyStatusShift/policystatusshift/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function updatePolicyStatus(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyStatusShift/policystatusshift/update`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listPolicyStatus(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyStatusShift/list`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

//#region [ Dashboard ]
export function policycountType(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdminDashboard/policycount/type`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policycountDuration(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdminDashboard/policycount/duration`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policycountStatus(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdminDashboard/policycount/status`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policycountActivity(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdminDashboard/policycount/activity`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function researchcountType(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdminDashboard/researchcount/type`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function usercountType(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `AdminDashboard/usercount/type`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function financialActivityAdminDashboard(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdminDashboard/activity/financial`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function indexLDIAdminDashboard(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdminDashboard/ldi/index`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function ecocatResearchAdminDashboard(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdminDashboard/research/ecocat`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getgeneralAdminDashboard(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdminDashboard/general/get`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

//#region [ Permission Group ]
export function updatePermissionByGroup(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Group/updatepermission`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getPermissionByGroup(
  group_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Group/listpermission/${group_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listGroup(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(`Group/list`, request, successCallback, failCallback, rMethod);
  return "";
}

export function createGroup(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `Group/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function deleteGroup(
  group_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Group/delete/${group_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
//#endregion

// #region [ Chat ]

export function removeFromGroupChat(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Chat/removeFromGroupChat`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function addUsersToGroupChat(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Chat/addUsersToGroupChat`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function chatAllUserList(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `chat/user/list`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getChatList(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `chat/getChatList`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function createChat(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `chat/createChat`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getChatHistory(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `chat/getChatHistory`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function createGroupChat(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `chat/createGroupChat`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

// #endregion

//#region [ notification/get ]
export function sendmessageNotification(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Notification/sendmessage`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getNotification(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `notification/get`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function viewNotification(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `notification/view`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

export function getReferenceList(
  kind,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Public/reference/getkind/${kind}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyviewCountPublicWeb(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/count`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewListPublicWeb(
  policy_type_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/list/${policy_type_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewObjectivePublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/listobjective/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewGetArticlePublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/getarticle/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewGetArticleListPublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/getarticle/list/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewGetDirectionsPublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/listdirection/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewAnnexOnePublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/get/annexone/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewAnnexTwoPublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/get/annextwo/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewAnnexThreePublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/get/annexthree/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewAnnexFourPublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/get/annexfour/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewAnnexFivePublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/get/annexfive/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewAnnexSixPublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/get/annexsix/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewAnnexSevenPublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/get/annexseven/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewAnnexEighPublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/get/annexeight/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewAnnexNinePublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/get/annexnine/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewAnnexNineExternalPublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/get/annexnine/external/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewAnnexNineExternalObjectivePublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/get/annexnine/external/listpolicyobjectives/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyViewAnnexNineObjectiverankPublicWeb(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PublicWeb/policy/view/get/annexnine/objectiverank/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyTPgetScope(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyTp/gettpscope/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyTPUpdateScope(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyTp/updatetpscope`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function stakeholderlistregions(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `stakeholder/listregions`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function gettpfundingminister(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `public/reference/gettpfundingminister`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function updatejobcv(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(
    `Research/updatejobcv`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function deleteResearch(
  research_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/delete/${research_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getCommentResearch(
  research_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `ResearchComment/getcomment/${research_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function createCommentResearch(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `ResearchComment/create`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function stakeholderFilterResearch(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/filter/stakeholder`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function organizationFilterResearch(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/filter/organization`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyFilterResearch(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/filter/policy`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
export function teammemberFilterResearch(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Research/filter/teammember`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function deletePolicy(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyGeneral/delete/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function tpListTentativeProposalPolicy(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposalPolicy/list/tp`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getchildfilesTentativeProposalPolicy(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposalPolicy/getchildfiles/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function saveandsendTentativeProposalPolicy(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposalPolicy/saveandsend/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function updateFileTentativeProposalPolicy(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposalPolicy/update/file`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function initGetTentativeProposal(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposal/init/get/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function tpUpdateTentativeProposalPolicy(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposalPolicy/update/tp`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function updateTentativeProposalPolicy(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `TentativeProposal/update`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listaPolicyTp(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyTp/lista/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function updatePolicyTp(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyTp/update`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function finalsendPolicyTp(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyTp/finalsend/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function updateFilePolicyTp(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyTp/update/file`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function updateTpPolicyTp(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyTp/update/tp`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getchildfilesTpPolicyTp(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyTp/getchildfiles/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listbPolicyTp(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyTp/listb/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function availableListProposal(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/list/available`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function myListProposal(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/list/my`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function proposalsListProposal(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/list/proposals`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getAnnexProposal(
  object_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/get/annex/${object_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listproposalPolicyAnnexThree(
  consultation_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexThree/listproposal/${consultation_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function deleteConsultationPolicyAnnexThree(
  consultation_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexThree/consultation/delete/${consultation_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function solvePolicyAnnexThree(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexThree/solve`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function listpolicyPolicyRelation(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyRelation/listpolicy`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getChartPolicyRelation(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyRelation/get/chart/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getMorePolicyRelation(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyRelation/get/more`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getGIS(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(`Gis/get`, request, successCallback, failCallback, rMethod);
  return "";
}

export function createGIS(request, successCallback, failCallback, rMethod) {
  baseBodyFetch(`Gis/create`, request, successCallback, failCallback, rMethod);
  return "";
}

export function saveandsendProposal(
  consultation_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/saveandsend/${consultation_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function fileUpdateProposal(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `Proposal/update/file`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function getchildfilesPolicyAnnexThree(
  consultation_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `PolicyAnnexThree/getchildfiles/${consultation_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#region [ AdvancedDashboard ]
export function infoGeneralAdvancedDashboard(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdvancedDashboard/general/info`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function infoEcoCatAdvancedDashboard(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdvancedDashboard/ecocat/info`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function policyRelationAdvancedDashboard(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdvancedDashboard/relation/policy`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function statusPolicycountAdvancedDashboard(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdvancedDashboard/policycount/status`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function financialAdvancedDashboard(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdvancedDashboard/activity/financial`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function typeResearchCountAdvancedDashboard(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdvancedDashboard/researchcount/type`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function relationObjectiveAdvancedDashboard(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdvancedDashboard/relation/objective/one`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function infoMapAdvancedDashboard(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdvancedDashboard/map/info`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function ListpoliciesAdvancedDashboard(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdvancedDashboard/relation/listpolicies`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function objectiveRelationAdvancedDashboard(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdvancedDashboard/relation/objective/one/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

export function twoobjectiveRelationAdvancedDashboard(
  policy_id,
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdvancedDashboard/relation/objective/two/${policy_id}`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}

//#endregion

export function myGeneralAdminDashboard(
  request,
  successCallback,
  failCallback,
  rMethod
) {
  baseBodyFetch(
    `AdminDashboard/general/my`,
    request,
    successCallback,
    failCallback,
    rMethod
  );
  return "";
}
