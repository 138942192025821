exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_title__20djN {\n  text-align: center;\n  margin: 20px 0px;\n  font-size: 17px;\n  font-weight: 500; }\n\n.style_tg__3zPeJ {\n  border-collapse: collapse;\n  border-spacing: 0;\n  width: 100%;\n  font-size: 14px;\n  -webkit-font-feature-settings: \"tnum\";\n          font-feature-settings: \"tnum\";\n  font-variant: tabular-nums;\n  line-height: 1.3;\n  list-style: none;\n  border: 1px solid #e8e8e8;\n  border-radius: 6px 6px 0 0;\n  border-collapse: separate;\n  display: block;\n  table-layout: fixed;\n  overflow-x: auto;\n  overflow: auto; }\n\n.style_tg__3zPeJ tbody {\n  overflow: auto;\n  height: 100px; }\n\n.style_tg__3zPeJ td {\n  font-size: 14px;\n  padding: 10px 5px;\n  border-style: solid;\n  border-width: 1px;\n  overflow: hidden;\n  word-break: normal;\n  border: 1px solid #e8e8e8;\n  align-items: center; }\n\n.style_tg__3zPeJ > tr > th {\n  color: #535353;\n  font-weight: bold;\n  text-align: left; }\n\n.style_tg__3zPeJ th {\n  font-size: 14px;\n  font-weight: normal;\n  padding: 10px 5px;\n  border-style: solid;\n  border-width: 1px;\n  overflow: hidden;\n  word-break: normal;\n  color: #535353;\n  text-align: left;\n  background: #fafafa;\n  border: 1px solid #e8e8e8; }\n\n.style_tg__3zPeJ .style_tgc3ow__1OyGZ {\n  border-color: inherit;\n  text-align: center;\n  vertical-align: middle; }\n\n.style_tg__3zPeJ .style_tg0pky__P6IM8 {\n  text-align: center;\n  vertical-align: middle; }\n\n.style_tg__3zPeJ .style_tg0lax__vpX0m {\n  text-align: left;\n  vertical-align: middle; }\n", ""]);

// exports
exports.locals = {
	"title": "style_title__20djN",
	"tg": "style_tg__3zPeJ",
	"tgc3ow": "style_tgc3ow__1OyGZ",
	"tg0pky": "style_tg0pky__P6IM8",
	"tg0lax": "style_tg0lax__vpX0m"
};