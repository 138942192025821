import React, { Component, Fragment } from "react";
import { Row, Form, Spin, Col, Card, Tag, Icon, Button, message } from "antd";
import IntlMessages from "util/IntlMessages";
import { GetResearch, publicViewResearch } from "../../../restAPI/main";
import { documentDefinition } from "../../../restAPI/request";
import { userData } from "util/helper";
import styles from "./style.module.scss";
import moment from "moment";
import TableToExcel from "@linways/table-to-excel";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class GeneralInformation extends Component {
  state = {
    research: null,
    loading: true
  };
  componentWillMount() {
    const { match, searchResearch } = this.props;
    if (match) {
      GetResearch(match.params.id, "", this.onSuccess, this.onFailed, "POST");
    }

    if (searchResearch) {
      publicViewResearch(
        searchResearch,
        "",
        this.onSuccess,
        this.onFailed,
        "POST"
      );
    }
  }

  onSuccess = response => {
    this.setState({
      research: response,
      loading: false
    });
  };

  onFailed = err => {
    let emptyResearch = {
      keywords: [],
      research_objectives: [],
      policy_researches: [],
      research_ecocats: [],
      research_regions: [],
      related_researches: [],
      research_nomzuis: [],
      initial_req_files: []
    };
    message.error(err);
    this.setState({
      research: emptyResearch,
      loading: false
    });
  };

  getTypeName = research_type => {
    if (research_type === 1) {
      return <IntlMessages id="policy" />;
    } else if (research_type === 2) {
      return <IntlMessages id="research.techno_echonomic_preanalysis" />;
    } else if (research_type === 3) {
      return <IntlMessages id="research.feasibility_study" />;
    }
  };

  getStatusJob = record => {
    if (!record.is_active) {
      return (
        <Tag color="red">
          <IntlMessages id="research.deactive" />
        </Tag>
      );
    } else {
      if (moment(record.request_deadline) > moment()) {
        return (
          <Tag color="#2db7f5">
            <IntlMessages id="research.statusNew" />
          </Tag>
        );
      } else {
        if (record.chosen_requests_count === 0) {
          return (
            <Tag color="#87d068">
              <IntlMessages id="research.requestOn" />
            </Tag>
          );
        } else {
          if (!record.is_completed) {
            return (
              <Tag color="#108ee9">
                <IntlMessages id="research.ongoing" />
              </Tag>
            );
          } else {
            if (!record.is_accepted) {
              return (
                <Tag color="#2db7f5">
                  <IntlMessages id="research.finishedAndResult" />
                </Tag>
              );
            } else {
              return (
                <Tag color="#2db7f5">
                  <IntlMessages id="research.done" />
                </Tag>
              );
            }
          }
        }
      }
    }
  };

  getStatusJobSimpleUser = record => {
    if (moment(record.request_deadline) < moment()) {
      return (
        <Tag color="#f50">
          <IntlMessages id="research.request_dateover" />
        </Tag>
      );
    } else {
      return (
        <Tag color="#2db7f5">
          <IntlMessages id="research.statusNew" />
        </Tag>
      );
    }
  };

  creatEXLSd = () => {
    const { research } = this.state;
    const table1 = document.getElementById("research-detail");

    const book = TableToExcel.tableToBook(table1, {
      sheet: { name: "Sheet 1" }
    });

    TableToExcel.save(book, `${research.name}.xlsx`);
  };

  downloadPDF = () => {
    const { research } = this.state;
    const obj = [];
    const policy_researches = [];
    const research_ecocats = [];
    const research_regions = [];
    const related_researches = [];
    const research_nomzuis = [];
    const initial_req_files = [];

    research.research_objectives.map(u => {
      obj.push(u.objective);
      return null;
    });

    research.policy_researches.map(u => {
      policy_researches.push(u.policy_name);
      return null;
    });

    research.research_ecocats.map(u => {
      research_ecocats.push(u.ecocat_name);
      return null;
    });

    research.research_regions.map(u => {
      research_regions.push(u.full_region_name);
      return null;
    });

    research.related_researches.map(u => {
      related_researches.push(u.related_research_name);
      return null;
    });

    research.research_nomzuis.map(u => {
      research_nomzuis.push(u.context);
      return null;
    });

    research.initial_req_files.map(u => {
      initial_req_files.push(u.filename);
      return null;
    });

    documentDefinition.styles.researchDetail = {
      alignment: "center",
      bold: true,
      fontSize: 14,
      margin: [0, 10, 0, 10]
    };
    documentDefinition.pageOrientation = "portrait";
    documentDefinition.content = [
      {
        text: research.name,
        style: "researchDetail"
      },
      {
        table: {
          body: [
            ["Судалгааны төрөл:", research.research_type_name],
            ["Төлөв:", research.status_number],
            ["Идэвхитэй эсэх:", research.is_active ? "Тийм" : "Үгүй"],
            ["Хүлээж авсан эсэх:", research.is_accepted ? "Тийм" : "Үгүй"],
            [
              "Хүлээж авсан огноо:",
              research.accepted_date !== "0001-01-01"
                ? research.accepted_date
                : "-"
            ],
            ["Дууссан эсэх:", research.is_completed ? "Тийм" : "Үгүй"],

            [
              "Хүсэлт хүлээж авах эцсийн хугацаа:",
              research.request_deadline !== "0001-01-01"
                ? research.request_deadline
                : "-"
            ],
            [
              "Судалгааг хүлээж авах эцсийн хугацаа:",
              research.research_deadline !== "0001-01-01"
                ? research.research_deadline
                : "-"
            ],
            ["Санхүүгийн эх үүсвэр:", research.financial_source_name],
            ["Нийт санхүүжилт (сая төгрөг):", research.budget],
            [
              "Түлхүүр үгс:",
              {
                ul: research.keywords
              }
            ],
            ["Үндэслэл:", research.rationale || "-"],
            ["Зорилго:", research.goal || "-"],

            [
              "Зорилт:",
              {
                ul: obj
              }
            ],
            [
              "Холбоотой бодлого:",
              {
                ul: policy_researches
              }
            ],
            [
              "Бүс:",
              {
                ul: research_regions
              }
            ],
            [
              "Холбоотой судалгаа:",
              {
                ul: related_researches
              }
            ],
            [
              "Холбоотой салбар:",
              {
                ul: research_ecocats
              }
            ],
            ["Хураангуй:", research.abstract || "-"],
            ["Үр дүн:", research.result || "-"],
            [
              "Ном зүй:",
              {
                ul: research_nomzuis
              }
            ],
            [
              "Нэмэлт хавсралт:",
              {
                ul: initial_req_files
              }
            ]
          ]
        }
      }
    ];
    documentDefinition.watermark.text = "www.development.gov.mn"
    pdfMake.createPdf(documentDefinition).download(`${research.name}.pdf`);
  };

  render() {
    const { searchResearch } = this.props;
    const { research, loading } = this.state;
    const { type } = userData();

    const mainElement = (
      <Fragment>
        {loading ? (
          <Spin />
        ) : (
          <table
            data-cols-width="30, 100"
            className={styles.tg}
            id="research-detail"
          >
            <thead>
              <tr>
                <th
                  data-a-h="center"
                  data-a-v="middle"
                  data-f-bold={true}
                  data-a-wrap={true}
                  colSpan="2"
                  data-f-sz="15"
                  className="text-center"
                >
                  <span
                    className="text-center font-weight-bold"
                    style={{ fontSize: 30 }}
                  >
                    {research.name}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ height: 50, width: 300 }} className="mb-3">
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  style={{ width: 300 }}
                  className="font-weight-bold"
                >
                  {" "}
                  <IntlMessages id="research.type" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  {this.getTypeName(research.research_type)}
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-f-bold={true}
                  data-a-h="left"
                  data-a-v="middle"
                  className="font-weight-bold"
                >
                  {" "}
                  <IntlMessages id="Status" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  {type === "99"
                    ? this.getStatusJob(research)
                    : this.getStatusJobSimpleUser(research)}
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  {" "}
                  <IntlMessages id="research.is_active" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  <Tag color={research.is_active ? "#87d068" : "#f50"}>
                    {research.is_active ? (
                      <IntlMessages id="yes" />
                    ) : (
                      <IntlMessages id="no" />
                    )}
                  </Tag>
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  {" "}
                  <IntlMessages id="research.is_accepted" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  <Tag color={research.is_accepted ? "#87d068" : "#f50"}>
                    {research.is_accepted ? (
                      <IntlMessages id="yes" />
                    ) : (
                      <IntlMessages id="no" />
                    )}
                  </Tag>
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  {" "}
                  <IntlMessages id="research.accepted_date" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  {research.accepted_date !== "0001-01-01"
                    ? research.accepted_date
                    : "-"}
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  {" "}
                  <IntlMessages id="research.is_completed" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  <Tag color={research.is_completed ? "#87d068" : "#f50"}>
                    {research.is_completed ? (
                      <IntlMessages id="yes" />
                    ) : (
                      <IntlMessages id="no" />
                    )}
                  </Tag>
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  {" "}
                  <IntlMessages id="research.request_deadline" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  {research.request_deadline !== "0001-01-01"
                    ? research.request_deadline
                    : "-"}
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  {" "}
                  <IntlMessages id="research.research_deadline" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  {research.research_deadline !== "0001-01-01"
                    ? research.research_deadline
                    : "-"}
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  className="font-weight-bold"
                  data-f-bold={true}
                >
                  {" "}
                  <IntlMessages id="policy.financial_source" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  {research.financial_source_name}
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  {" "}
                  <IntlMessages id="policy.total_funding_tugrug" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  {research.budget}
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  {" "}
                  <IntlMessages id="research.keywords" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  {" "}
                  {research.keywords.length === 0
                    ? "-"
                    : research.keywords.map(eachKeywork => {
                        return <Tag key={eachKeywork}>{eachKeywork}</Tag>;
                      })}
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  {" "}
                  <IntlMessages id="research.retional" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  {" "}
                  {research.rationale || "-"}
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  {" "}
                  <IntlMessages id="goal" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  {" "}
                  {research.goal || "-"}
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  {" "}
                  <IntlMessages id="research.objective" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  {" "}
                  <ul>
                    {research.research_objectives.length !== 0
                      ? research.research_objectives.map(eachObject => {
                          return (
                            <li key={eachObject.id}>
                              {eachObject.objective || eachObject.objective_en}
                            </li>
                          );
                        })
                      : "-"}
                  </ul>
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  <IntlMessages id="research.related_Policy" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  <ul>
                    {research.policy_researches.length !== 0
                      ? research.policy_researches.map(eachPolicy => {
                          return (
                            <li key={eachPolicy.id}>
                              {eachPolicy.policy_name}
                            </li>
                          );
                        })
                      : "-"}
                  </ul>
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  <IntlMessages id="research.related_sector" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  <ul>
                    {research.research_ecocats.length !== 0
                      ? research.research_ecocats.map(eachoEco => {
                          return (
                            <li key={eachoEco.id}>{eachoEco.ecocat_name}</li>
                          );
                        })
                      : "-"}
                  </ul>
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  <IntlMessages id="sidebar.region" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  <ul>
                    {research.research_regions.length !== 0
                      ? research.research_regions.map(region => {
                          return (
                            <li key={region.id}>{region.full_region_name}</li>
                          );
                        })
                      : "-"}
                  </ul>
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  <IntlMessages id="research.related_research" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  <ul>
                    {research.related_researches.length !== 0
                      ? research.related_researches.map(research => {
                          return (
                            <li key={research.id}>
                              {research.related_research_name}
                            </li>
                          );
                        })
                      : "-"}
                  </ul>
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  <IntlMessages id="research.abstract" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  {research.abstract || "-"}
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  <IntlMessages id="research.result" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  {research.result || "-"}
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  Ном зүй:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  {research.research_nomzuis.length !== 0
                    ? research.research_nomzuis.map((nomzui, nomzuiIndex) => {
                        return (
                          <span>
                            <span style={{ fontSize: 14 }}>
                              {nomzuiIndex + 1}
                            </span>
                            . {nomzui.context} <br />
                          </span>
                        );
                      })
                    : "-"}
                </td>
              </tr>

              <tr style={{ height: 50 }}>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                  data-f-bold={true}
                  className="font-weight-bold"
                >
                  <IntlMessages id="policy.additional_annex" />:
                </td>
                <td
                  data-b-a-s="thin"
                  data-a-wrap={true}
                  data-a-h="left"
                  data-a-v="middle"
                >
                  <ul>
                    {research.initial_req_files.map((file, index) => {
                      return (
                        <li key={file.id}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={file.filepath}
                          >
                            {file.filename || `Файл ${index + 1}`}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </Fragment>
    );

    return searchResearch ? (
      mainElement
    ) : (
      <Card className="gx-card" title="Судалгааны дэлгэрэнгүй">
        {mainElement}
        {!searchResearch ? (
          <Row>
            <Col span={24} className="text-right mt-3">
              <Button type="dashed" onClick={() => this.props.history.goBack()}>
                <Icon type="arrow-left" />
                <IntlMessages id="back" />
              </Button>{" "}
              <Button type="dashed" onClick={() => this.creatEXLSd()}>
                <Icon
                  type="file-excel"
                  theme="twoTone"
                  twoToneColor="#008000"
                />
                EXCEL
              </Button>{" "}
              <Button type="dashed" onClick={() => this.downloadPDF()}>
                <Icon type="file-pdf" theme="twoTone" twoToneColor="#ff0000" />
                PDF
              </Button>{" "}
              {/* <Button type="dashed">
                <Icon type="file-text" />
                Word
              </Button>{" "} */}
            </Col>
          </Row>
        ) : null}
      </Card>
    );
  }
}

export default Form.create()(GeneralInformation);
