import React, { Component } from "react";
import { connect } from "react-redux";
import URLSearchParams from "url-search-params";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import RegisterConfirm from "../RegisterConfirm";
import ForgetPassword from "../ForgetPassword";
import SetPassword from "../SetPassword";
import PublicWeb from "../PublicWeb";
import PublicPolicyDetail from "../PublicPolicyDetail";
import PublicResearchDetail from "../PublicResearchDetail";
import PublicPolicy from "../PublicPolicy";
import PublicTask from "../PublicTask";
import PublicSystem from "../PublicSystem";
import PublicGIS from "../PublicGIS";

import { setInitUrl } from "appRedux/actions/Auth";
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType
} from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class App extends Component {
  setLayoutType = layoutType => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove("full-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("full-layout");
      document.body.classList.add("framed-layout");
    }
  };

  setNavStyle = navStyle => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add("full-scroll");
      document.body.classList.add("horizontal-layout");
    } else {
      document.body.classList.remove("full-scroll");
      document.body.classList.remove("horizontal-layout");
    }
  };

  componentWillMount() {
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);

    if (params.has("theme")) {
      this.props.setThemeType(params.get("theme"));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get("nav-style"));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get("layout-type"));
    }
  }

  render() {
    const {
      match,
      location,
      themeType,
      layoutType,
      navStyle,
      locale,
      authUser,
      initURL
    } = this.props;

    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add("dark-theme");
    }

    if (location.pathname === "/") {
      if (authUser === null) {
        return <Redirect to={"/home"} />;
      } else if (initURL === "" || initURL === "/" || initURL === "/home") {
        return <Redirect to={"/dashboard"} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }

    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <Switch>
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/home" component={PublicWeb} />
            <Route exact path="/task" component={PublicTask} />
            <Route exact path="/detail" component={PublicPolicyDetail} />
            <Route exact path="/research" component={PublicResearchDetail} />
            <Route exact path="/gis" component={PublicGIS} />
            <Route exact path="/policy" component={PublicPolicy} />
            <Route exact path="/system" component={PublicSystem} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/auth/confirm/:id" component={RegisterConfirm} />
            <Route exact path="/auth/forgot/:id" component={SetPassword} />
            <Route
              exact
              path="/auth/forget_password"
              component={ForgetPassword}
            />

            <RestrictedRoute
              path={`${match.url}`}
              authUser={authUser}
              component={MainApp}
            />
          </Switch>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale, navStyle, themeType, layoutType } = settings;
  const { authUser, initURL } = auth;
  return { locale, navStyle, themeType, layoutType, authUser, initURL };
};
export default connect(
  mapStateToProps,
  { setInitUrl, setThemeType, onNavStyleChange, onLayoutTypeChange }
)(App);
