import React, { Component } from "react";
import { Form, Table, message } from "antd";
import IntlMessages from "util/IntlMessages";
import ShowMoreText from "react-show-more-text";
import { policyViewAnnexNineObjectiverankPublicWeb } from "../../../../restAPI/main";

class Final extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finalRank: [],
      loading: true
    };
  }

  componentWillMount() {
    const { policy } = this.props;
    policyViewAnnexNineObjectiverankPublicWeb(
      policy.id,
      "",
      this.onSuccess,
      this.onFailed,
      "POST"
    );
  }

  onFailed = err => {
    message.error(err);
  };

  onSuccess = response => {
    this.setState({
      finalRank: response,
      loading: false
    });
  };

  render() {
    const { finalRank, loading } = this.state;

    const columns = [
      {
        title: <IntlMessages id="annex9.Objectives_of_processing_policy" />,
        dataIndex: "objective",
        width: 800,
        render: value => (
          <ShowMoreText
            lines={3}
            more="Дэлгэрэнгүй"
            less="Хураах"
            anchorClass=""
            expanded={false}
            className="text-justify"
          >
            {value}
          </ShowMoreText>
        )
      },
      {
        title: "Бодлогын зорилтуудын хоорондын нөлөөлөл (Хүснэгт 1)",
        children: [
          {
            title: "Дүн",
            dataIndex: "inner_total"
          },
          {
            title: "Эрэмбэ",
            dataIndex: "inner_rank"
          }
        ]
      },
      {
        title:
          "Бусад бодлогын баримт бичгийн зорилтуудын хэрэгжилтэд үзүүлэх нөлөөлөл (Хүснэгт 2)",
        children: [
          {
            title: "Дүн",
            dataIndex: "outer_total"
          },
          {
            title: "Эрэмбэ",
            dataIndex: "outer_rank"
          }
        ]
      },
      {
        title: "Нэгдсэн эрэмбэ",
        dataIndex: "rank"
      }
    ];

    return (
      <Table
        scroll={{ x: "max-content" }}
        className="mb-4"
        bordered
        rowKey="id"
        loading={loading}
        dataSource={finalRank}
        columns={columns}
        pagination={false}
      />
    );
  }
}

export default Form.create()(Final);
