// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const CHANGE_WINDOWS = "CHANGE_WINDOWS";
export const CHANGE_APIS = "CHANGE_APIS";
// Common const
export const SET_PRIVILEGES = "SET_PRIVILEGES";
