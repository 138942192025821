import React, { Component } from "react";
import { Layout, Popover, message, Tooltip } from "antd";
import { connect } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import { checkWindow } from "util/helper";

import languageData from "../languageData";
import UserInfo from "components/UserInfo";
import HorizontalNav from "../HorizontalNav";
import { Link } from "react-router-dom";
import {
  switchLanguage,
  toggleCollapsedSideNav
} from "../../../appRedux/actions/Setting";
import AppNotification from "components/AppNotification";
import AppGariinAvlaga from "components/AppGariinAvlaga";
import { getNotification, getReferenceList } from "../../../restAPI/main";
import HeaderImage from "../../../assets/images/header.jpg";
import { userData } from "util/helper";

const { Header } = Layout;

class InsideHeader extends Component {
  state = {
    searchText: "",
    notification: [],
    chatsCount: 0,
    gariin_avlaga: []
  };

  componentWillMount() {
    this.fetchNotifList();
    let isShowed = localStorage.getItem("isShowed");
    let msg = localStorage.getItem("msg");

    if (isShowed === "false" && msg !== "null") {
      localStorage.setItem("isShowed", true);
      message.warn(msg, 4);
    }

    getReferenceList(28, "", this.onSuccessList, this.onFailed);
  }

  onSuccessList = response => {
    let gariin_avlaga = [];
    response.map((i, index) => {
      if (index > 2) {
        gariin_avlaga.push(i);
      }
      return null;
    });
    this.setState({ gariin_avlaga });
  };

  fetchNotifList = () => {
    getNotification("", this.onSuccess, this.onFailed);
  };

  onSuccess = response => {
    const notification = [];
    const chatsCount = [];
    response.map(eachNotif => {
      if (eachNotif.notification_type_id !== 0) {
        notification.push(eachNotif);
      }

      if (eachNotif.notification_type_id === 9) {
        chatsCount.push(eachNotif);
      }
      return "";
    });
    this.setState({
      notification,
      chatsCount
    });
  };

  onFailed = err => {
    message.error(err);
  };

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover" id="translation-links">
        {languageData.map(language => (
          <li
            data-lang={language.languageId}
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            // onClick={e => this.props.switchLanguage(language)}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  updateSearchChatUser = evt => {
    this.setState({
      searchText: evt.target.value
    });
  };

  getCount = array => {
    let count = 0;
    array.map(i => {
      if (!i.is_viewed) {
        count += 1;
      }
      return "";
    });
    return count;
  };

  render() {
    const { navCollapsed } = this.props;
    const { notification, gariin_avlaga } = this.state;

    return (
      <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">
        <Header
          className="gx-header-horizontal-main primaryColor"
          style={{ backgroundImage: `url(${HeaderImage})` }}
        >
          <div className="gx-container" style={{ width: "100%" }}>
            <div className="gx-header-horizontal-main-flex">
              <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
                <i
                  className="gx-icon-btn icon icon-menu"
                  onClick={() => {
                    this.props.toggleCollapsedSideNav(!navCollapsed);
                  }}
                />
              </div>
              <a
                href="/home"
                className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
              >
                <img alt="" width={200} src={require("assets/images/a.png")} />
              </a>
              <a
                href="/home"
                className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
              >
                <img alt="" width={300} src={require("assets/images/a.png")} />
              </a>

              <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
                <HorizontalNav />
              </div>
              <ul className="gx-header-notifications gx-ml-auto">
                <li className="gx-notify">
                  <Tooltip title="Видео гарын авлага">
                    <a
                      href={userData().manual_video_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#fa8c15" }}
                    >
                      <span className="gx-pointer gx-status-pos gx-d-block">
                        <i className="icon icon-feedback" />
                      </span>
                    </a>
                  </Tooltip>
                  {/* </Popover> */}
                </li>

                <li className="gx-notify">
                  <Tooltip title="Системийн гарын авлагууд">
                    <Popover
                      overlayClassName="gx-popover-horizantal"
                      placement="bottomRight"
                      content={
                        <AppGariinAvlaga gariin_avlaga={gariin_avlaga} />
                      }
                      trigger="click"
                    >
                      <span className="gx-pointer gx-status-pos gx-d-block">
                        <i className="icon icon-files" />
                      </span>
                    </Popover>
                  </Tooltip>
                </li>

                <li className="gx-notify">
                  <Tooltip title="Мэдэгдэл">
                    <Popover
                      overlayClassName="gx-popover-horizantal"
                      placement="bottomRight"
                      content={
                        <AppNotification
                          fetchNotifList={this.fetchNotifList}
                          notifications={notification}
                        />
                      }
                      trigger="click"
                    >
                      <span className="gx-pointer gx-status-pos gx-d-block">
                        <i className="icon icon-notification" />
                        {this.getCount(notification) === 0 ? (
                          ""
                        ) : (
                          <span
                            style={{
                              backgroundColor: "red",
                              position: "absolute",
                              top: -13,
                              color: "white",
                              fontSize: 16,
                              padding: 3,
                              borderRadius: 7,
                              left: 11
                            }}
                          >
                            {this.getCount(notification)}
                          </span>
                        )}
                      </span>
                    </Popover>
                  </Tooltip>
                </li>
                {checkWindow("/eoffice/chat") ? (
                  <Tooltip title="Чат">
                    <li className="gx-msg">
                      <Link style={{ color: "#ea8c35" }} to="/eoffice/chat">
                        <span className="gx-pointer gx-status-pos gx-d-block">
                          <i className="icon icon-chat-new" />
                          <span className="gx-status gx-status-rtl gx-small gx-orange" />
                        </span>
                      </Link>
                    </li>
                  </Tooltip>
                ) : null}

                <li className="gx-language">
                  <div id="google_translate_element"></div>
                </li>

                <li className="gx-user-nav">
                  <UserInfo />
                </li>
              </ul>
            </div>
          </div>
        </Header>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale, navCollapsed } = settings;
  return { locale, navCollapsed };
};
export default connect(mapStateToProps, {
  toggleCollapsedSideNav,
  switchLanguage
})(InsideHeader);
