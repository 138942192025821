exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_inputFocus__Oc0O8:focus {\n  height: 200px; }\n\n.style_title__JaJE6 {\n  text-align: center;\n  margin: 20px 0px;\n  font-size: 17px;\n  font-weight: 500;\n  padding: 0 20%; }\n\n.style_tg__3f7r9 {\n  border-collapse: collapse;\n  border-spacing: 0;\n  width: 100%;\n  font-size: 14px;\n  line-height: 1.3;\n  list-style: none;\n  border: 1px solid #e8e8e8;\n  border-radius: 6px 6px 0 0;\n  border-collapse: separate;\n  display: block;\n  table-layout: fixed;\n  overflow-x: auto;\n  overflow: auto; }\n\n.style_policyTeam__35vLu .style_ant-card-body__2gYC2 {\n  padding: 8px !important; }\n\n.style_tg__3f7r9 tbody {\n  overflow: auto;\n  height: 100px; }\n\n.style_tg__3f7r9 td {\n  font-size: 14px;\n  padding: 10px 5px;\n  border-style: solid;\n  border-width: 1px;\n  overflow: hidden;\n  word-break: normal;\n  border: 1px solid #e8e8e8;\n  align-items: center; }\n\n.style_tg__3f7r9 th {\n  font-size: 14px;\n  font-weight: normal;\n  padding: 10px 5px;\n  border-style: solid;\n  border-width: 1px;\n  overflow: hidden;\n  word-break: normal;\n  color: #535353;\n  text-align: left;\n  background: #fafafa;\n  border: 1px solid #e8e8e8; }\n\n.style_tg__3f7r9 > tr > th {\n  color: #535353;\n  font-weight: bold;\n  text-align: left; }\n\n.style_tg__3f7r9 .style_tg9wq8__3l00j {\n  border-color: inherit;\n  text-align: center;\n  vertical-align: middle; }\n\n.style_tg__3f7r9 .style_tgNrix__3u6tm {\n  text-align: center;\n  vertical-align: middle; }\n\n.style_semi_bold__3pmCQ {\n  font-weight: 500; }\n\n.style_bold__3fHMe {\n  font-weight: 700; }\n\n.style_grey_background__3-d8i {\n  background-color: #e3e3e3; }\n\n.style_light_grey_background__3oEer {\n  background-color: #f5f5f5; }\n\n.style_requerment_input__Jybv_ {\n  background-color: #e6faff; }\n", ""]);

// exports
exports.locals = {
	"inputFocus": "style_inputFocus__Oc0O8",
	"title": "style_title__JaJE6",
	"tg": "style_tg__3f7r9",
	"policyTeam": "style_policyTeam__35vLu",
	"ant-card-body": "style_ant-card-body__2gYC2",
	"tg9wq8": "style_tg9wq8__3l00j",
	"tgNrix": "style_tgNrix__3u6tm",
	"semi_bold": "style_semi_bold__3pmCQ",
	"bold": "style_bold__3fHMe",
	"grey_background": "style_grey_background__3-d8i",
	"light_grey_background": "style_light_grey_background__3oEer",
	"requerment_input": "style_requerment_input__Jybv_"
};