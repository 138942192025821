import React, { Component, Fragment } from "react";
import { Row, Form, Col, message, Spin } from "antd";
import styles from "./style.module.scss";
import {
  policyViewAnnexFivePublicWeb,
  getReferenceListPublicWeb
} from "../../../../restAPI/main";
import { MagicReference, policyTabKeys } from "../../../../restAPI/request";
import IntlMessages from "util/IntlMessages";
// import Reflect from "./reflect";
import Multi from "./Multi";
import Directions from "./OnlyDirection";
import BothFalse from "./BothFalse";

class AnnexFive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      base_year: 0,
      Organizations: [],
      OrganizationsLoading: false,
      OrganizationandGroup: [],
      OrganizationandGroupLoading: true,
      researches: [],
      researchesLoading: true,
      baseYear: props.policy.start_year,
      dataRequest: {},
      Units: [],
      UnitsLoading: true,
      SourceTypes: [],
      SourceTypeLoading: true,
      isReflect: false,
      is_direction: true,
      is_objective: true,
      getLoading: true,

      is_current: "is_policy"
    };
  }

  componentWillMount() {
    this.fetchUnit();
    this.fetchSourceType();
    this.fetchgetPolicyAnnex5();
  }

  fetchUnit = () => {
    getReferenceListPublicWeb(
      MagicReference.UNIT,
      "",
      this.onSuccessUnit,
      err => this.onFailed(err, "UnitsLoading")
    );
  };

  fetchSourceType = () => {
    getReferenceListPublicWeb(
      MagicReference.SOURCE_TYPE,
      "",
      this.onSuccessSourceType,
      err => this.onFailed(err, "SourceTypeLoading")
    );
  };

  onSuccessSourceType = response => {
    this.setState({
      SourceTypes: response,
      SourceTypeLoading: false
    });
  };

  onSuccessUnit = response => {
    this.setState({
      Units: response,
      UnitsLoading: false
    });
  };

  fetchgetPolicyAnnex5 = () => {
    const { policy } = this.props;
    policyViewAnnexFivePublicWeb(policy.id, "", this.onSuccessGet, err =>
      this.onFailed(err, "")
    );
    this.setState({
      getLoading: true
    });
  };

  onFailed = (err, stateNameLoading) => {
    this.setState({
      [stateNameLoading]: false
    });
    message.error(err);
  };

  onSuccessGet = response => {
    let is_current = "is_policy";
    if (response.annex5_type === 2) {
      is_current = "is_direction";
    } else if (response.annex5_type === 3) {
      is_current = "is_objective";
    }

    this.setState({
      is_current,
      getLoading: false,
      dataRequest: response
    });
  };

  onSuccessResearches = response => {
    this.setState({
      researches: response,
      researchesLoading: false
    });
  };

  onSuccessSave = (res, nextTab) => {
    const { next_rank, onChangeTabKey } = this.props;
    message.success("Амжилттай хадгаллаа.");
    if (nextTab) {
      const next_annex = `annex${next_rank}`;
      onChangeTabKey(policyTabKeys[next_annex]);
    }
  };

  reflecthandle = is_current => {
    let { dataRequest } = this.state;
    if (is_current === "is_policy") {
      dataRequest.annex5_type = 1;
    } else if (is_current === "is_direction") {
      dataRequest.annex5_type = 2;
    } else if (is_current === "is_objective") {
      dataRequest.annex5_type = 3;
    }

    this.setState({
      is_current,
      dataRequest
    });
  };

  updateDataRequest = dataRequest => {
    this.setState({
      dataRequest
    });
  };

  addIndicator = () => {
    const { dataRequest, base_year } = this.state;
    const { policy } = dataRequest;

    const indica_target = [];
    const tmp = {
      id: 0,
      policy_id: this.props.policy.id,
      direction_id: 0,
      objective_id: 0,
      indicator_name: "",
      indicator_name_en: "",
      unit_id: 0,
      baseline_year: base_year,
      baseline_level: "",
      comparison: 0,
      source_type_id: 0,
      source_organization_id: 0,
      source_research_id: 0,
      source_text: "",
      indicator_organizations: [],
      indicator_targets: indica_target
    };

    policy.indicators.push(tmp);

    this.setState({
      dataRequest
    });
  };

  checkDisable = () => {
    const { dataRequest, is_current } = this.state;
    let retVal = false;
    if (is_current === "is_objective") {
      dataRequest.objectives.map(eachObje => {
        eachObje.indicators.map(ind => {
          if (ind.source_type_id === 31) {
            if (!ind.source_organization_id) {
              retVal = true;
            }
          }

          if (ind.source_type_id === 32) {
            if (!ind.source_research_id) {
              retVal = true;
            }
          }
          return "";
        });
        return "";
      });
    } else if (is_current === "is_direction") {
      dataRequest.directions.map(eachDirection => {
        eachDirection.indicators.map(ind => {
          if (ind.source_type_id === 31) {
            if (!ind.source_organization_id) {
              retVal = true;
            }
          }

          if (ind.source_type_id === 32) {
            if (!ind.source_research_id) {
              retVal = true;
            }
          }
          return "";
        });
        return "";
      });
    } else if (is_current === "is_policy") {
      dataRequest.policy.indicators.map(eachData => {
        if (eachData.source_type_id === 31) {
          if (!eachData.source_organization_id) {
            retVal = true;
          }
        }

        if (eachData.source_type_id === 32) {
          if (!eachData.source_research_id) {
            retVal = true;
          }
        }
        return "";
      });
    }

    return retVal;
  };

  render() {
    const {
      dataRequest,
      researches,
      Organizations,
      researchesLoading,
      OrganizationsLoading,
      OrganizationandGroup,
      OrganizationandGroupLoading,
      Units,
      UnitsLoading,
      SourceTypeLoading,
      SourceTypes,
      getLoading,
      base_year,
      is_current
    } = this.state;

    const { policy } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 20 },
        sm: { span: 20 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };

    return (
      <Fragment>
        <Row>
          <Col className="text-right" span={24}>
            <IntlMessages id="annexLeftTitle" values={{ annexNumber: `5` }} />
          </Col>
        </Row>
        <div className={styles.title}>
          <IntlMessages id="annex5.title" />
        </div>

        {getLoading ? (
          <Spin />
        ) : (
          <Fragment>
            {/* <Reflect
              is_current={is_current}
              reflecthandle={this.reflecthandle}
              className="mb-5"
            /> */}
            <Form
              className="mt-5"
              {...formItemLayout}
              onSubmit={this.handleSubmit}
            >
              <table className={styles.tg}>
                <thead>
                  <tr>
                    {is_current === "is_objective" ? (
                      <th
                        className={styles.tg9wq8}
                        style={{ minWidth: "300px" }}
                        rowSpan="2"
                      >
                        <IntlMessages id="annex5.objective" />
                      </th>
                    ) : null}
                    <th
                      className={styles.tg9wq8}
                      style={{ minWidth: "75px" }}
                      rowSpan="2"
                    >
                      <IntlMessages id="annex5.indicator" /> <br />
                    </th>
                    <th
                      className={styles.tg9wq8}
                      style={{ minWidth: "150px" }}
                      rowSpan="2"
                    >
                      <IntlMessages id="annex5.unit_of_messure" />
                    </th>
                    <th
                      className={styles.tg9wq8}
                      style={{ width: 100 }}
                      colSpan="2"
                    >
                      <IntlMessages id="annex5.base_level" />
                    </th>
                    <th style={{ width: 100 }} className={styles.tg9wq8}>
                      <IntlMessages id="annex5.target_level" />
                    </th>
                    <th
                      className={styles.tg9wq8}
                      style={{ width: 100 }}
                      rowSpan="2"
                    >
                      <IntlMessages id="annex5.source_of_data" />
                    </th>
                    <th
                      className={styles.tg9wq8}
                      style={{ width: 100 }}
                      rowSpan="2"
                    >
                      <IntlMessages id="annex5.implementry_bodies" />
                    </th>
                    <th className={styles.tg9wq8} style={{ width: 100 }} />
                  </tr>

                  <tr>
                    <td className={styles.tg9wq8} style={{ width: 100 }}>
                      <IntlMessages id="annex5.year" />
                    </td>
                    <td className={styles.tg9wq8} style={{ width: 100 }}>
                      <IntlMessages id="annex5.value_of_indicator" />
                    </td>
                    <td style={{ minWidth: 340 }} className={styles.tg9wq8}>
                      Он / Үзүүлэлт
                    </td>
                    {/* <td style={{ minWidth: 240 }} className={styles.tg9wq8}>
                      <IntlMessages id="annex5.value_of_indicator" />
                    </td> */}
                  </tr>
                </thead>
                {is_current === "is_objective" ? (
                  <Multi
                    key="multi"
                    dataRequest={dataRequest}
                    policy={policy}
                    UnitsLoading={UnitsLoading}
                    Units={Units}
                    SourceTypeLoading={SourceTypeLoading}
                    SourceTypes={SourceTypes}
                    researchesLoading={researchesLoading}
                    researches={researches}
                    OrganizationsLoading={OrganizationsLoading}
                    Organizations={Organizations}
                    OrganizationandGroupLoading={OrganizationandGroupLoading}
                    OrganizationandGroup={OrganizationandGroup}
                    updateDataRequest={this.updateDataRequest}
                    base_year={base_year}
                  />
                ) : null}

                {is_current === "is_direction" ? (
                  <Directions
                    key="direction"
                    dataRequest={dataRequest}
                    policy={policy}
                    UnitsLoading={UnitsLoading}
                    Units={Units}
                    SourceTypeLoading={SourceTypeLoading}
                    SourceTypes={SourceTypes}
                    researchesLoading={researchesLoading}
                    researches={researches}
                    OrganizationsLoading={OrganizationsLoading}
                    Organizations={Organizations}
                    OrganizationandGroupLoading={OrganizationandGroupLoading}
                    OrganizationandGroup={OrganizationandGroup}
                    updateDataRequest={this.updateDataRequest}
                    base_year={base_year}
                  />
                ) : null}

                {is_current === "is_policy" ? (
                  <BothFalse
                    key="BothFalse"
                    dataRequest={dataRequest}
                    policy={policy}
                    UnitsLoading={UnitsLoading}
                    Units={Units}
                    SourceTypeLoading={SourceTypeLoading}
                    SourceTypes={SourceTypes}
                    researchesLoading={researchesLoading}
                    researches={researches}
                    OrganizationsLoading={OrganizationsLoading}
                    Organizations={Organizations}
                    OrganizationandGroupLoading={OrganizationandGroupLoading}
                    OrganizationandGroup={OrganizationandGroup}
                    updateDataRequest={this.updateDataRequest}
                  />
                ) : null}
              </table>
            </Form>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default Form.create()(AnnexFive);
