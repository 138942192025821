import React, { Component } from "react";
import { Row, Form, Col, Radio, InputNumber, message } from "antd";
import styles from "./style.module.scss";
import { MagicReference } from "../../../../restAPI/request";
import { getReferenceListPublicWeb } from "../../../../restAPI/main";
import IntlMessages from "util/IntlMessages";

class AnnexSevenB extends Component {
  state = {
    loading: true,
    mainFinancials: [],
    mainFinancialsLoading: true,
    files: [],
    fileList: [],
    childfileVis: false,
    formData: [
      {
        policy_id: 0,
        type: "",
        is_edit: true,
        startdate: "",
        enddate: "",
        is_send: true,
        togtool_file_id: 0,
        togtool_file_path: "",
        togtool_file_name: "",
        activities: [
          {
            id: 0,
            state_org_id: 0,
            activity: "",
            is_external: true,
            mid_term_policy_id: 0,
            output: "",
            indicator: "",
            is_reflect: true,
            plan_amount: 0,
            actual_amount: 0,
            created_organization_id: 0,
            aimag_id: 0,
            soum_id: 0,
            bag_id: 0,
            longitude: 0,
            latitude: 0,
            budgets: [
              {
                amount: 0,
                financial_source_id: 0,
                order: 0
              }
            ]
          }
        ]
      }
    ],
    editAddress: false,
    currentAddress: {},
    actIndex: 0
  };

  componentWillMount() {
    const { formData } = this.props;
    const fileList = [];
    if (formData.togtool_file_id) {
      fileList.push({
        uid: formData.togtool_file_id,
        name: formData.togtool_file_name,
        status: "done",
        url: formData.togtool_file_path
      });
    }

    this.setState({
      formData,
      fileList
    });

    getReferenceListPublicWeb(
      MagicReference.FINANCIAL_SOURCE,
      "",
      this.onSuccessFinancial,
      err => this.onFailde(err, "mainFinancialsLoading"),
      "POST"
    );
  }

  onSuccessFiles = response => {
    response.map((each, eachIndex) => {
      each.key = eachIndex + 1;
      return null;
    });
    this.setState({
      files: response
    });
  };

  onFailde = (err, loader) => {
    message.error(err);
    this.setState({
      [loader]: false
    });
  };

  onSuccessFinancial = response => {
    this.setState({
      mainFinancials: response,
      mainFinancialsLoading: false
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formData } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 20 },
        sm: { span: 20 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Row>
          <Col className="text-right" span={24}>
            <IntlMessages id="annexLeftTitle" values={{ annexNumber: `7` }} />
          </Col>
        </Row>

        <br />
        <table className={`${styles.tg} mb-5`}>
          <thead>
            <tr>
              <th className={styles.tg9wq8}>#</th>
              <th className={styles.tg9wq8} style={{ width: 250 }}>
                Төрийн захиргааны төв, <br />
                төрийн захиргааны
                <br />
                байгууллага
              </th>
              <th className={styles.tg9wq8} style={{ width: 250 }}>
                Арга хэмжээ
              </th>
              <th className={styles.tg9wq8}>
                Дунд&nbsp;&nbsp;хугацааны
                <br />
                бодлогын баримт
                <br />
                бичиг
              </th>
              <th className={styles.tg9wq8} style={{ width: 170 }}>
                Шалгуур үзүүлэлт
              </th>
              <th className={styles.tg9wq8} style={{ width: 170 }}>
                Хүрэх түвшин, үр дүн
              </th>
              <th className={styles.tg9wq8} style={{ width: 170 }}>
                Шаардлагатай
                <br />
                хөрөнгийн хэмжээ,
                <br />
                хөрөнгийн эх үүсвэр (сая ₮)
              </th>
              <th className={styles.tg9wq8}>Үндсэн чиглэлд тусгах эсэх</th>
              <th className={styles.tg9wq8}>
                Төлөвлөгдсөн
                <br />
                санхүүжилт
                <br />
                (төлөвлөсөн/
                <br />
                батлагдсан)
              </th>
              <th className={styles.tg9wq8}>
                Батлагдсан
                <br />
                санхүүжилтийн хувь
              </th>
              <th className={styles.tg9wq8}>Хэрэгжүүлэх байршил</th>
            </tr>
          </thead>
          <tbody>
            {formData.map((act, actIndex) => {
              return (
                <tr>
                  <td className={styles.tglboi}>{actIndex + 1}</td>
                  <td className={styles.tglboi}>{act.state_org_name}</td>
                  <td className={styles.tglboi}>{act.activity}</td>
                  <td className={styles.tglboi}>{act.mid_term_policy_name}</td>
                  <td className={styles.tglboi}>{act.output}</td>
                  <td className={styles.tglboi}>{act.indicator}</td>
                  <td className={styles.tglboi} style={{ minWidth: 200 }}>
                    <ul>
                      {act.budgets.map((budget, budgetIndex) => {
                        return (
                          <li key={budgetIndex}>
                            {budget.financial_source_name} - {budget.amount}
                          </li>
                        );
                      })}
                    </ul>
                  </td>
                  <td className={styles.tglboi} style={{ minWidth: 200 }}>
                    <Row className="text-center">
                      <Col span={24}>
                        <Radio.Group
                          value={act.is_reflect}
                          disabled
                          size="large"
                        >
                          <Radio value={true}>Тийм</Radio>
                          <Radio value={false}>Үгүй</Radio>
                        </Radio.Group>
                      </Col>

                      <Col span={24} className="mt-2">
                        {act.reflect_desc}
                      </Col>
                    </Row>
                  </td>
                  <td className={styles.tg0pky}>
                    <Form.Item>
                      {getFieldDecorator(`plan_amount${actIndex}`, {
                        initialValue: act.plan_amount,
                        rules: [
                          {
                            required: true,
                            message: <IntlMessages id="input_required" />
                          }
                        ]
                      })(<InputNumber disabled />)}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator(`actual_amount${actIndex}`, {
                        initialValue: act.actual_amount,
                        rules: [
                          {
                            required: true,
                            message: <IntlMessages id="input_required" />
                          }
                        ]
                      })(
                        <InputNumber min={0} disabled max={act.plan_amount} />
                      )}
                    </Form.Item>
                  </td>
                  <td className={styles.tg0pky}>
                    {act.actual_amount && act.plan_amount
                      ? `${(
                          (act.actual_amount * 100) /
                          act.plan_amount
                        ).toFixed(1)}%`
                      : null}
                  </td>

                  <td style={{ minWidth: "150px" }} className={styles.tgNrix}>
                    <span className="text-left">
                      {act.aimag_name} - {act.sum_name} - {act.bag_name}
                    </span>
                    <br />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Form>
    );
  }
}

export default Form.create()(AnnexSevenB);
