import React from "react";
import { Route, Switch } from "react-router-dom";
import Stakeholder from "./Stakeholder/index";
import Configuration from "./Configuration/index";
import Research from "./Research/index";
import Proposal from "./Proposal/index";
import Tentative from "./Tentative";
import Eoffice from "./Eoffice";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => {
  return (
    <div className="gx-main-content-wrapper" style={{ paddingTop: 15 }}>
      <Switch>
        <Route
          path={`${match.url}dashboard`}
          component={asyncComponent(() => import("./Dashboard"))}
        />
        <Route
          path={`${match.url}policy-relation`}
          component={asyncComponent(() => import("./Dashboard/index2.js"))}
        />
        <Route
          path={`${match.url}advanced-dashboard`}
          component={asyncComponent(() => import("./Dashboard/advanced.js"))}
        />
        <Route
          path={`${match.url}policy`}
          component={asyncComponent(() => import("./Policy"))}
        />
        <Route path={`${match.url}stakeholder`} component={Stakeholder} />
        <Route path={`${match.url}proposal`} component={Proposal} />
        <Route path={`${match.url}configuration`} component={Configuration} />
        <Route path={`${match.url}research`} component={Research} />
        <Route path={`${match.url}eoffice`} component={Eoffice} />
        <Route path={`${match.url}tentativeproposal`} component={Tentative} />
        <Route
          path={`${match.url}profile`}
          component={asyncComponent(() => import("./Profile"))}
        />
        <Route
          path={`${match.url}webgis`}
          component={asyncComponent(() => import("./webgis"))}
        />
        advanced
      </Switch>
    </div>
  );
};

export default App;
