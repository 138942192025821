import React, { Fragment } from "react";

import { Modal, Button, Row, Col } from "antd";
import { getReferenceListPublicWeb } from "../../restAPI/main";
import { MagicReference } from "../../restAPI/request";
import image from "../../assets/images/logo.png";
import facebook from "../../assets/images/svg/facebook.svg";
import arrow from "../../assets/images/svg/arrow.svg";
import twitter from "../../assets/images/svg/twitter.svg";
import youtube from "../../assets/images/svg/youtube.svg";

class FooterComp extends React.Component {
  state = {
    showTerm: false,
    contactInfo: []
  };

  componentWillMount() {
    getReferenceListPublicWeb(
      MagicReference.CONTACTINFO,
      "",
      this.onSuccessInfo,
      this.onFailed
    );
  }

  onSuccessInfo = response => {
    this.setState({
      contactInfo: response
    });
  };

  render() {
    const { publicLang } = this.props;
    const { showTerm, contactInfo } = this.state;
    return (
      <div
        className="tw-footer"
        style={{
          fontFamily: "Roboto Condensed, sans-serif"
        }}
        data-uk-scrollspy="target: > *; cls:uk-animation-slide-bottom-medium; delay: 300;"
      >
        <footer className="uk-section uk-section-default">
          <div
            className="uk-grid-medium"
            data-uk-grid
            data-uk-scrollspy="target: > div; cls:uk-animation-slide-bottom-medium; delay: 300;"
          >
            <Row style={{ width: "100%" }}>
              <Col
                xl={8}
                lg={8}
                md={8}
                sm={12}
                xs={24}
                style={{ margin: "auto" }}
              >
                <div className="uk-flex uk-flex-bottom tw-mb-20">
                  <a href="#" className="tw-mr-25">
                    <img alt="" width="350" src={image} />
                  </a>
                  {publicLang ? (
                    <div id="google_translate_element"></div>
                  ) : null}
                </div>
              </Col>

              <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                <h6 className="uk-text-uppercase">
                  <b>Холбоо барих</b>
                </h6>
                <ul
                  className="uk-list"
                  style={{ marginTop: 20, color: "black" }}
                >
                  {contactInfo.map(each => {
                    return (
                      <Fragment key={each.id}>
                        <li>{each.name}</li>
                      </Fragment>
                    );
                  })}
                </ul>
              </Col>

              <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                <p
                  style={{
                    fontFamily: "Roboto Condensed, sans-serif"
                  }}
                  className="uk-margin-remove uk-text-lead tw-mb-35"
                >
                  <h6
                    className="uk-text-uppercase"
                    style={{ marginBottom: 16 }}
                  >
                    <b>Үндэсний Хөгжлийн Газар</b>
                  </h6>{" "}
                  <ul
                    className="uk-list"
                    style={{ marginTop: 20, color: "black" }}
                  >
                    Засгийн газрын II байр <br />
                    Нэгдсэн үндэстний гудамж 5/1 Чингэлтэй
                    <br />
                    дүүрэг, Улаанбаатар хот 15160
                  </ul>
                </p>
                <ul
                  style={{ float: "right" }}
                  className="uk-iconnav uk-iconnav-horizontal tw-social-icon uk-text-right"
                >
                  <li className="flip-box">
                    <a
                      className="side-f"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/ndagovMN/"
                    >
                      <img alt="" src={facebook} />
                    </a>
                    <a
                      className="tw-bg-facebook side-b"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/ndagovMN/"
                    >
                      <img alt="" src={facebook} />
                    </a>
                  </li>
                  <li className="flip-box">
                    <a
                      className="side-f"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/ndagovMN"
                    >
                      <img alt="" src={twitter} />
                    </a>
                    <a
                      className="tw-bg-twitter side-b"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/ndagovMN"
                    >
                      <img alt="" src={twitter} />
                    </a>
                  </li>
                  <li className="flip-box">
                    <a
                      className="side-f"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/channel/UCwa3Yh0SrzZlUR9IzkJHo5Q"
                    >
                      <img alt="" src={youtube} />
                    </a>
                    <a
                      className="tw-bg-youtube side-b"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/channel/UCwa3Yh0SrzZlUR9IzkJHo5Q"
                    >
                      <img alt="" src={youtube} />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className="text-center">
            <span style={{ color: "black" }}>
              Зохиогчийн эрх хуулиар хамгаалагдсан. Мэдээлэл хуулбарлах хориотой
            </span>{" "}
            <br />
            <Button
              type="link"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.setState({
                  showTerm: true
                });
              }}
            >
              <b>Үйлчилгээний нөхцөл</b>
            </Button>{" "}
            <Button type="link" style={{ cursor: "pointer" }}>
              <b>
                <a
                  href="https://www.legalinfo.mn/annex/details/10118?lawid=14648"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Эрх зүйн акт
                </a>{" "}
              </b>
            </Button>
            <Modal
              title="Үйлчилгээний нөхцөл"
              visible={showTerm}
              footer={false}
              onCancel={() => this.setState({ showTerm: false })}
            >
              <ul>
                <li>
                  Азийн хөгжлийн банкнаас Монгол Улсын Засгийн газарт үзүүлэх
                  буцалтгүй тусламжийн хүрээнд Үндэсний хөгжлийн газрын
                  "Хөгжлийн бодлого төлөвлөлтийн тухай" хуулийн 19.2, "Хөгжлийн
                  бодлогын баримт бичиг боловсруулах нийтлэг журам"-ийн 6 дугаар
                  бүлэгт тус тус заасан чиг үүргийг хэрэгжүүлэх зорилгоор энэхүү
                  системийг хөгжүүлэв.
                </li>
                <li>
                  Бодлогын баримт бичгийг олон нийтэд ил тод, ойлгомжтой
                  байлгах, бодлогын баримт бичгийн төслийг иргэд, олон нийт,
                  холбогдох төрийн болон төрийн бус байгууллагуудаар
                  хэлэлцүүлэх, санал авах ажлыг хялбаршуулах, бодлогын баримт
                  бичгийн уялдааг сайжруулах зорилгын хүрээнд энэхүү системийг
                  ашиглана.
                </li>
                <li>
                  Систем ашиглахдаа дараах нөхцөлийг мөрдөхийг хичээнгүйлэн
                  хүсье.
                  <ul>
                    <li>
                      Cистемд хэрэглэгчээр бүргүүлэхдээ
                      <ul>
                        <li>Өөрийн мэдээллийг үнэн зөв, бүрэн оруулах</li>
                        <li>Нэгээс олон бүртгэл үүсгэхгүй байх</li>
                        <li>Бусдын өмнөөс бүртгэл үүсгэхгүй байх</li>
                      </ul>
                    </li>
                    <li>
                      Бодлогын баримт
                      <ul>
                        <li>Үндсэн санаагаа товч тодорхой бичих</li>
                        <li>
                          Тухайн бодлогын баримт бичгийн агуулгатай хамааралгүй
                          мэдээлэл оруулахгүй байх
                        </li>
                        <li>
                          Бусдыг үл хүндэтгэсэн, ёс бус үг хэллэг ашиглахгүй
                          байх
                        </li>
                      </ul>
                    </li>
                    <li>Холбогдох хууль тогтоомжийн хүрээнд ашиглах</li>
                    Энэхүү системд байршуулсан бодлогын баримт бичгийн мэдээлэл
                    батлагдсан бодлогын баримт бичигт туссан мэдээллээс зөрсөн
                    тохиолдолд батлагдсан бодлогын баримт бичгийн хувилбарыг
                    баримтална.
                  </ul>
                </li>
              </ul>
            </Modal>
          </div>
          <ul
            className="uk-iconnav uk-iconnav-horizontal tw-social-icon tw-social-icon-large"
            style={{ position: "absolute", top: "-19px", right: "40px" }}
          >
            <li className="flip-box">
              <a
                id="target-1"
                className="tw-button-primary side-f"
                href="#top"
                data-uk-scroll
              >
                <img alt="" src={arrow} />
              </a>
              <a
                id="target-2"
                className="tw-button-warning side-b"
                href="#top"
                data-uk-scroll
              >
                <img alt="" src={arrow} />
              </a>
            </li>
          </ul>
        </footer>
      </div>
    );
  }
}

export default FooterComp;
