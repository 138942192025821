import React from "react";

import asyncComponent from "util/asyncComponent";
import { Route, Switch, Redirect } from "react-router-dom";
const Stakeholder = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/create`} />
    <Route
      exact
      path={`${match.url}/consultation`}
      component={asyncComponent(() => import("./consultation/List"))}
    />
    <Route
      exact
      path={`${match.url}/consultation/create`}
      component={asyncComponent(() => import("./consultation/Create"))}
    />
    <Route
      exact
      path={`${match.url}/consultation/edit/:id`}
      component={asyncComponent(() => import("./consultation/Create"))}
    />

    <Route
      path={`${match.url}/create/:id`}
      component={asyncComponent(() => import("./proposal/Create"))}
    />
    <Route
      path={`${match.url}/SimpleCreatePolicy`}
      component={asyncComponent(() => import("./proposal/SimpleCreatePolicy"))}
    />
    <Route
      path={`${match.url}/simplecreateresearch`}
      component={asyncComponent(() =>
        import("./proposal/SimpleCreateResearch")
      )}
    />
    <Route
      path={`${match.url}/edit/:id`}
      component={asyncComponent(() => import("./proposal/Create/edit.js"))}
    />
    <Route
      path={`${match.url}/ref/edit/:id`}
      component={asyncComponent(() => import("./proposal/EditAdmin"))}
    />
    <Route
      path={`${match.url}/list`}
      component={asyncComponent(() => import("./proposal/List"))}
    />
    <Route
      path={`${match.url}/scope`}
      component={asyncComponent(() => import("./scope"))}
    />
    <Route
      exact
      path={`${match.url}/history/:id`}
      component={asyncComponent(() => import("./history/List"))}
    />
    <Route
      path={`${match.url}/sent/:id`}
      component={asyncComponent(() => import("./sent"))}
    />

    <Route
      path={`${match.url}/sanal/:id`}
      component={asyncComponent(() => import("./proposal/List/sanal.js"))}
    />

    <Route
      path={`${match.url}/sanalDetail/:id`}
      component={asyncComponent(() => import("./proposal/List/sanalDetail.js"))}
    />
  </Switch>
);

export default Stakeholder;
