import React from "react";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";
import { Icon } from "antd";
import { download } from "util/helper";

const AppNotification = ({ gariin_avlaga }) => {
  return (
    <Auxiliary>
      <div className="gx-popover-header">
        <h3 className="gx-mb-0">Системийн гарын авлагууд</h3>
      </div>
      <CustomScrollbars className="gx-popover-scroll">
        <ul className="gx-sub-popover">
          {gariin_avlaga.map((item, index) => (
            <li
              className="gx-media"
              style={{ padding: "7px 0", cursor: "pointer" }}
            >
              <div className="gx-media-body gx-align-self-center">
                <span
                  onClick={() => download(item.url, `${item.name}.pdf`)}
                  className="custom-link"
                  style={{ width: "100%" }}
                >
                  <p className="gx-fs-md gx-mb-0">
                    {" "}
                    <Icon type="book" /> {item.name}
                  </p>
                </span>
              </div>
            </li>
          ))}
        </ul>
      </CustomScrollbars>
    </Auxiliary>
  );
};

export default AppNotification;
