import React from "react";
import { Button, message, Form, Input, Alert, Row, Col } from "antd";
import { authSetpassword } from "../restAPI/main";
import IntlMessages from "util/IntlMessages";

const FormItem = Form.Item;

class ForgetPassword extends React.Component {
  state = {
    isSendEmail: false,
    loadingBtn: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const request = {
          password: values.password,
          token: this.props.match.params.id
        };
        authSetpassword(request, this.onSuccess, this.onFailed);
        this.setState({
          loadingBtn: true
        });
      }
    });
  };

  onSuccess = () => {
    this.setState({
      isSendEmail: true,
      loadingBtn: false
    });
    message.success("Амжилттай солигдлоо.");
    this.props.history.push("/signin");
  };

  onFailed = err => {
    message.error(err);
    this.setState({
      loadingBtn: false
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Оруулсан нууц үгүүд ижилхэн байх шаардлагатай байна!");
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isSendEmail, loadingBtn } = this.state;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container" style={{ maxWidth: 400 }}>
          <div className="gx-app-login-main-content">
            <div className="gx-app-login-content" style={{ width: "100%" }}>
              <div className="text-center">
                <img alt="" src={require("assets/images/logo-thumb.png")} />
              </div>

              {isSendEmail ? (
                <Alert
                  message="Таны нууц үг амжилттай солигдлоо."
                  type="success"
                />
              ) : (
                ""
              )}
              <Form
                onSubmit={this.handleSubmit}
                className="mt-5 gx-signin-form gx-form-row0"
              >
                <Form.Item label="Нууц" hasFeedback>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: "Нууц үгээ оруулна уу!"
                      },
                      {
                        validator: this.validateToNextPassword
                      }
                    ]
                  })(<Input.Password />)}
                </Form.Item>

                <Form.Item label="Нууц үг давтах" hasFeedback>
                  {getFieldDecorator("confirm", {
                    rules: [
                      {
                        required: true,
                        message: "Нууц үгээ баталгаажуулна уу!"
                      },
                      {
                        validator: this.compareToFirstPassword
                      }
                    ]
                  })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                </Form.Item>

                <Row>
                  <Col xl={12} lg={24} style={{ padding: 0 }}>
                    <FormItem className="text-left">
                      <a href="/login">
                        <Button
                          type="primary"
                          className="text-right gx-mb-0 primaryColor"
                        >
                          <IntlMessages id="back" />
                        </Button>
                      </a>
                    </FormItem>
                  </Col>
                  <Col xl={12} lg={24} style={{ padding: 0 }}>
                    <FormItem className="text-right">
                      <Button
                        loading={loadingBtn}
                        type="primary"
                        className="text-right gx-mb-0 primaryColor"
                        htmlType="submit"
                      >
                        <IntlMessages id="save" />
                      </Button>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create()(ForgetPassword);
