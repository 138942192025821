import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";

const SubMenu = Menu.SubMenu;

class HorizontalNav extends Component {
  state = {
    menus: [],
    loading: true,
    language: localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "mn"
  };

  componentWillMount() {
    let menus = [];
    const tmpmenus = localStorage.getItem("menus");
    if (tmpmenus) {
      menus = JSON.parse(localStorage.getItem("menus"));
    }
    this.setState({
      menus
    });
  }

  getNavStyleSubMenuClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  render() {
    const { pathname, navStyle } = this.props;
    const { menus, language } = this.state;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    return (
      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        mode="horizontal"
      >
        {menus.map((eachMenu, menuIndex) => {
          return (
            <SubMenu
              key={menuIndex}
              className={this.getNavStyleSubMenuClass(navStyle)}
              title={language === "mn" ? eachMenu.menu : eachMenu.menu_en}
            >
              {eachMenu.submenus.map(eachChildMenu => {
                return (
                  <Menu.Item key={eachChildMenu.submenu_name}>
                    <a href={eachChildMenu.submenu_route} target={eachChildMenu.submenu_route === "/advanced-dashboard" ? "_blank" : null}>
                      <i className="icon icon-add" />
                      {language === "mn"
                        ? eachChildMenu.submenu_name
                        : eachChildMenu.submenu_name_en}
                    </a>
                  </Menu.Item>
                );
              })}
            </SubMenu>
          );
        })}
      </Menu>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { themeType, navStyle, pathname, locale } = settings;
  return { themeType, navStyle, pathname, locale };
};
export default connect(mapStateToProps)(HorizontalNav);
