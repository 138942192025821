exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_inputFocus__29Ci7:focus {\n  height: 200px; }\n\n.style_title__hdmsK {\n  text-align: center;\n  margin: 20px 0px;\n  font-size: 17px;\n  font-weight: 500;\n  padding: 0 20%; }\n\n.style_tg__1UmHH {\n  border-collapse: collapse;\n  border-spacing: 0;\n  width: 100%;\n  font-size: 14px;\n  line-height: 1.3;\n  list-style: none;\n  border: 1px solid #e8e8e8;\n  border-radius: 6px 6px 0 0;\n  border-collapse: separate;\n  display: block;\n  table-layout: fixed;\n  overflow-x: auto;\n  overflow: auto; }\n\n.style_policyTeam__3IzZ9 .style_ant-card-body__1H3or {\n  padding: 8px !important; }\n\n.style_tg__1UmHH tbody {\n  overflow: auto;\n  height: 100px; }\n\n.style_tg__1UmHH td {\n  font-size: 14px;\n  padding: 10px 5px;\n  border-style: solid;\n  border-width: 1px;\n  overflow: hidden;\n  word-break: normal;\n  border: 1px solid #e8e8e8;\n  align-items: center; }\n\n.style_tg__1UmHH th {\n  font-size: 14px;\n  font-weight: normal;\n  padding: 10px 5px;\n  border-style: solid;\n  border-width: 1px;\n  overflow: hidden;\n  word-break: normal;\n  color: #535353;\n  text-align: left;\n  background: #fafafa;\n  border: 1px solid #e8e8e8; }\n\n.style_tg__1UmHH > tr > th {\n  color: #535353;\n  font-weight: bold;\n  text-align: left; }\n\n.style_tg__1UmHH .style_tg9wq8__GaTBj {\n  border-color: inherit;\n  text-align: center;\n  vertical-align: middle; }\n\n.style_tg__1UmHH .style_tgNrix__2R1ns {\n  text-align: center;\n  vertical-align: middle; }\n\n.style_semi_bold__1B4He {\n  font-weight: 500; }\n\n.style_bold__tZfaP {\n  font-weight: 700; }\n\n.style_grey_background__1X4Hl {\n  background-color: #e3e3e3; }\n\n.style_light_grey_background__1hTTw {\n  background-color: #f5f5f5; }\n\n.style_requerment_input__1fVTY {\n  background-color: #e6faff; }\n", ""]);

// exports
exports.locals = {
	"inputFocus": "style_inputFocus__29Ci7",
	"title": "style_title__hdmsK",
	"tg": "style_tg__1UmHH",
	"policyTeam": "style_policyTeam__3IzZ9",
	"ant-card-body": "style_ant-card-body__1H3or",
	"tg9wq8": "style_tg9wq8__GaTBj",
	"tgNrix": "style_tgNrix__2R1ns",
	"semi_bold": "style_semi_bold__1B4He",
	"bold": "style_bold__tZfaP",
	"grey_background": "style_grey_background__1X4Hl",
	"light_grey_background": "style_light_grey_background__1hTTw",
	"requerment_input": "style_requerment_input__1fVTY"
};