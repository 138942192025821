exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_policyCol__QySTY {\n  width: 33%;\n  text-align: center;\n  vertical-align: middle !important;\n  font-weight: 400; }\n\n.style_impactCol__1M0_X {\n  padding: 0px 10px; }\n\n.style_title__2fMNl {\n  text-align: center;\n  margin: 20px 0px;\n  font-size: 17px;\n  font-weight: 500; }\n\n.style_minusBtn__1hCvb {\n  width: 5%;\n  margin: auto;\n  margin-left: 5px; }\n", ""]);

// exports
exports.locals = {
	"policyCol": "style_policyCol__QySTY",
	"impactCol": "style_impactCol__1M0_X",
	"title": "style_title__2fMNl",
	"minusBtn": "style_minusBtn__1hCvb"
};