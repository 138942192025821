import {
  INIT_URL,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  CHANGE_WINDOWS,
  CHANGE_APIS
} from "constants/ActionTypes";

export const changeAPis = apis => {
  return {
    type: CHANGE_APIS,
    payload: apis
  };
};

export const changeWindows = windows => {
  return {
    type: CHANGE_WINDOWS,
    payload: windows
  };
};

export const userSignUp = user => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};
export const userSignIn = user => {
  return {
    loader: true,
    type: SIGNIN_USER,
    payload: user
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};
export const userSignUpSuccess = authUser => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = authUser => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  };
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS
  };
};

export const showAuthMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};
