import React, { Component, Fragment } from "react";
import { Row, Form, Col, Table, message } from "antd";
import IntlMessages from "util/IntlMessages";
import {
  policyViewAnnexOnePublicWeb,
  getReferenceListPublicWeb
} from "../../../../restAPI/main";
import ShowMoreText from "react-show-more-text";
import styles from "./style.module.scss";
import { MagicReference } from "../../../../restAPI/request";

const formItemLayout = {
  labelCol: {
    xs: { span: 20 },
    sm: { span: 20 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
};
class AnnexOne extends Component {
  state = {
    loading: true,
    policy: this.props.policy, // Үндсэн бодлого
    datas: [],
    evalationalds: [],
    columns: [
      {
        title: <IntlMessages id="annex1.table" />,
        dataIndex: "objective",
        width: 250,
        render: value => (
          <ShowMoreText
            lines={3}
            more="Дэлгэрэнгүй"
            less="Хураах"
            anchorClass=""
            expanded={false}
            className="text-justify"
          >
            {value}
          </ShowMoreText>
        )
      }
    ],

    columnRemark: [
      {
        title: <IntlMessages id="title" />,
        dataIndex: "title",
        width: 200
      },
      {
        title: <IntlMessages id="remark" />,
        dataIndex: "remark",
        render: () => (
          <ShowMoreText
            lines={3}
            more="Дэлгэрэнгүй"
            less="Хураах"
            anchorClass=""
            expanded={false}
            className="text-left"
          >
            {this.props.policy.annex1_remark}
          </ShowMoreText>
        )
      }
    ],
    Annex1_remark: [
      {
        title: <IntlMessages id="remark" />,
        policy_id: 0,
        annex1_remark: "",
        annex1_remark_en: ""
      }
    ]
  };

  componentWillMount() {
    const { policy } = this.props;

    getReferenceListPublicWeb(
      MagicReference.COORDINATION_EVALUATION,
      "",
      this.onSuccessEval,
      this.onFailed,
      "POST"
    );

    const Annex1_remark = [
      {
        title: <IntlMessages id="remark" />,
        policy_id: 0,
        annex1_remark: "",
        annex1_remark_en: ""
      }
    ];

    Annex1_remark[0].annex1_remark = policy.annex1_remark;
    Annex1_remark[0].annex1_remark_en = policy.annex1_remark_en;
    this.setState({
      Annex1_remark
    });
  }

  onSuccessEval = response => {
    const { policy } = this.props;
    this.setState({
      evalationalds: response
    });

    policyViewAnnexOnePublicWeb(
      policy.id,
      "",
      this.onSuccessObj,
      this.onFailed,
      "POST"
    );
  };

  onSuccessObj = response => {
    let { columns } = this.state;
    let datas = response;
    if (response.length !== 0) {
      let tmp1 = {};
      response[0].policy_coordinations.map(i => {
        tmp1 = {
          co_policy_id: i.co_policy_id,
          title: (
            <Row gutter={16}>
              <Col className="text-center" span={24}>
                <span>{i.co_policy_name}</span>
              </Col>
            </Row>
          ),
          width: 500,
          render: (value, record, index) =>
            this.getClause(record, i.co_policy_id, index)
        };
        columns.push(tmp1);
        return "";
      });
    }

    this.setState({
      datas,
      columns
    });
  };

  onFailed = err => {
    message.error(err);
  };

  getClause = (record, col_policy_id, MainIndex) => {
    const { evalationalds } = this.state;
    return record.policy_coordinations.map((col_policy, index) => {
      if (col_policy.co_policy_id === col_policy_id) {
        return (
          <Fragment key={index}>
            <span className="mb-3">
              <b>
                {" "}
                <IntlMessages id="annex1.coordination" />
              </b>
              :{" "}
              {
                evalationalds.find(
                  x => x.id === col_policy.coordination_evaluation_id
                ).name
              }
            </span>
            <br />
            <br />
            <ShowMoreText
              lines={3}
              more="Дэлгэрэнгүй"
              less="Хураах"
              anchorClass=""
              expanded={false}
              className="text-left"
            >
              {col_policy.coordination_evaluation_id === 85
                ? ""
                : col_policy.coordination_clause}
            </ShowMoreText>
          </Fragment>
        );
      }
      return "";
    });
  };

  getTotal = (index, record) => {
    const { datas } = this.state;
    let retVal = 0;
    datas.map(eachData => {
      if (
        eachData.policy_coordinations[index - 1].coordination_evaluation_id ===
        84
      ) {
        retVal += 1;
      } else if (
        eachData.policy_coordinations[index - 1].coordination_evaluation_id ===
        83
      ) {
        retVal += 1;
      }
      return "";
    });

    if (record.key === "1") {
      return retVal;
    } else {
      return `${((retVal * 100) / datas.length).toFixed(2)}%`;
    }
  };

  render() {
    const { columns, datas, columnRemark, Annex1_remark } = this.state;
    const TotalDataSource = [
      {
        key: "1",
        name: "Нийт"
      },
      {
        key: "2",
        name: "Нийцсэн хувь"
      }
    ];

    const TotalColumns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 242
      }
    ];

    const tmp = [...columns];
    tmp.map((eachColumn, index) => {
      if (index) {
        const tmp1 = {
          width: 500,
          render: (value, record) => <span>{this.getTotal(index, record)}</span>
        };
        TotalColumns.push(tmp1);
      }
      return "";
    });

    return (
      <Fragment>
        <Row>
          <Col className="text-right" span={24}>
            <IntlMessages id="annexLeftTitle" values={{ annexNumber: `1` }} />
          </Col>
        </Row>
        <div className={styles.title}>
          <IntlMessages id="annex1.title" />
        </div>
        <Row className="mb-4">
          <Col span={24} className="text-left">
            Засгийн газрын 2018 оны 294 дүгээр тогтоолоор энэ хавсралтыг
            өөрчилсөн.
          </Col>
        </Row>
        <br />

        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Table
            scroll={{ x: "max-content" }}
            pagination={false}
            columns={columns}
            dataSource={datas}
            rowKey="key"
          />

          <Table
            scroll={{ x: "max-content" }}
            columns={TotalColumns}
            pagination={false}
            showHeader={false}
            dataSource={TotalDataSource}
          />

          <Table
            scroll={{ x: "max-content" }}
            className="mt-5"
            columns={columnRemark}
            pagination={false}
            dataSource={Annex1_remark}
            rowKey="remark"
          />
        </Form>
      </Fragment>
    );
  }
}

export default Form.create()(AnnexOne);
