import React, { Component } from "react";
import { Form, Table, message } from "antd";
import store from "store";
import { policyViewAnnexNinePublicWeb } from "../../../../restAPI/main";
import ShowMoreText from "react-show-more-text";
import styles from "./style.module.scss";
import IntlMessages from "util/IntlMessages";

class AnnexNine extends Component {
  state = {
    policyLangBoth: store.get("policyLangBoth"),
    targets: [new Date().getFullYear() + 1],
    isReflect: false,
    Objects: [],
    ObjectsLoading: true,
    Columns: [],
    evaluations: [],
    evaluationsLoading: true,
    submitLoading: false
  };

  componentWillMount() {
    this.fetchObjectCoordination();
    this.setState({
      evaluations: this.props.evaluations,
      evaluationsLoading: this.props.evaluationsLoading
    });
  }

  fetchObjectCoordination = () => {
    const { policy } = this.props;
    policyViewAnnexNinePublicWeb(
      policy.id,
      "",
      this.onSuccessObj,
      this.onFailed,
      "POST"
    );
  };

  onSuccessObj = response => {
    const tmpColumn = [
      {
        title: <IntlMessages id="annex9.Objectives_of_processing_policy" />,
        dataIndex: "objective_name",
        width: 200,
        render: value => (
          <ShowMoreText
            lines={3}
            more="Дэлгэрэнгүй"
            less="Хураах"
            anchorClass=""
            expanded={false}
            className="text-justify"
          >
            {value}
          </ShowMoreText>
        )
      }
    ];

    response.map((res, MainIndex) => {
      const tmp = {
        title: (
          <ShowMoreText
            lines={3}
            more="Дэлгэрэнгүй"
            less="Хураах"
            anchorClass=""
            expanded={false}
            className="text-justify"
          >
            {res.objective_name}
          </ShowMoreText>
        ),
        id: res.objective_id,
        width: 200,
        render: (value, record, index) =>
          this.CheckObjectInput(res.objective_name, value)
            ? null
            : record.Children[MainIndex].point
      };

      tmpColumn.push(tmp);
      return tmp;
    });

    tmpColumn.push({
      title: "ДҮН",
      width: 50,
      render: (value, record) => this.getGetTotal(record)
    });

    this.setState({
      Columns: tmpColumn,
      Objects: response,
      ObjectsLoading: false
    });
  };

  onhandleChange = (event, record, MainIndex) => {
    const { Objects } = this.state;
    Objects.map(object => {
      if (object.objective_id === record.objective_id) {
        object.Children[MainIndex].point = event;
      }
      return "";
    });
    this.setState({
      Objects
    });
    this.props.setFinalRef(false);
  };

  getGetTotal = record => {
    let total = 0;
    record.Children.map(child => {
      total += child.point;
      return total;
    });
    return <span className={styles.textCenter}>{total}</span>;
  };

  CheckObjectInput = (value, record) => {
    if (value === record.objective_name) {
      return true;
    }
    return false;
  };

  onSuccessSave = () => {
    message.success("Амжилттай хадгаллаа.");
    this.props.setFinalRef(true);
    this.setState({
      submitLoading: false
    });
  };

  onFailed = err => {
    message.error(err);
    this.setState({
      submitLoading: false
    });
  };

  render() {
    const { Objects, ObjectsLoading, Columns } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 20 },
        sm: { span: 20 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Table
          bordered
          loading={ObjectsLoading}
          dataSource={Objects}
          columns={Columns}
          scroll={{ x: "max-content" }}
          pagination={false}
          rowKey="objective_id"
        />
      </Form>
    );
  }
}

export default Form.create()(AnnexNine);
