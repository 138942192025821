import React, { Component } from "react";
import { Form, Table, Row, Col, message, Divider } from "antd";
import ShowMoreText from "react-show-more-text";
import store from "store";
import { policyViewAnnexNineExternalPublicWeb } from "../../../../restAPI/main";
import styles from "./style.module.scss";
import IntlMessages from "util/IntlMessages";

class AnnexNine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policyLangBoth: store.get("policyLangBoth"),
      Objects: [],
      ObjectsLoading: true,
      Columns: [],
      evaluations: [],
      evaluationsLoading: true,

      // ----------------------------
      externalPolicy: [],
      externalPolicyLoading: true,
      choosedExternalObj: [],
      IsReflect: false,
      externalPolicyRequest: [],

      submitLoading: false
    };
  }

  componentWillMount() {
    this.fetchObjectCoordinationExternal();
    this.setState({
      evaluations: this.props.evaluations,
      evaluationsLoading: this.props.evaluationsLoading
    });
  }

  // ---------------- API CALL ---------------------------

  fetchObjectCoordinationExternal = () => {
    const { policy } = this.props;
    policyViewAnnexNineExternalPublicWeb(
      policy.id,
      "",
      this.onSuccessObj,
      this.onFailed,
      "POST"
    );
  };
  onSuccessExtObj = response => {
    this.setState({
      externalPolicy: response,
      externalPolicyLoading: false
    });
  };

  onSuccessObj = response => {
    this.setState(
      {
        externalPolicyRequest: response
      },
      function() {
        const { externalPolicyRequest } = this.state;
        let columnChild = [];
        if (externalPolicyRequest.length !== 0) {
          externalPolicyRequest[0].Children.map(eachChildren => {
            columnChild.push(eachChildren);
            return columnChild;
          });

          this.getAllof(columnChild);
        }
      }
    );
  };

  // ---------------- API CALL END ---------------------------

  getAllof = datas => {
    const { externalPolicyRequest } = this.state;
    const Columns = [
      {
        title: <IntlMessages id="annex9.Objectives_of_processing_policy" />,
        dataIndex: "objective_name",
        width: 200,
        render: (value, record) => (
          <ShowMoreText
            lines={3}
            more="Дэлгэрэнгүй"
            less="Хураах"
            anchorClass=""
            expanded={false}
            className="text-justify"
          >
            {record.objective_name}
          </ShowMoreText>
        )
      }
    ];
    datas.map((child, childIndex) => {
      var tmp = {
        title: (
          <Row>
            <Col span={24}>
              <ShowMoreText
                lines={3}
                more="Дэлгэрэнгүй"
                less="Хураах"
                anchorClass=""
                expanded={false}
                className="text-justify"
              >
                {child.co_policy_name}
              </ShowMoreText>
              <Divider />
              <ShowMoreText
                lines={3}
                more="Дэлгэрэнгүй"
                less="Хураах"
                anchorClass=""
                expanded={false}
                className="text-justify"
              >
                {child.co_objective_name}
              </ShowMoreText>
            </Col>
          </Row>
        ),
        co_objective_id: child.co_objective_id,
        width: 200,
        render: (value, record, index) =>
          value.Children[
            externalPolicyRequest[0].Children.findIndex(
              x => x.co_objective_id === child.co_objective_id
            )
          ]
            ? value.Children[
                externalPolicyRequest[0].Children.findIndex(
                  x => x.co_objective_id === child.co_objective_id
                )
              ].point
            : undefined
      };

      Columns.push(tmp);
      return "";
    });

    Columns.push({
      title: "ДҮН",
      width: 50,
      render: (value, record) => this.getGetTotal(record)
    });

    this.setState({
      Columns
    });
  };

  getGetTotal = record => {
    let total = 0;
    record.Children.map(child => {
      total += child.point;
      return total;
    });
    return <span className={styles.textCenter}>{total}</span>;
  };

  onFailed = err => {
    message.error(err);
    this.setState({
      submitLoading: false
    });
  };

  getIDs = eachPolicy => {
    const Ids = [];
    eachPolicy.objectives.map(eachObj => {
      if (eachObj.is_chosen) {
        Ids.push(eachObj.objective_id);
      }
      return Ids;
    });
    return Ids;
  };

  render() {
    const { Columns, externalPolicyRequest } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 20 },
        sm: { span: 20 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 }
      }
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <h2 className="mt-4">Зорилтууд</h2>

        <Table
          bordered
          dataSource={externalPolicyRequest}
          columns={Columns}
          scroll={{ x: "max-content" }}
          pagination={false}
          rowKey="objective_id"
        />
      </Form>
    );
  }
}

export default Form.create()(AnnexNine);
