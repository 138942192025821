import { message } from "antd";
import JWTdecode from "jwt-decode";

export function beforeUpload(file, bucketFiles) {
  const isJpgOrPng =
    file.name.split(".").pop().toLowerCase() === "doc" ||
    file.name.split(".").pop().toLowerCase() === "docx" ||
    file.name.split(".").pop().toLowerCase() === "xls" ||
    file.name.split(".").pop().toLowerCase() === "xlsx" ||
    file.name.split(".").pop().toLowerCase() === "txt" ||
    file.name.split(".").pop().toLowerCase() === "ppt" ||
    file.name.split(".").pop().toLowerCase() === "pptx" ||
    file.name.split(".").pop().toLowerCase() === "pdf" ||
    file.name.split(".").pop().toLowerCase() === "png" ||
    file.name.split(".").pop().toLowerCase() === "jpeg" ||
    file.name.split(".").pop().toLowerCase() === "jpg" ||
    file.name.split(".").pop().toLowerCase() === "svg";

  const isLt2M = file.size / 1024 / 1024 < 50;
  const total_size_all_mb =
    (bucketFiles.total_size + isLt2M) / 1024 / 1024 > 250;
  const total_count_all = bucketFiles.total_count + 1 > 50;

  if (total_size_all_mb) {
    message.error("Your size is full!");
    return false;
  }

  if (!isJpgOrPng) {
    message.error("Хуулах боломжгүй файл байна!");
    return false;
  }

  if (total_count_all) {
    message.error("Your count is limited!");
    return false;
  }

  if (!isLt2M) {
    message.error("Your file must smaller than 50MB!");
    return false;
  }

  return true;
}

export function checkFileType (file){
  const isJpgOrPng =
    file.name.split(".").pop().toLowerCase() === "doc" ||
    file.name.split(".").pop().toLowerCase() === "docx" ||
    file.name.split(".").pop().toLowerCase() === "xls" ||
    file.name.split(".").pop().toLowerCase() === "xlsx" ||
    file.name.split(".").pop().toLowerCase() === "csv" ||
    file.name.split(".").pop().toLowerCase() === "txt" ||
    file.name.split(".").pop().toLowerCase() === "ppt" ||
    file.name.split(".").pop().toLowerCase() === "pptx" ||
    file.name.split(".").pop().toLowerCase() === "pdf" ||
    file.name.split(".").pop().toLowerCase() === "png" ||
    file.name.split(".").pop().toLowerCase() === "jpeg" ||
    file.name.split(".").pop().toLowerCase() === "jpg" ||
    file.name.split(".").pop().toLowerCase() === "svg";

  if (!isJpgOrPng) {
    message.error("Хуулах боломжгүй файл байна!");
    return false;
  }
  return true;
}

export function download(url, filename) {
  fetch(url).then(function(t) {
    return t.blob().then(b => {
      var a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", filename);
      a.click();
    });
  });
}

export function calSizeMB(sizeByte) {
  const sizekb = (sizeByte / 1024).toFixed(2);
  let retVal = sizekb;
  if (sizekb > 1000) {
    retVal = (sizekb / 1024).toFixed(2);
  }

  return `${retVal} ${sizekb > 1000 ? "MB" : "KB"}`;
}

export function checkPermission(permissions, onePermission) {
  if (permissions.includes(onePermission)) {
    return true;
  }
  return false;
}

export function checkWindow(checkingWindow) {
  const windows = localStorage.getItem("windows");
  let retVal = false;
  if (windows) {
    const parsedJSON = JSON.parse(windows);
    parsedJSON.map(eachWindow => {
      if (eachWindow === checkingWindow) {
        retVal = true;
      }
      return retVal;
    });
    return retVal;
  }
}

export function userData() {
  return localStorage.getItem("user_token")
    ? JWTdecode(localStorage.getItem("user_token"))
    : null;
}

export function checkAPI(checkingAPI) {
  const APIs = localStorage.getItem("apis");
  let retVal = false;
  if (APIs) {
    const parsedJSON = JSON.parse(APIs);
    parsedJSON.map(eachAPI => {
      if (eachAPI === checkingAPI) {
        retVal = true;
      }
      return retVal;
    });
    return retVal;
  }
}

export function getSearchResult(requests) {
  let result = "";

  requests.map(i => {
    if (i.op) {
      result += "(";
    }
    return null;
  });

  requests.map(i => {
    if (i.op) {
      result += ") " + i.op + " " + i.value + "[";
      result += i.fieldName ? i.fieldName : "";
      result += "]";
    } else {
      result += i.value;
      result += i.fieldName ? "[" + i.fieldName + "] " : "";
    }

    return null;
  });
  return result;
}

export function format1(n) {
  return n.toFixed(2).replace(/./g, function(c, i, a) {
    return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
  });
}

export function generalColor(index, is_body) {
  let color = "";
  switch (index) {
    case 0:
      color = is_body ? "rgba(142,209,252,0.2)" : "rgba(142,209,252,1)";
      break;
    case 1:
      color = is_body ? "rgba(235,20,76,0.2)" : "rgba(235,20,76,1)";
      break;
    case 2:
      color = is_body ? "rgba(171,184,195,0.2)" : "rgba(171,184,195,1)";
      break;
    case 4:
      color = is_body ? "rgba(0,208,132,0.2)" : "rgba(0,208,132,1)";
      break;
    case 5:
      color = is_body ? "rgba(153,0,239,0.2)" : "rgba(153,0,239,1)";
      break;
    case 6:
      color = is_body ? "rgba(6,147,227,0.2)" : "rgba(6,147,227,1)";
      break;
    case 7:
      color = is_body ? "rgba(123,220,181,0.2)" : "rgba(123,220,181,1)";
      break;
    case 8:
      color = is_body ? "rgba(252,185,0,0.2)" : "rgba(252,185,0,1)";
      break;
    case 9:
      color = is_body ? "rgba(247,141,167,0.2)" : "rgba(247,141,167,1)";
      break;
    case 10:
      color = is_body ? "rgba(255,105,0,0.2)" : "rgba(255,105,0,1)";
      break;
    default:
      color = is_body ? "rgba(0,208,132,0.2)" : "rgba(0,208,132,1)";
      break;
  }
  return color;
}

export function chunkArray(myArray, chunk_size) {
  var index = 0;
  var arrayLength = myArray.length;
  var tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    var myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
}
